import React, { useEffect, useState, useMemo, } from 'react';

import useAppTitle from '../hooks/useAppTitle';
import useLocale from '../hooks/useLocale';
import Footer from '../Footer';

export default function PageHOC(WrappedComponent) {
  return function Page (props) {
    const [locale, setLocale] = useState();
    const [lang = 'ja', setLang] = useState();
    const { translate: _translate } = useLocale();
    const translate = useMemo(_ => _ => _translate(_, locale), [locale]);
    useAppTitle(props.pageTitle);

    return (
      <div className="page d-flex flex-column" style={{ minHeight: '100vh', }}>
        <div className="flex-fill">
          <WrappedComponent {...{ ...props, lang, setLang, locale, setLocale, translate, }} />
        </div>
        <Footer translate={translate} />
      </div>
    );
  };
};
