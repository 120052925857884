import { format as formatDate } from 'date-fns';
import { get } from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  '/admin': {
    title: (_) => '管理画面トップ',
  },
  '/admin/auditLogs': {
    title: (_) => '操作ログ',
    parent: '/admin',
  },
  '/admin/users': {
    title: (_) => 'アカウント管理',
    parent: '/admin',
  },
  '/admin/users/:userId': {
    title: (_) => 'アカウント詳細',
    parent: '/admin/users',
  },
  '/admin/users/:userId/accessLogs': {
    title: (_) => 'アクセスログ',
    parent: '/admin/users/:userId',
  },
  '/admin/userTagRules': {
    title: (_) => 'ユーザータグルール管理',
    parent: '/admin',
  },
  '/admin/area': {
    title: (_) => 'エリア管理',
    parent: '/admin',
  },
  '/admin/places': {
    title: (_) => '開催場所管理',
    parent: '/admin',
  },
  '/admin/questions': {
    title: (_) => 'アンケート管理',
    parent: '/admin',
  },
  '/admin/surveyGroups': {
    title: (_) => 'アンケートグループ管理',
    parent: '/admin',
  },
  '/admin/surveys': {
    title: (_) => 'アンケートページ管理',
    parent: '/admin',
  },
  '/admin/surveyAnswers': {
    title: (_) => 'アンケートページ回答管理',
    parent: '/admin',
  },
  '/admin/surveyAnswers/:surveyAnswerId': {
    title: (_) => 'アンケートページ回答詳細',
    parent: '/admin/surveyAnswers',
    reactionEnabled: true,
  },
  '/admin/userVoices': {
    title: (_) => 'ユーザーズボイス管理',
    parent: '/admin',
  },
  '/admin/events': {
    title: (_) => 'イベント管理',
    parent: '/admin',
  },
  '/admin/events/:eventId': {
    title: ({ event = {} }) => event.name,
    parent: '/admin/events',
    isDetailScreen: true,
  },
  '/admin/events/:eventId/entries': {
    title: () => '申込み一覧',
    parent: '/admin/events/:eventId',
  },
  '/admin/events/:eventId/entryFrames': {
    title: () => '参加者一覧',
    parent: '/admin/events/:eventId',
  },
  '/admin/events/:eventId/cancellationNotices': {
    title: () => '空き待ち一覧',
    parent: '/admin/events/:eventId',
  },
  '/admin/events/:eventId/lectures/:lectureId': {
    title: ({ lecture }) => lecture && `${formatDate(lecture.date.toDate(), 'yyyy/MM/dd')}のレクチャー`,
    parent: '/admin/events/:eventId',
  },
  '/admin/events/:eventId/lectures/:lectureId/edit': {
    title: () => '編集',
    parent: '/admin/events/:eventId/lectures/:lectureId',
  },
  '/admin/events/:eventId/entries/:entryId': {
    title: ({ entry }) => '申込み詳細',
    parent: '/admin/events/:eventId',
  },
  '/admin/eventCalendar': {
    title: () => 'イベントカレンダー',
    parent: '/admin/events',
  },
  '/admin/entries': {
    title: () => 'イベント申し込み管理',
    parent: '/admin',
  },
  '/admin/inventories': {
    title: () => '在庫管理',
    parent: '/admin',
  },
  '/admin/inventories/schedule': {
    title: () => 'シミュレーション',
    parent: '/admin/inventories',
  },
  '/admin/inventories/purchaseOrders': {
    title: () => 'PO',
    parent: '/admin/inventories',
  },
  '/admin/inventories/containers': {
    title: () => 'コンテナ',
    parent: '/admin/inventories',
  },
  '/admin/receivingPlans': {
    title: () => '入荷予定管理',
    parent: '/admin',
  },
  '/admin/eventProductTypes': {
    title: (_) => 'イベント商品種別',
    parent: '/admin',
  },
  '/admin/lectureTypes': {
    title: (_) => 'レクチャー種別',
    parent: '/admin',
  },
  '/admin/emailSettings': {
    title: (_) => 'メール設定',
    parent: '/admin',
  },
  '/admin/cartSettings': {
    title: _ => 'カート設定',
    parent: '/admin',
  },
  '/admin/textSettings': {
    title: (_) => 'テキスト管理',
    parent: '/admin',
  },
  '/admin/products': {
    title: (_) => '商品管理',
    parent: '/admin',
  },
  '/admin/productsAgents': {
    title: (_) => '商品 × 代理店管理',
    parent: '/admin',
  },
  '/admin/contentProductsAgents': {
    title: (_) => 'コンテンツ商品 × 代理店管理',
    parent: '/admin',
  },
  '/admin/envelopeProducts': {
    title: (_) => '郵送物管理',
    parent: '/admin',
  },
  '/admin/productTags': {
    title: (_) => '商品タグ管理',
    parent: '/admin',
  },
  '/admin/productTypes': {
    title: (_) => '商品種別管理',
    parent: '/admin',
  },
  '/admin/couponTemplates': {
    title: (_) => '優待テンプレート管理',
    parent: '/admin',
  },
  '/admin/coupons': {
    title: (_) => '優待管理',
    parent: '/admin',
  },
  '/admin/orders': {
    title: (_) => '注文管理',
    parent: '/admin',
  },
  '/admin/orders/:orderId': {
    title: (_) => '注文詳細',
    parent: '/admin/orders',
  },
  '/admin/multiOrdersUsers': {
    title: (_) => '複数注文アカウント管理',
    parent: '/admin',
  },
  '/admin/contentOrders': {
    title: (_) => 'コンテンツ注文管理',
    parent: '/admin',
  },
  '/admin/qrUrls': {
    title: (_) => '紹介QRコード遷移先管理',
    parent: '/admin',
  },
  '/admin/agents': {
    title: (_) => '代理店管理',
    parent: '/admin',
  },
  '/admin/agents/register': {
    title: (_) => '代理店登録ウィザード',
    parent: '/admin/agents',
  },
  '/admin/agents/:agentId/agentShops': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/members': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/qrUrls': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/products': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/contentProducts': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/comments': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/contractPlans': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/rentalItems': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/settings': {
    title: ({ agent = {} }) => agent.name,
    parent: '/admin/agents',
    isDetailScreen: true,
  },
  '/admin/agents/:agentId/agentShops/:agentShopId': {
    title: ({ agentShop = {} }) => agentShop.name,
    parent: '/admin/agents/:agentId/agentShops',
  },
  '/admin/agents/:agentId/agentShops/:agentShopId/general': {
    title: ({ agentShop = {} }) => '詳細',
    parent: '/admin/agents/:agentId/agentShops/:agentShopId',
  },
  '/admin/agents/:agentId/agentShops/:agentShopId/referrers': {
    title: ({ agentShop = {} }) => 'リファラ',
    parent: '/admin/agents/:agentId/agentShops/:agentShopId',
  },
  '/admin/agents/:agentId/agentShops/:agentShopId/referrers/:referrerId': {
    title: ({ referrer = {} }) => referrer.name,
    parent: '/admin/agents/:agentId/agentShops/:agentShopId',
  },
  '/admin/contentProducts': {
    title: _ => 'コンテンツ商品',
    parent: '/admin',
  },
  '/admin/inquiries': {
    title: (_) => 'お問合せ管理',
    parent: '/admin',
  },
  '/admin/inquiries/:inquiryId': {
    title: (_) => 'お問合せ詳細',
    parent: '/admin/inquiries',
  },
  '/admin/troubleInquiries': {
    title: (_) => '不具合組立問合せ管理',
    parent: '/admin',
  },
  '/admin/troubleInquiries/:troubleInquiryId': {
    title: (_) => '不具合組立問合せ詳細',
    parent: '/admin/troubleInquiries',
  },
  '/admin/methodInquiries': {
    title: (_) => '乗り方お問合せ管理',
    parent: '/admin',
  },
  '/admin/methodInquiries/:methodInquiryId': {
    title: (_) => '乗り方問合せ詳細',
    parent: '/admin/methodInquiries',
  },
  '/admin/inquiryTypes': {
    title: (_) => '問合せ種別管理',
    parent: '/admin',
  },
  '/admin/magazines': {
    title: (_) => 'はじめナビSMS管理',
    parent: '/admin',
  },
  '/admin/magazines/:magazineId': {
    title: (_) => 'はじめナビSMS管理詳細',
    parent: '/admin/magazines',
  },
  '/admin/magazines/:magazineId/deliveryHistories': {
    title: (_) => '配信履歴',
    parent: '/admin/magazines/:magazineId',
  },
  '/admin/smsDeliveries': {
    title: (_) => 'SMS配信',
    parent: '/admin',
  },
  '/admin/smsDeliveries/:smsDeliveryId': {
    title: (_) => 'SMS配信詳細',
    parent: '/admin/smsDeliveries',
  },
  '/admin/smsDeliveries/:smsDeliveryId/deliveryTargets': {
    title: (_) => '配信対象',
    parent: '/admin/smsDeliveries/:smsDeliveryId',
  },
  '/admin/envelopeSchedules': {
    title: (_) => '郵便スケジュール',
    parent: '/admin',
  },
  '/admin/envelopeSchedules/:envelopeScheduleId': {
    title: (_) => '郵便スケジュール詳細',
    parent: '/admin/envelopeSchedules',
  },
  '/admin/envelopeSchedules/:envelopeScheduleId/deliveryHistories': {
    title: (_) => '発送履歴',
    parent: '/admin/envelopeSchedules/:envelopeScheduleId',
  },
  '/admin/envelopeDeliveries': {
    title: (_) => '一括郵便発送',
    parent: '/admin',
  },
  '/admin/envelopeDeliveries/:envelopeDeliveryId': {
    title: (_) => '一括郵便発送詳細',
    parent: '/admin/envelopeDeliveries',
  },
  '/admin/envelopeDeliveries/:envelopeDeliveryId/deliveryTargets': {
    title: (_) => '発送対象',
    parent: '/admin/envelopeDeliveries/:envelopeDeliveryId',
  },
  '/admin/envelopeDeliveries/:envelopeDeliveryId/deliveryHistories': {
    title: (_) => '発送履歴',
    parent: '/admin/envelopeDeliveries/:envelopeDeliveryId',
  },
  '/admin/customerJourneys': {
    title: (_) => 'カスタマージャーニー',
    parent: '/admin',
  },
  '/admin/customerJourneys/:customerJourneyId': {
    title: (_) => 'カスタマージャーニー配信詳細',
    parent: '/admin/customerJourneys',
  },
  '/admin/customerJourneys/:customerJourneyId/deliveryHistories': {
    title: (_) => 'カスタマージャーニー配信履歴',
    parent: '/admin/customerJourneys/:customerJourneyId',
  },
  '/admin/contacts': {
    title: (_) => 'コンタクトリスト一覧',
    parent: '/admin',
  },
  '/admin/contacts/:contactId': {
    title: (_) => 'コンタクトリスト詳細',
    parent: '/admin/contacts',
  },
  '/admin/contacts/:contactId/contactTargets': {
    title: (_) => 'コンタクトリスト',
    parent: '/admin/contacts/:contactId',
  },
  '/admin/contents': {
    title: (_) => 'コンテンツ管理',
    parent: '/admin',
  },
  '/admin/shortUrls': {
    title: (_) => '短縮URL管理',
    parent: '/admin',
  },
  '/admin/initialTags': {
    title: (_) => '初期化タグ',
    parent: '/admin',
  },
  '/admin/settings': {
    title: (_) => '設定',
    parent: '/admin',
  },
  '/admin/exportRequests': {
    title: _ => 'エクスポート履歴',
    parent: '/admin',
  },
  '/admin/accessLogs': {
    title: _ => 'アクセスログ集計',
    parent: '/admin',
  },
  '/admin/paceMakerSettings': {
    title: _ => 'ペースメーカー設定',
    parent: '/admin',
  },
  '/admin/locales': {
    title: _ => '言語設定',
    parent: '/admin',
  },
  '/admin/mypage': {
    title: _ => '管理マイページ',
    parent: '/admin',
  },
  '/agents': {
    title: (_) => 'ビジネスアカウント',
    isNotLink: true,
  },
  '/agents/:agentId': {
    title: (_) => get(_, 'agent.name'),
    parent: '/agents',
  },
  '/agents/:agentId/shops': {
    title: (_) => '店舗一覧',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/referralOrders': {
    title: (_) => '紹介注文一覧',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/tmpOrders': {
    title: (_) => '卸仮注文一覧',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/wholesaleOrders': {
    title: (_) => '卸注文一覧',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/rentalItems': {
    title: ({ referrer = {} }) => 'レンタル商品',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/rentalOrders': {
    title: ({ referrer = {} }) => 'レンタル注文',
    parent: '/agents/:agentId',
  },
  '/agents/:agentId/agentShops/:agentShopId': {
    title: ({ agentShop = {} }) => agentShop.name,
    parent: '/agents/:agentId/shops',
  },
  '/agents/:agentId/agentShops/:agentShopId/general': {
    title: ({ agentShop = {} }) => '詳細',
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/agentShops/:agentShopId/referrers': {
    title: ({ referrer = {} }) => 'リファラ',
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/agentShops/:agentShopId/referrers/:referrerId': {
    title: ({ referrer = {} }) => referrer.name,
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/agentShops/:agentShopId/rentalItemEntities': {
    title: ({ referrer = {} }) => 'レンタル商品在庫',
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/agentShops/:agentShopId/rentalPrices': {
    title: ({ referrer = {} }) => 'レンタル料金',
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/agentShops/:agentShopId/rentalCalendar': {
    title: ({ referrer = {} }) => 'レンタル状況',
    parent: '/agents/:agentId/agentShops/:agentShopId',
  },
  '/agents/:agentId/members': {
    title: (_) => 'メンバー',
    parent: '/agents/:agentId',
  },
};
