const { chunk, uniq, isEmpty, } = require('lodash');
const { format: formatDate, addDays, addHours, startOfDay, endOfDay, } = require('date-fns');
const dedent = require('dedent');
const { isFullWidth } = require('validator');

const { getCollectionData, } = require('../firebase');
const { vehicleExperiences, } = require('./user');
const { smsOrderTypes: orderTypes, smsEntryTypes: entryTypes, deliveryMethods, userNotificationTypes, mobileNotificationContentTypes } = require('../config');

const { entries } = Object;

const scheduleTypes = {
  ship: {
    label: '発送から',
    candidates: async (db, { tenantId, daysAfter }) => {
      const orders = await getCollectionData(db.collection('orders').where('tenantId', '==', tenantId).where('shippedDate', '==', formatDate(addDays(addHours(new Date(), 9), -daysAfter), 'yyyy/MM/dd')));
      return uniq(orders.map(_ => _.createdBy?.uid).filter(_ => _));
    },
  },
  entry: {
    label: 'イベント参加から',
    candidates: async (db, { tenantId, daysAfter }) => {
      const lectures = await getCollectionData(db.collectionGroup('lectures').where('tenantId', '==', tenantId).where('date', '>=', addHours(startOfDay(addHours(addDays(new Date(), -daysAfter), 9)), -9)).where('date', '<=', addHours(endOfDay(addHours(addDays(new Date(), -daysAfter), 9)), -9)));
      const entries = (await Promise.all(chunk(lectures, 10).map((lectures) => {
        return getCollectionData(db.collectionGroup('entries').where('lectureIds', 'array-contains-any', lectures.map(_ => _.id)));
      }))).flat();
      return uniq(entries.map(_ => _.createdBy?.uid).filter(_ => _));
    },
  },
};

module.exports = {
  fields: ({ products = [], productTypes = [], events = [], userTags = [], magazineGroups = [], onCreateMagazineGroup = () => null, useSms = false, useMobile = false } = {}) => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      magazineGroupIds: {
        label: 'グループ',
        type: 'creatableMultiSelect',
        options: magazineGroups.map(_ => ({ label: _.name, value: _.id })),
        initialValue: [],
        onCreateMultiSelectValue: onCreateMagazineGroup
      },
      scheduleType: {
        label: 'スケジュール種別',
        type: 'select',
        options: entries(scheduleTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      daysAfter: {
        label: 'スケジュール(n日後)',
        type: 'integer',
        validations: {
          required: v => v != null,
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      orderType: {
        label: '注文種別',
        type: 'select',
        options: entries(orderTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionProductTypeIds: {
        label: '注文商品種別',
        type: 'multiSelect',
        options: productTypes.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      conditionProductIds: {
        label: '注文商品',
        type: 'multiSelect',
        options: products.map(_ => ({ label: _.label || _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      isBodyOnly: {
        label: '本体のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.orderType !== 'ordered',
      },
      entryType: {
        label: 'イベント参加種別',
        type: 'select',
        options: entries(entryTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionEventIds: {
        label: '参加イベント',
        type: 'multiSelect',
        options: events.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.entryType !== 'entried',
      },
      isIncludedCancellOrAbort: {
        label: 'キャンセル・中止を含む',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.entryType !== 'entried',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        hint: 'ORで判定します',
      },
      userChildAgeMin: {
        label: 'お子様の年齢下限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildAgeMax: {
        label: 'お子様の年齢上限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildVehicleExperiences: {
        label: 'お子様乗り物経験',
        type: 'multiSelect',
        options: ['未選択', ...vehicleExperiences].map(_ => ({ label: _, value: _, })),
      },
      deliveryMethod: {
        label: '配信方法',
        type: 'select',
        options: entries(deliveryMethods)
          .filter(([k, v]) => useSms || k !== 'sms')
          .filter(([k, v]) => useMobile || k !== 'mobile')
          .map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      emailSubject: {
        label: 'Eメール件名',
        type: 'text',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'email',
      },
      body: {
        label: '本文',
        type: 'text',
        rows: 5,
        validations: {
          required: v => !isEmpty(v),
        },
        showsTextLength: true,
        hint: dedent`埋め込みキーワード
        {{name}} : contactorName もしくは displayName が表示される。
        `,
        hidden: _ => !['email', 'sms'].includes(_.deliveryMethod),
      },
      mobileTypeDisplayName: {
        label: '通知グループ',
        type: 'text',
        rows: 1,
        hint: '最大16文字までで指定してください',
        validations: {
          maxLength: v => (v || '').length <= 16,
          maxRows: v => (v || '').split('\n').length <= 1,
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileSubject: {
        label: '通知文',
        type: 'text',
        rows: 2,
        hint: '全角18文字、2行までで指定してください',
        validations: {
          required: v => !isEmpty(v),
          maxRows: v => (v || '').split('\n').length <= 2,
          maxLength: v => (v || '').split('\n').every(text => {
            return text.split('').map(_ => isFullWidth(_) ? 2 : 1).reduce((x, y) => x + y, 0) <= 36
          }),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileNotificationContentType: {
        label: '本文タイプ',
        type: 'select',
        options: entries(mobileNotificationContentTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileMessage: {
        label: '本文（テキスト）',
        type: 'text',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'text',
      },
      mobileUrl: {
        label: '本文（URL）',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'url',
      },
      mobileHtml: {
        label: '本文（HTML）',
        type: 'richText',
        rows: 10,
        validations: {
          required: v => !isEmpty(v),
        },
        showsTextLength: true,
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'html',
      },
      mobileNotificationType: {
        label: '通知タイプ',
        type: 'select',
        options: entries(userNotificationTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      fixUntil: {
        label: 'イベント最終日',
        type: 'date',
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationType !== 'newEvent',
      },
      isSuspended: {
        label: '配信停止',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
      },
    };
  },
  orderTypes,
  entryTypes,
  scheduleTypes,
};
