import React, { useEffect, useRef } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { isEmpty, omit, } from 'lodash';
import classnames from 'classnames';
import { useToggle } from 'react-use';
import sanitizeHtml from 'sanitize-html';

import RichTextContent from '../RichTextContent';
import { activateRichTextHtml } from '../../util';

export default function QuestionForm(props) {
  const { translate = _ => _, answer = {}, description, hint = '', type, optionsString, isOptional = false, onChange: setAnswer, shouldStarts = false, } = props;
  const [hasStarted, toggleStart] = useToggle(false);
  const options = (optionsString || '').split(',');
  const { isValid, value } = answer;
  const setValueAndStart = (value) => {
    const isValid = isOptional || !isEmpty(value);
    setAnswer({ value, isValid });
    toggleStart(true);
  };
  const validationCss = hasStarted && classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  useEffect(() => {
    if(shouldStarts) toggleStart(true);
  }, [shouldStarts]);
  const fileInputRef = useRef(null);
  
  return (
    <div className="d-flex flex-column mb-3">
      <Label className="mb-1">
        {description}
        {!isOptional && translate(<span className="text-danger small">【必須】</span>)}
      </Label>
      <RichTextContent className="small text-muted" html={hint} />
      {
        (({
          text: () => (
            <Input type="textarea" value={value} onChange={_ => setValueAndStart(_.target.value)} className={validationCss || ''} />
          ),
          checkbox: () => {
            return (
              <FormGroup check inline className="d-inline">
                {
                  options.map((option, i) => (
                    <Label check className="mr-2" key={i}>
                      <Input checked={!!(value || {})[option]} type="checkbox" onChange={_ => setValueAndStart({ ...(_.target.checked ? { ...value, [option]: true } : omit(value, option)), })} /> {option}
                    </Label>
                  ))
                }
              </FormGroup>
            );
          },
          radio: () => {
            return (
              <FormGroup check inline className="d-inline">
                {
                  options.map((option, i) => (
                    <Label check className="mr-2" key={i}>
                      <Input checked={option === value} type="radio" onChange={_ => setValueAndStart(option)} /> {option}
                    </Label>
                  ))
                }
              </FormGroup>
            );
          },
          imageFile: () => {
            return (
              <>
                <Button onClick={() => fileInputRef.current?.click()}>{translate('ファイル選択')}</Button>
                <Input type="file" multiple onChange={_ => setValueAndStart([...(value || []), ...Array.from(_.target.files || [])])} className={validationCss} accept="image/*,video/mp4,audio/*,application/pdf" innerRef={fileInputRef} hidden />
                <ul>
                  {(value || []).map((file, index) => <li>{file.name}<Button type="button" size="sm" close onClick={() => setValueAndStart(value.filter((_, i) => index !== i))} /></li>)}
                </ul>
              </>            
            );
          },
        })[type] || (_ => null))()
      }
    </div>
  );
}
