import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { pick, isEmpty, keyBy, differenceWith, isEqual, isArray, isObject } from 'lodash';
import { toast } from 'react-toastify';

import AppCard from './AppCard';
import MypageMessagesButton from './MypageMessagesButton';
import { computeVisibleQuestionIds } from '../shared/models/survey';
import SurveyAnswerEditButton from './SurveyAnswerEditButton';
import firebase from '../firebase';
import { reinquiryContacts } from '../shared/config';
import useCollectionSubscription from './hooks/useCollectionSubscription';

const { keys, entries } = Object;
const storageRef = firebase.storage().ref();
const db = firebase.firestore();
const activitiesRef = db.collection('activities');

export default function SurveyAnswerItem({ surveyAnswer, user, questions }) {
  const { id: surveyAnswerId, survey, answers, createdAt, ref, tenantId } = surveyAnswer;
  const activities = useCollectionSubscription(
    activitiesRef
      .where('tenantId', '==', tenantId)
      .where('payload.surveyAnswer.id', '==', surveyAnswerId)
      .where('type', '==', 'updateSurveyAnswer'),
    [surveyAnswerId]
  );
  return (
    <div className="mb-4">
      <div className="position-relative" style={{ textDecoration: 'none' }}>
        <AppCard className="text-dark p-3 p-sm-4">
          <div className="card-title small font-weight-bold">{survey?.title}</div>
          <div className="flex-grow-1">
            <div>
              <div className="small text-muted">回答日時</div>
              {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
          </div>
          <div className="mt-3">
            {computeVisibleQuestionIds(survey, answers).map((questionId) => {
              const question = questions.find((_) => _.id === questionId);
              const answer = answers[questionId];
              const displayValue = {
                text: answer,
                checkbox: keys(answer || {}).join(', '),
                radio: answer,
                imageFile: [answer].flat().map((answer, i) => (
                  <div key={i}>
                    <img src={answer?.url} style={{ maxWidth: '100%' }}></img>
                  </div>
                )),
              }[question?.type || ''];
              return (
                <div key={questionId} className="m-3">
                  <div className="small text-muted">{question?.description}</div>
                  <Card>
                    <CardBody>{displayValue}</CardBody>
                  </Card>
                </div>
              );
            })}
          </div>
          {survey.isAnswerEditable && (
            <SurveyAnswerEditButton {...{ survey, questions, activities, surveyAnswer, user }} />
          )}
          {surveyAnswer.messages?.length > 0 && <MypageMessagesButton color="secondary" className="rounded-pill px-4" size="sm" type="surveyAnswer" subject={surveyAnswer} reinquiryPath={_ => reinquiryContacts[_.reinquiryContact || 'inquiry']?.path} />}
        </AppCard>
      </div>
    </div>
  );
}
