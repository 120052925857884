import React, { useEffect } from 'react';
import { mapValues } from 'lodash';

import { canUpdateEmailSettings, } from '../../shared/abilities';
import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import { emailSettingsFields } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';

const db = firebase.firestore();
const { entries } = Object;

export default AdminPage(function AdminEmailSettings (props) {
  const { tenant, user, match: { params: { tenantPath } } } = props;
  const emailSettingsRef = db.collection('settings').doc([tenantPath, 'email'].join('__'));
  const emailSettings = useDocumentSubscription(emailSettingsRef);
  useEffect(() => {
    if(emailSettings === null) {
      emailSettingsRef.set({ tenantId: tenant.id, ...mapValues(emailSettingsFields(), _ => ''), });
    }
  }, [emailSettings])

  return emailSettings != null && (
    <div>
      <div className="admin-email-settings container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="d-flex justify-content-end mb-3">
            <EditButton color="primary" itemRef={emailSettingsRef} FormModal={ModelFormModal} formProps={{ title: 'メール設定', fields: emailSettingsFields, }} disabled={!canUpdateEmailSettings(user)} />
          </div>
          <table className="table table-bordered align-middle thead-light">
            <tbody>
              {
                entries(emailSettingsFields()).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  return (
                    <tr key={fieldName}>
                      <th>
                        {label}
                      </th>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {fieldDisplayValue(emailSettings[fieldName], fieldSettings)}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
