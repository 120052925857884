import React, { useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { pickBy, pick, uniqBy, inRange, isEmpty, keyBy, groupBy, mapValues } from 'lodash';
import classnames from 'classnames';
import { format as formatDate, differenceInYears } from 'date-fns';
import numeral from 'numeral';
import { useList, useMap, useToggle } from 'react-use';
import qs from 'qs';

import { computeVisibleQuestionIds, computeVisibleAnswers, } from '../../shared/models/survey';
import { childGenders, vehicleExperiences } from '../../shared/config';
import { i18nField } from '../../shared/i18n';
import useQueryParams from '../hooks/useQueryParams';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import EntryflowSection from '../EntryflowSection';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';
import AppButton from '../AppButton';
import QuestionForm from './QuestionForm';
import TenantLink from '../TenantLink';

const currentYear = new Date().getFullYear();
const { entries } = Object;
const frameFields = ({ lecture, children }) => {
  const [minAge, maxAge] = lecture.lectureAge.split('-').map((_) => parseInt(_, 10));
  return {
    name: {
      label: '参加者名',
      type: 'string',
      placeholder: 'たろう',
      validations: {
        required: (v) => !isEmpty(v),
      },
    },
    birthday: {
      label: '生年月日',
      type: 'date',
      selector: true,
      yearRange: [currentYear - 30, currentYear],
      validations: {
        required: (v) => !!v,
        age: (v) => inRange(differenceInYears(lecture.date.toDate(), v), minAge, maxAge + 1),
      },
      initialValue: new Date(),
    },
    gender: {
      label: '性別',
      type: 'select',
      options: entries(childGenders).map(([k, v]) => ({ label: v, value: k})),
      validations: {
        required: v => !!v,
      },
      hidden: _ => !_.isRegisterChildProfile,
    },
    vehicleExperiences: {
      label: '乗り物経験',
      type: 'multiSelect',
      options: vehicleExperiences.map(_ => ({ value: _, label: _, })),
      initialValue: [],
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !_.isRegisterChildProfile,
    },
    useRegistered: {
      label: '登録済みお子様情報を使う',
      type: 'boolean',
      initialValue: false,
    },
    children: {
      label: '参加者名',
      type: 'select',
      options: children.map((_, i) => ({ label: `${_.name} (${formatDate(_.birthday.toDate(), 'yyyy年MM月dd日生まれ')})`, value: `${i}` })),
      validations: { required:  v => !isEmpty(v), },
      hidden: _ => !_.useRegistered,
    },
    isRegisterChildProfile: {
      label: '入力したお子様情報をプロフィールに登録する',
      type: 'boolean',
      initialValue: true,
      hidden: _ => _.useRegistered,
    },
  };
};

function FrameForm(props) {
  const { translate, lecture, values, onChange, shouldStarts = false, children = [] } = props;
  const statedFields = useFormState(values, frameFields({ lecture, children }));
  useEffect(
    () => {
      onChange(statedFields);
    },
    Object.values(statedFields).map((_) => _.value)
  );
  useEffect(() => {
    if (shouldStarts) statedFields.startAll();
  }, [shouldStarts]);
  useEffect(() => {
    if (statedFields.useRegistered.value && statedFields.children.value) {
      const child = children[Number(statedFields.children.value)];
      child && statedFields.setValues({ name: child.name, birthday: child.birthday.toDate(), gender: child.gender, vehicleExperiences: child.vehicleExperiences });
    }
  }, [statedFields.useRegistered.value, statedFields.children.value])

  return (
    <div>
      {!statedFields.useRegistered.value &&
        <div className="d-lg-flex">
          {entries(pick(statedFields,["name", "birthday", 'gender', 'vehicleExperiences'])).map(([fieldName, fieldSetting]) => (
            <div key={fieldName} className="mr-2" style={{ minWidth: 200 }}>
              <Field name={fieldName} {...fieldSetting} translate={translate} />
            </div>
          ))}
        </div>
      }
      <div className="d-lg-flex">
        {entries(pick(statedFields,["children"])).map(([fieldName, fieldSetting]) => (
          <div key={fieldName} className="mr-2" style={{ minWidth: 400 }}>
            <Field name={fieldName} {...fieldSetting} translate={translate} />
          </div>
        ))}
      </div>
      <div>
        {entries(pick(statedFields,["useRegistered", "isRegisterChildProfile"])).map(([fieldName, fieldSetting]) => (
          <div key={fieldName}>
            <Field name={fieldName} {...fieldSetting} translate={translate} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default function EntryForm(props) {
  const { event, translate, eventId, lectures = [], questions = [], values, surveys = [], lectureTypesById, } = props;
  const surveysById = keyBy(surveys, 'id');
  const queryParams = useQueryParams();
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [frames, { updateAt: updateFrameAt }] = useList(values.frames || []);
  const framesGroupedByLectureId = groupBy(frames, 'lectureId');
  const lecturesById = keyBy(lectures, 'id');
  const relatedLectures = frames.map((_) => _.lectureId).map((_) => lecturesById[_]);
  const questionsById = keyBy(questions, 'id');
  const relatedQuestions = uniqBy(
    relatedLectures
      .map((_) => (_.surveyId ? surveys.find(({ id }) => id === _.surveyId)?.questionRows?.map(_ => _.questionId) || [] : _.questions || []))
      .flat()
      .map((_) => questionsById[_] || {}),
    'id'
  );
  const initialAnswers = relatedQuestions.reduce(
    (x, { id, type, isOptional = false }) => ({
      ...x,
      [id]: {
        isValid: isOptional,
        value: ({ text: '', radio: undefined, checkbox: {}, imageFile: [] })[type],
      },
    }),
    {}
  );
  const [answers, { setAll: setAnswers, }] = useMap(initialAnswers);
  const visibleQuestionIds = relatedLectures
    .map(_ => surveysById[_.surveyId])
    .filter(_ => _)
    .flatMap(_ => computeVisibleQuestionIds(_, mapValues(answers, 'value')));
  const visibleQuestions = visibleQuestionIds.map(_ => questionsById[_] || {});
  const validationErrorMessages = [
    frames.some((_) => Object.values(pick(_, ['name', 'birthday', 'gender', 'vehicleExperiences'])).some((_) => !_.isValid)) &&
      'お子様情報にエラーがあります',
    Object.values(answers).some((_) => !_.isValid) && 'アンケート回答にエラーがあります',
  ].filter((_) => _).map(translate);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    await props.onSubmit({
      frames: frames.map((_) => ({
        ...pick(_, ['lectureRef', 'lectureFrame', 'lectureId', 'frameIndex']),
        ...mapValues(pick(_, ['name', 'birthday', 'gender', 'vehicleExperiences', 'isRegisterChildProfile']), 'value'),
      })),
      answers: mapValues(answers, 'value'),
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {translate(<EntryflowSection activeIndex={1} />)}
      <section className="container mt-5">
        <div>
          <div>
            {translate(<h4 className="h5 text-center font-weight-bold">
              参加されるお子様の
              <br />
              お名前を入力してください
            </h4>)}
            <div className="mt-4">
              {entries(framesGroupedByLectureId).map(([lectureId, framesInLecture]) => {
                const lecture = lecturesById[lectureId] || {};
                const { frames: lectureFrames, isUserFree } = lecture;
                return (
                  <div key={lectureId} className="card p-3 mb-2">
                    <div>
                      {framesInLecture.map((frame) => {
                        const { frameIndex } = frame;
                        const { startAt, endAt, lectureType } = lectureFrames[frameIndex];
                        const index = frames.indexOf(frame);
                        return (
                          <div key={index} className="mt-2">
                            <div className="large">
                              {formatDate(lecture.date.toDate(), 'yyyy/MM/dd')} {formatDate(startAt.toDate(), 'HH:mm')}{' '}
                              {<span>{lectureTypesById[lectureType]?.[i18nField(event?.lang, 'name')]}</span>}
                            </div>
                            {translate(<div className="mt-2 d-flex align-items-center">
                              <div>
                                <LectureTypeDisplay event={event} {...lecture} />
                              </div>
                              <div className="ml-3 text-grey">
                                {/* TODO: 将来的にはユーザーの場合のみ無料にする */}
                                {isUserFree ? 'ユーザー無料' : `${numeral(lecture.price).format('0,0')} 円`}
                              </div>
                            </div>)}
                            {translate(<div className="mt-3 d-flex">
                              <LectureLevelDisplay event={event} className="flex-fill" {...lecture} />
                              <LectureAgeDisplay event={event} className="ml-3 flex-fill" {...lecture} />
                            </div>)}
                            <div className="mt-2">
                              <FrameForm
                                translate={translate}
                                event={event}
                                lecture={lecture}
                                values={frame}
                                onChange={(_) => updateFrameAt(index, { ...frame, ..._ })}
                                shouldStarts={hasSubmitted}
                                children={values.createdBy.children || []}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {visibleQuestions.length > 0 && (
            <div className="mt-5">
              {translate(<h4 className="h5 text-center font-weight-bold">アンケートにお答えください</h4>)}
              <div className="mt-4">
                {visibleQuestions.map((question) => {
                  const { id } = question;
                  return (
                    <div key={id}>
                      <QuestionForm
                        answer={answers[id]}
                        {...question}
                        onChange={_ => setAnswers(relatedLectures.reduce((x, y) => {
                          return {
                            ...x,
                            ...computeVisibleAnswers(surveysById[y.surveyId], { ...answers, [id]: _, }),
                          };
                        }, {}))}
                        translate={translate}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {translate(<div className="d-flex mt-5">
          <AppButton
            size="lg"
            className="cancel flex-fill"
            color="secondary"
            tag={TenantLink}
            to={`/events/${eventId}/entries/new?${qs.stringify(queryParams)}`}
          >
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton size="lg" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit}>
            <span className="fas fa-arrow-right mr-1" />
            確認画面
          </AppButton>
        </div>)}
      </section>
    </Form>
  );
}
