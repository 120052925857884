import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { omit, uniqBy, isEmpty, keyBy, mapValues } from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useMap, useToggle } from 'react-use';

function LaterQuestionForm(props) {
  const { laterAnswer = {}, description, type, optionsString, isOptional = false, onChange: setLaterAnswer } = props;
  const [hasStarted, toggleStart] = useToggle(false);
  const options = (optionsString || '').split(',');
  const { isValid, value } = laterAnswer;
  const setValueAndStart = (value) => {
    const isValid = isOptional || !isEmpty(value);
    setLaterAnswer({ value, isValid });
    toggleStart(true);
  };
  const validationCss = hasStarted && classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });

  return (
    <div className="d-flex flex-column mb-3">
      <Label className="mb-1">
        {description}
        {!isOptional && <span className="text-danger small">【必須】</span>}
      </Label>
      {(
        {
          text: () => (
            <Input
              type="textarea"
              value={value}
              onChange={(_) => setValueAndStart(_.target.value)}
              className={validationCss || ''}
            />
          ),
          checkbox: () => {
            return (
              <FormGroup check inline>
                {options.map((option) => (
                  <Label check className="mr-2">
                    <Input
                      checked={!!(value || {})[option]}
                      type="checkbox"
                      onChange={(_) =>
                        setValueAndStart({ ...(_.target.checked ? { ...value, [option]: true } : omit(value, option)) })
                      }
                    />{' '}
                    {option}
                  </Label>
                ))}
              </FormGroup>
            );
          },
          radio: () => {
            return (
              <FormGroup check inline>
                {options.map((option) => (
                  <Label check className="mr-2">
                    <Input checked={option === value} type="radio" onChange={(_) => setValueAndStart(option)} />{' '}
                    {option}
                  </Label>
                ))}
              </FormGroup>
            );
          },
        }[type] || ((_) => null)
      )()}
    </div>
  );
}

export default function LaterQuestionsAnswerForm(props) {
  const { lectures = [], entry, questions = [] } = props;
  const questionsById = keyBy(questions, 'id');
  const lecturesById = keyBy(lectures, 'id');
  const relatedLectures = entry.frames.map((_) => _.lectureId).map((_) => lecturesById[_]);
  const relatedLaterQuestions = uniqBy(
    relatedLectures
      .map((_) => _.laterQuestions || [])
      .flat()
      .map((_) => questionsById[_] || {}),
    'id'
  );
  const initialLaterAnswers = relatedLaterQuestions.reduce(
    (x, { id, type, isOptional = false }) => ({
      ...x,
      [id]: {
        isValid: isOptional,
        value: { text: '', radio: undefined, checkbox: {} }[type],
      },
    }),
    {}
  );
  const [laterAnswers, { set: setLaterAnswer }] = useMap(initialLaterAnswers);
  const isUnsubmittable = Object.values(laterAnswers).some((_) => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable || isSubmitting) return;
    toggleSubmitting(true);
    try {
      await props.onSubmit({ laterAnswers: mapValues(laterAnswers, 'value') });
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div>
        <div className="mt-4">
          {relatedLaterQuestions.map((laterQuestion) => {
            const { id } = laterQuestion;
            return (
              <div key={id}>
                <LaterQuestionForm
                  laterAnswer={laterAnswers[id]}
                  {...laterQuestion}
                  onChange={(_) => setLaterAnswer(id, _)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-5">
        <Button block type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
          <span className="fas fa-paper-plane mr-1" />
          送信する
        </Button>
      </div>
    </Form>
  );
}
