import { addDays, } from 'date-fns';
import { useLocalStorage, } from 'react-use';

import useTenant from './useTenant';

export default function useAvailableReferrerKey () {
  const tenant = useTenant();
  const [referrerKeyInStorage] = useLocalStorage(['referrerKey', tenant.id].join('__'));
  const [referrerKeySavedAtString] = useLocalStorage(['referrerKeySavedAt', tenant.id].join('__'));
  const referrerKeySavedAt = referrerKeySavedAtString && new Date(JSON.parse(referrerKeySavedAtString));
  const availableReferrerKey = referrerKeySavedAt > addDays(new Date(), -30) ? referrerKeyInStorage : null;
  return availableReferrerKey;
};
