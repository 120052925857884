import React, { useMemo, Fragment, } from 'react';
import { isEmpty, orderBy, sortBy, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdatePurchaseOrder } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import PurchaseOrderFormModal from '../modals/PurchaseOrderFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import InventoriesHeader from '../InventoriesHeader';
import { fields, computeLeftQuantities, } from '../../shared/models/purchaseOrder';
import QuerySelector from '../QuerySelector';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();

export default AdminPage(function AdminInventoriesSchedule (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const scheduledManufactureDateStartOn = useMemo(_ => queryParams.scheduledManufactureDateRange?.[0] ? new Date(queryParams.scheduledManufactureDateRange[0]) : null, [queryParams.scheduledManufactureDateRange]);
  const scheduledManufactureDateEndOn = useMemo(_ => queryParams.scheduledManufactureDateRange?.[1] ? new Date(queryParams.scheduledManufactureDateRange[1]) : null, [queryParams.scheduledManufactureDateRange]);
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(_ => ({ value: _.id, label: _.name }));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const bodyProducts = products.filter(_ => _.isBody);
  const productOptions = bodyProducts.map(_ => ({ value: _.id, label: _.code }));
  const productsById = keyBy(products, 'id');
  const _purchaseOrders = useCollectionSubscriptionInTenant(db.collection('purchaseOrders'));
  const purchaseOrders = useMemo(_ => orderBy(_purchaseOrders, _ => _.orderedOn.toDate()), [_purchaseOrders]);
  const _containers = useCollectionSubscriptionInTenant(db.collection('containers'));
  const containers = useMemo(_ => orderBy(_containers, _ => _.arrivalDate.toDate()), [_containers]);
  const computedPurchaseOrders = useMemo(_ => computeLeftQuantities(purchaseOrders, containers), [purchaseOrders, containers]);

  let filteredRowGroups = computedPurchaseOrders;
  if(scheduledManufactureDateStartOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.scheduledManufactureDate.toDate() >= scheduledManufactureDateStartOn);
  }
  if(scheduledManufactureDateEndOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.scheduledManufactureDate.toDate() <= scheduledManufactureDateEndOn);
  }
  filteredRowGroups = filteredRowGroups.map((rowGroup) => {
    let filteredItems = rowGroup.computedItems;
    if(!isEmpty(queryParams.productTypeIds)) {
      filteredItems = filteredItems.filter(_ => productsById[_.productId]?.productTypeIds?.some(_ => queryParams.productTypeIds.includes(_)));
    }
    if(!isEmpty(queryParams.productIds)) {
      filteredItems = filteredItems.filter(_ => queryParams.productIds.includes(_.productId));
    }
    return {
      ...rowGroup,
      filteredItems,
    };
  }).filter(_ => _.filteredItems.length > 0);

  return (
    <div>
      <div className="admin-purchase-orders container py-5 position-relative bg-white">
        <InventoriesHeader activeTab="purchaseOrders" />
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QueryDateRangeSelector label="製造予定日" defaultValue={[scheduledManufactureDateStartOn, scheduledManufactureDateEndOn]} paramName="scheduledManufactureDateRange" pickerProps={{ isClearable: true, showYearDropdown: true, dropdownMode: 'select' }} />
          <QuerySelector paramName="productTypeIds" width={400} isMulti options={productTypeOptions} label="商品種別で絞込み" />
          <QuerySelector paramName="productIds" width={300} isMulti options={productOptions} label="商品で絞込み" />
        </div>
        <div className="mt-4 d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={db.collection('purchaseOrders').doc()} FormModal={PurchaseOrderFormModal} formProps={{ products: bodyProducts, }} disabled={!canUpdatePurchaseOrder(user)} />
        </div>
        <div>
          {
            purchaseOrders.length > 0 ? (
              <table className="table table-bordered table-sm">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ width: 200 }}></th>
                    <th>PO No.</th>
                    <th>発注日</th>
                    <th>製造予定日</th>
                    <th>完成日</th>
                    <th>商品コード</th>
                    <th>製造数</th>
                    <th>コンテナ</th>
                    <th>残数</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRowGroups.map((computedPurchaseOrder) => {
                      const { id, ref, poNumber, orderedOn, scheduledManufactureDate, completedOn, filteredItems, } = computedPurchaseOrder;

                      return (
                        <Fragment key={id}>
                          {
                            filteredItems.map((item, i) => {
                              const { productId, quantity, containerItems, leftQuantity, } = item;
                              const product = productsById[productId];
                              return (
                                <tr key={id}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={filteredItems.length} className="text-nowrap">
                                          <EditButton itemRef={ref} FormModal={PurchaseOrderFormModal} formProps={{ products: bodyProducts, }} disabled={!canUpdatePurchaseOrder(user)} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdatePurchaseOrder(user)} />
                                        </td>
                                        <td rowSpan={filteredItems.length}>
                                          {poNumber}
                                        </td>
                                        <td rowSpan={filteredItems.length}>
                                          {formatDate(orderedOn.toDate(), 'yyyy/MM/dd')}
                                        </td>
                                        <td rowSpan={filteredItems.length}>
                                          {scheduledManufactureDate && formatDate(scheduledManufactureDate.toDate(), 'yyyy/MM/dd')}
                                        </td>
                                        <td rowSpan={filteredItems.length}>
                                          {completedOn && formatDate(completedOn.toDate(), 'yyyy/MM/dd')}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    {product?.code}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format()}
                                  </td>
                                  <td>
                                    {
                                      containerItems.map((containerItem, i) => {
                                        const { container, quantity } = containerItem;
                                        return (
                                          <div key={i} className="d-flex justify-content-between gap-2">
                                            <div>
                                              {container.number}
                                            </div>
                                            <div>
                                              {numeral(quantity).format()}
                                            </div>
                                          </div>
                                        );
                                      })
                                    }
                                  </td>
                                  <td className="text-right">
                                    {numeral(leftQuantity).format()}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                POは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
