import React, { useEffect, } from 'react';
import { useLocation, useRouteMatch, } from 'react-router-dom';

import { fields, } from '../shared/models/help';
import firebase from '../firebase';
import ModalButton from './ModalButton';
import EditButton from './EditButton';
import ModelFormModal from './modals/ModelFormModal';
import RichTextContent from './RichTextContent';
import useDocumentSubscription from './hooks/useDocumentSubscription';

const db = firebase.firestore();

export default function AdminHelp (props) {
  const { user, helpKey, title = 'ヘルプ', label = 'ヘルプ', icon = 'question-circle', buttonProps, }  = props;
  return (
    <ModalButton color="link" title={title} content={_ => <ModalContent helpKey={helpKey} user={user} />} {...buttonProps} >
      <span className={`fas fa-${icon}`} />
      {label}
    </ModalButton>
  );
};

function ModalContent (props) {
  const { user, helpKey } = props;
  const match = useRouteMatch();
  const ref = db.collection('helps').doc(helpKey);
  const help = useDocumentSubscription(ref, [helpKey]);
  useEffect(() => {
    if(['admin', 'staff'].includes(user?.role) && help === null) {
      ref.set({ tenantId: match.params.tenantPath, }, { merge: true });
    }
  }, [help])

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        {
          ['admin', 'staff'].includes(user?.role) && (
            <EditButton
              itemRef={ref}
              FormModal={ModelFormModal}
              formProps={{ title: 'ヘルプ編集', fields: fields() }}
            />
          )
        }
      </div>
      <div>
        <RichTextContent html={help?.body} />
      </div>
    </div>
  );
};
