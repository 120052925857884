import React, { useEffect, useRef } from 'react';
import { format as formatDate } from 'date-fns';
import { useRouteMatch } from 'react-router';
import { isEmpty } from 'lodash';

import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import { prefectures } from '../shared/config';
import useTenant from './hooks/useTenant';
import { drawQr } from '../qr';

const db = firebase.firestore();
const ordersRef = db.collection('orders');
const productsRef = db.collection('products');
const referrersRef = db.collectionGroup('referrers');
const agentShopsRef = db.collectionGroup('agentShops');

export default function Warranty(props) {
  const { orderId } = props;
  const order = useDocumentSubscription(ordersRef.doc(orderId), [orderId]);
  const products = useCollectionSubscription(
    order &&
      productsRef.where(
        'code',
        'in',
        order.orderItems.map((_) => _.productId)
      ),
    [order]
  );
  const [product] = products.filter((_) => _.isBody);
  const [referrer] = useCollectionSubscription(order && referrersRef.where('key', '==', order.referrerKey).limit(1), [
    order,
  ]);
  const agentShop = useDocumentSubscription(
    referrer ? referrer.ref.parent.parent : order?.wholesaleAgentShopId ? agentShopsRef.doc(order.wholesaleAgentShopId) : null,
    [referrer, order?.wholesaleAgentShopId]
  );
  const agent = useDocumentSubscription(agentShop && agentShop.ref.parent.parent, [agentShop]);

  return (
    order != null && (
      <div>
        <div className="admin-order-warranty container py-2 position-relative d-flex justify-content-center">
          <div className="p-4" style={{ width: 820 }}>
            <div className="mt-3 text-center font-weight-bold h3">へんしんバイク保証書</div>
            <div className="mt-4">
              <p>
                へんしんバイクをお買上げ頂き、ありがとうございました。
                <br />
                本体は、新車お買上げ日から１年間保証します。消耗品（タイヤ，ブレーキ，グリップ等）を除き、製造上の不良、
                <br />
                不具合のあった部品を新品交換します。ペダルシステム取付部分は、新車購入日から１年半まで保証します。
                <br />
                ただし、初回取付時のみ。二度目以降の取付は保証外です。
              </p>
              <div className="mt-3">
                [ 条件 ]
                <ol>
                  <li className="pt-1">
                    新車を購入された最初のオーナー様に限ります。譲り受けた場合、中古品は除きます。
                  </li>
                  <li className="pt-1">一度ご使用になった製品本体は、お取り替えできません。</li>
                  <li className="pt-1">
                    保証書に必要事項の記入やお買上げ店名の捺印のない場合、シリアルＮｏの記載がない場合
                    <br />
                    記載内容を訂正された場合は無効です。
                    <br />
                    ※公式サイトでお買い上げの場合、注文確認メールを保管してください。
                  </li>
                  <li className="pt-1">自然劣化による変色、錆、使用による傷、塗装剥がれを除きます。</li>
                  <li className="pt-1">
                    保証は、故障した部品の新品交換に限定されます。その故障や欠陥によって起こった二次的な
                    <br />
                    破損やケガについては責任を負うものではありません。
                  </li>
                  <li className="pt-1">日本国内のみ有効です。海外からの修理サービスは受けられません。</li>
                  {/* <li className="pt-1">保証対応はお買い上げ店舗で、自転車本体に保証書を添えて行ってください。</li> */}
                </ol>
                [ 注意 ]
                <ol>
                  <li className="pt-1">
                    事故、誤った使い方、整備不良、改造された場合、業務用やレンタルでの使用など一般家庭以外での
                    <br />
                    使用の場合、紛失や盗難の場合、天災の場合、保証を受けられません。
                  </li>
                  <li className="pt-1">部品交換のための工賃、運賃は含まれません。</li>
                </ol>
              </div>
              <p>
                お買上げ後すぐに、シリアルＮｏ、お買上げ日、販売店名、お名前、住所を記入してください。
                <br />
                シリアルＮｏは、ペダルシステムに刻印されています。
              </p>
            </div>
            <table className="mt-5 table table-bordered">
              <tbody>
                <tr>
                  <td width="50%">
                    <span className="mr-4">モデル名</span>
                    {product?.name}
                  </td>
                  <td width="50%">
                    <span className="mr-4">商品コード</span>
                    {product?.code}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="mr-4">お買上げ日</span>
                    {formatDate(order.createdAt.toDate(), 'yyyy年 MM月 dd日')}
                  </td>
                  <td>シリアルＮｏ.</td>
                </tr>
                <tr>
                  <td>
                    <span className="mr-4">お名前</span>
                    {order.name}
                  </td>
                  <td>
                    <span className="mr-4">お買上げ店</span>
                    {agent?.name} {agentShop?.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span className="mr-4">ご住所</span>〒 {order.postalCode}
                    </div>
                    <div className="mt-2">
                      <span>
                        {prefectures[order.prefecture]} {order.city} {order.address}
                      </span>
                    </div>
                    <div className="mt-4">
                      <span className="mr-4">TEL</span>
                      {order.phone}
                    </div>
                  </td>
                  <td>
                    <div>お問合せ先</div>
                    <div className="mt-2">
                      <TroubleInquiryQrCode orderId={orderId} product={product} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
}

const TroubleInquiryQrCode = (props) => {
  const { orderId, product } = props;

  const match = useRouteMatch();
  const tenant = useTenant();
  const tenantPath = match.params.tenantPath || tenant?.id || props.tenantId;
  const to = `/troubleInquiry?orderId=${orderId}&productId=${product?.id}&productTypeId=${product?.productTypeIds?.[0]}`;
  const url = window.location.origin + (isEmpty(tenantPath) ? '' : `/${tenantPath}`) + to;

  const canvasEl = useRef(null);
  useEffect(() => {
    if(canvasEl.current) {
      drawQr(canvasEl.current, url, null, null, null, 200);
    }
  }, [canvasEl, orderId, product]);

  return <canvas ref={canvasEl} />
};
