import React, { Fragment, useState, useRef, useEffect, } from 'react';
import { FormGroup, Label as ReactstrapLabel, Input } from 'reactstrap';
import { get, isFunction, } from 'lodash';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import numeral from 'numeral';
import { useToggle } from 'react-use';
import { PatternFormat } from 'react-number-format';

import texts from '../shared/texts';
import DateSelector from './DateSelector';
import ModalButton from './ModalButton';
import TextTemplatesModal from './modals/TextTemplatesModal';
import RichTextEditor from './RichTextEditor';
import AdminHelp from './AdminHelp';

const floatFormat = _ => numeral(_).format('0,0[.][000000]');
const validationText = (documentName, fieldName, key) => {
  return (
    get(texts.validations[documentName], `${fieldName}.${key}`)
    || get(texts.validations.general, key)
  );
}
const warningText = (documentName, fieldName, key) => {
  return (
    get(texts.warnings[documentName], `${fieldName}.${key}`) || get(texts.warnings.general, key)
  );
}

function CustomDatepickerInput({ value, onClick, }) {
  return (
    <Input readOnly onClick={onClick} value={value} className="bg-white" />
  );
}

export default function Field (props) {
  const { translate = _ => _, documentName, type, name: fieldName, label, value, setValue, setState, options: _options = [], validationErrors = [], validationWarnings = [], readOnly = _ => false, shouldHide, placeholder: _placeholder, selector = false, yearRange = [], labelProps, rows, hint, topHint, values, hasStarted, start, validations = [], inputProps, showsTextInput = false, showsTextLength = false, disablesTextTemplates = false, onCreateMultiSelectValue, pattern, showsHelp = false, setRichTextEditorRef, ...extraProps } = props;
  const placeholder = translate(_placeholder);
  const options = (_ => _?.map(_ => ({ ..._, label: translate(_.label), })))(isFunction(_options) ? _options(values) : _options);
  const richTextEditorRef = useRef(null);
  const [localValue, setLocalValue] = useState((({
    float: _ => value != null ? floatFormat(value) : null,
  })[type] || (() => null))());
  const [isHtmlMode, toggleHtmlMode] = useToggle(false); // NOTE: リッチテキスト用。もう少しstate増えるならコンポーネントを分けたいところ。
  const setValueAndStart = (value) => {
    setValue(value);
    start?.();
  };
  const isValid = validationErrors.length === 0;
  const validationCss = hasStarted && classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
  const LabelBlock = (props) => {
    return (
      <div className="d-flex justify-content-between align-items-end">
        <ReactstrapLabel {...props} {...labelProps}>
          {label}
          {validations.required != null && <span className="text-danger small">【必須】</span>}
        </ReactstrapLabel>
        {showsHelp && documentName && <AdminHelp helpKey={[documentName, fieldName].join('.')} />}
      </div>
    )
  };
  const textLengthContent = showsTextLength && <div>{value?.length || 0}</div>;
  useEffect(() => {
    setRichTextEditorRef?.(richTextEditorRef);
  }, []);
  if(shouldHide) return null;

  return translate(
    <FormGroup key={fieldName} {...extraProps}>
      {
        ({
          string: () => (
            <Fragment>
              <LabelBlock />
              <Input name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} {...inputProps} />
              {textLengthContent}
            </Fragment>
          ),
          patternString: () => (
            <Fragment>
              <LabelBlock />
              <PatternFormat name={fieldName} value={value || ''} onValueChange={({ formattedValue }) => setValueAndStart(formattedValue)} className={validationCss || ''} readOnly={readOnly(value, props)} placeholder={placeholder} format={pattern} mask="_" customInput={Input} {...inputProps} />
              {textLengthContent}
            </Fragment>
          ),
          password: () => (
            <Fragment>
              <LabelBlock />
              <Input type="password" name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} {...inputProps} />
            </Fragment>
          ),
          integer: () => (
            <Fragment>
              <LabelBlock />
              {
                showsTextInput ? (
                  <Input name={fieldName} value={value != null ? numeral(value).format() : ''} onChange={_ => setValueAndStart(_.target.value === '' ? null : numeral(_.target.value).value())} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} />
                ) : (
                  <Input name={fieldName} type="number" step="1" value={value != null ? value : ''} onChange={_ => setValueAndStart(parseInt(_.target.value))} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} {...inputProps} />
                )
              }
            </Fragment>
          ),
          float: () => {
            const floatFormat = _ => numeral(_).format('0,0[.][000000]');
            const parse = (value) => value === '' ? null : numeral(floatFormat(value)).value();
            const onChange = ({ target }) => {
              setLocalValue(target.value);
              setValueAndStart(parse(target.value));
            };
            const onBlur = _ => setLocalValue(value != null ? floatFormat(value) : '');
            return (
              <Fragment>
                <LabelBlock />
                <Input name={fieldName} value={localValue} onChange={onChange} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} onBlur={onBlur} />
              </Fragment>
            );
          },
          boolean: () => (
            <Fragment>
              <FormGroup check>
                <ReactstrapLabel check>
                  <Input name={fieldName} type="checkbox" checked={value} onChange={_ => setValueAndStart(_.target.checked)} className={validationCss} disabled={readOnly(value, props)} {...inputProps} />
                  {label}
                </ReactstrapLabel>
              </FormGroup>
            </Fragment>
          ),
          select: () => (
            <Fragment>
              <LabelBlock />
              <Select
                data-test={fieldName}
                value={options.find(_ => _.value === value) || null}
                onChange={_ => setValueAndStart(_ && _.value)}
                className={classnames('form-select', validationCss)}
                options={options}
                isDisabled={readOnly(value, props)}
                isClearable
                {...inputProps}
              />
            </Fragment>
          ),
          multiSelect: () => (
            <Fragment>
              <LabelBlock />
              <Select
                data-test={fieldName}
                isMulti
                value={(value || []).map?.(v => options.find(_ => _.value === v)) || null}
                onChange={_ => setValueAndStart((_ || []).map(_ => _.value))}
                className={classnames('form-select', validationCss)}
                options={options}
                isDisabled={readOnly(value, props)}
                isClearable
                {...inputProps}
              />
            </Fragment>
          ),
          creatableMultiSelect: () => (
            <Fragment>
              <LabelBlock />
              <CreatableSelect
                data-test={fieldName}
                isMulti
                value={(value || []).map(v => options.find(_ => _.value === v)) || null}
                onChange={_ => setValueAndStart((_ || []).map(_ => _.value))}
                onCreateOption={async (_) => {
                  const id = await onCreateMultiSelectValue(_);
                  setValueAndStart([...(value || []), id])
                }}
                className={classnames('form-select', validationCss)}
                options={options}
                isDisabled={readOnly(value, props)}
                isClearable
                {...inputProps}
              />
            </Fragment>
          ),
          datetime: () => (
            <Fragment>
              <LabelBlock />
              <div>
                <DatePicker
                  customInput={<CustomDatepickerInput />}
                  data-test={fieldName}
                  showTimeSelect
                  dateFormat="yyyy/MM/dd HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  selected={value}
                  onChange={setValueAndStart}
                  className={classnames('form-control', validationCss)}
                  readOnly={readOnly(value, props)}
                  placeholder={placeholder}
                />
              </div>
            </Fragment>
          ),
          date: () => (
            <Fragment>
              <LabelBlock />
              {/* TODO: 他のtypeでも必要になったら追加する */}
              {topHint && <small className="form-text" style={{ whiteSpace: 'pre-line' }}>{topHint}</small>}
              <div>
                {
                  selector ? (
                    <DateSelector
                      data-test={fieldName}
                      value={value}
                      onChange={setValueAndStart}
                      className={classnames(validationCss)}
                      readOnly={readOnly(value, props)}
                      yearRange={yearRange}
                    />
                  ) : (
                    <DatePicker
                      customInput={<CustomDatepickerInput />}
                      data-test={fieldName}
                      dateFormat="yyyy/MM/dd"
                      timeIntervals={10}
                      selected={value}
                      onChange={setValueAndStart}
                      className={classnames('form-control', validationCss)}
                      readOnly={readOnly(value, props)}
                      placeholderText={placeholder}
                      isClearable
                      {...inputProps}
                    />
                  )
                }
              </div>
            </Fragment>
          ),
          time: () => (
            <Fragment>
              <LabelBlock />
              <div>
                <DatePicker
                  data-test={fieldName}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  selected={value}
                  onChange={setValueAndStart}
                  className={classnames('form-control', validationCss)}
                  readOnly={readOnly(value, props)}
                  placeholder={placeholder}
                />
              </div>
            </Fragment>
          ),
          text: () => (
            <Fragment>
              <div className="d-flex justify-content-between">
                <LabelBlock />
                <div className="d-flex gap-1 align-items-center">
                  {
                    !disablesTextTemplates && (
                      <ModalButton title="文例" color="link" size="sm" Modal={TextTemplatesModal} modalProps={{ type: 'text', textTemplateKey: [documentName || '', fieldName].join('.'), onApply: _ => setValueAndStart(_), }}>
                        文例
                      </ModalButton>
                    )
                  }
                </div>
              </div>
              <Input type="textarea" value={value} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} {...inputProps} />
              {textLengthContent}
            </Fragment>
          ),
          richText: () => (
            <Fragment>
              <div className="d-flex justify-content-between">
                <LabelBlock />
                <div className="d-flex gap-1 align-items-center">
                  {
                    !disablesTextTemplates && (
                      <ModalButton title="文例" color="link" size="sm" Modal={TextTemplatesModal} modalProps={{ type: 'richText', textTemplateKey: [documentName || '', fieldName].join('.'), onApply: _ => richTextEditorRef.current?.setRteValue(_), }}>
                        文例
                      </ModalButton>
                    )
                  }
                  <FormGroup check>
                    <ReactstrapLabel check>
                      <Input type="checkbox" checked={isHtmlMode} onChange={_ => toggleHtmlMode(_.target.checked)} />
                      HTMLモード
                    </ReactstrapLabel>
                  </FormGroup>
                </div>
              </div>
              {
                isHtmlMode ? (
                  <Input type="textarea" value={value} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} {...inputProps} />
                ) : (
                  <div className="position-relative" style={{ zIndex: 0}}>
                    <RichTextEditor ref={richTextEditorRef} value={value} onChange={setValueAndStart} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} />
                  </div>
                )
              }
            </Fragment>
          ),
          file: () => (
            <Fragment>
              <LabelBlock />
              <Input type="file" name={fieldName} onChange={_ => setValueAndStart(get(_, 'target.files[0]'))} className={validationCss} readOnly={readOnly(value, props)} {...inputProps} />
            </Fragment>
          ),
          files: () => (
            <Fragment>
              <LabelBlock />
              {topHint && <small className="mb-2 text-muted form-text" style={{ whiteSpace: 'pre-line' }}>{topHint}</small>}
              <Input type="file" multiple name={fieldName} onChange={_ => setValueAndStart(get(_, 'target.files'))} className={validationCss} readOnly={readOnly(value, props)} {...inputProps} />
            </Fragment>
          ),
        })[type]()
      }
      {hint && translate(<small className="form-text text-muted" style={{ whiteSpace: 'pre-line' }}>{isFunction(hint) ? hint(values) : hint}</small>)}
      {
        hasStarted && type !== 'list' && validationErrors.length > 0 && translate(
          <div className="small text-danger mt-1">
            {
              validationErrors.map(k => (
                <div key={k}>{validationText(documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
              ))
            }
          </div>
        )
      }
      {
        hasStarted && type !== 'list' && validationWarnings.length > 0 && translate(
          <div className="small text-info mt-1">
            {
              validationWarnings.map(k => (
                <div key={k}>{warningText(documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
              ))
            }
          </div>
        )
      }
    </FormGroup>
  );
};

Field.disabledTranslation = true;
