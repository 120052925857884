const { isEmpty, } = require('lodash');

module.exports = {
  fields: () => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      title: {
        label: 'タイトル',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      description: {
        label: '説明',
        type: 'richText',
      },
      isNoindex: {
        label: '検索エンジンのインデックスに登録しない',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
      },
    };
  },
};
