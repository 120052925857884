import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmpty, keyBy, pick } from 'lodash';
import { set } from 'lodash/fp';
import { useToggle } from 'react-use';

import TenantUserPage from '../hocs/TenantUserPage';
import useAppTitle from '../hooks/useAppTitle';
import useLocale from '../hooks/useLocale';
import firebase from '../../firebase';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import NewSurveyAnswerForm from '../forms/NewSurveyAnswerForm';
import NewSurveyAnswerConfirmForm from '../forms/NewSurveyAnswerConfirmForm';

const { entries } = Object;
const storageRef = firebase.storage().ref();
const db = firebase.firestore();

function NewSurveyAnswer(props) {
  const {
    history,
    user,
    toggleLoginForm,
    match: {
      params: { tenantPath, surveyId },
    },
    setLang,
    translate,
  } = props;
  const { tag = null, eventId, entryId, frameIndex } = useQueryParams();
  const survey = useDocumentSubscription(db.collection('surveys').doc(surveyId), [surveyId]);
  const questions = useCollectionSubscriptionInTenant(db.collection('questions'), [], { initialItems: null });
  const questionsById = keyBy(questions, 'id');
  const [showsConfirm, toggleConfirm] = useToggle(false);
  const [values, setValues] = useState({});
  const onSubmitNewSurveyAnswerForm = async (_values) => {
    setValues({ ...values, ..._values });
    toggleConfirm(true);
  };
  const onSubmitConfirm = async ({ answers: _answers }) => {
    try {
      const ref = db.collection('surveyAnswers').doc();
      const answers = await entries(_answers).reduce(async (x, [k, v]) => {
        const prevs = await x;

        const question = questionsById[k];
        return {
          ...prevs,
          [k]:
            question?.type === 'imageFile' && !isEmpty(v)
              ? await Promise.all(
                  v.map(async (file) => {
                    const fileRef = storageRef.child(`surveyAnswers/${ref.id}/${k}/${new Date().toISOString()}/${file.name}`);
                    await fileRef.put(file, { contentType: file.type });
                    return {
                      ...pick(file, ['name', 'type']),
                      url: await fileRef.getDownloadURL(),
                    };
                  })
                )
              : v ?? null,
        };
      }, Promise.resolve({}));

      const supportRequired = entries(_answers).some(([key, value]) => {
        const row = survey.questionRows?.find(_ => _.questionId === key);
        return row && row.supportRequiredAnswer?.split(',').some(_ => value.includes?.(_));
      })

      await ref.set({
        tenantId: tenantPath,
        answers,
        surveyId,
        tag,
        status: supportRequired ? 'supportRequired' : 'initial',
        createdAt: new Date(),
        createdBy: user,
      });

      // TODO: 利用が無くなった段階で削除する
      if (eventId && entryId && frameIndex != null) {
        const entryRef = db.doc(`events/${eventId}/entries/${entryId}`);
        const entry = (await entryRef.get()).data();
        const entryFrames = set(`${frameIndex}.laterAnswers`, answers, entry.frames);
        await entryRef.update({ frames: entryFrames });
      }

      history.replace(`/${tenantPath}/surveys/${survey.id}/surveyAnswers/thanks`);
    } catch (error) {
      console.error(error);
      toast.error('失敗しました');
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showsConfirm]);
  useEffect(() => {
    survey?.lang && setLang(survey.lang);
  }, [survey?.lang]);
  useAppTitle(survey?.title);

  return (
    survey != null &&
    questions != null && (
      <div className="new-survey-answer position-relative">
        {!showsConfirm ? (
          <NewSurveyAnswerForm
            translate={translate}
            onSubmit={onSubmitNewSurveyAnswerForm}
            values={values}
            user={user}
            onClickLogin={toggleLoginForm}
            {...{ survey, questions }}
          />
        ) : translate(
          <NewSurveyAnswerConfirmForm
            values={values}
            onSubmit={onSubmitConfirm}
            onClickBack={toggleConfirm.bind(null, false)}
            {...{ user, survey, questions }}
          />
        )}
      </div>
    )
  );
}

NewSurveyAnswer.preview = true;
NewSurveyAnswer.initiallyOpenLogin = true;
export default TenantUserPage(NewSurveyAnswer);
