import React, { useEffect, Fragment } from 'react';
import { Button as _Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import usePrevious from '../hooks/usePrevious';
import Field from '../Field';

const { entries } = Object;

export default function ModelFormModal(props) {
  const { documentName, fields, title, isOpen, values, onClickClose, onChange = _ => _, submitLabel = '保存', renderFormHeader = _ => null, renderFooterContent = _ => _, Button = _Button, hasSpin = false, ...extraProps } = props;
  const statedFields = useFormState(values, isFunction(fields) ? fields() : fields, isOpen);
  const prevStatedFields = usePrevious(statedFields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value'), }, { onClickClose });
    toggleSubmitting(false);
  };
  useEffect(() => {
    onChange(prevStatedFields, statedFields);
  }, [Object.values(statedFields).map(_ => _.value)]);

  return (
    <Modal isOpen={isOpen} {...extraProps}>
      <ModalHeader>
        {title}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {renderFormHeader(statedFields)}
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                values={mapValues(statedFields, 'value')}
                documentName={documentName}
                {...fieldSetting}
              />
            ))
          }
        </ModalBody>
        <ModalFooter>
          {
            renderFooterContent((
              <Fragment>
                {onClickClose && <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>}
                <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
                  {hasSpin && isSubmitting && <span className="fas mr-1 fa-spin fa-spinner" />}
                  {submitLabel}
                </Button>
              </Fragment>
            ), { onSubmit, onClickClose, toggleSubmitting, statedFields, isUnsubmittable, isSubmitting, })
          }
        </ModalFooter>
      </Form>
    </Modal>
  );
};
