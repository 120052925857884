import React from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import { keyBy } from 'lodash';
import { Badge } from 'reactstrap';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import UserListSection from '../UserListSection';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { fields, scheduleTypes } from '../../shared/models/customerJourney';
import { deliveryMethods } from '../../shared/config';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';

const db = firebase.firestore();
const customerJourneysRef = db.collection('customerJourneys');

export default AdminPage(function AdminCustomerJourneyHistories(props) {
  const {
    user,
    match: {
      params: { customerJourneyId },
    },
  } = props;
  const params = useQueryParams();
  const collectionPath = `customerJourneys/${customerJourneyId}/deliveryHistories`;
  const usersRef = db.collection(collectionPath).where('customerJourneyRowKey', '==', params.key);
  const prefixColumns = [
    {
      name: 'createdAt',
      label: (_) => '配信日時',
      body: ({ orderId, createdAt }) => {
        const Component = orderId != null ? Link : 'div';
        return (
          <Component to={`/admin/orders/${orderId}`}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</Component>
        );
      },
    },
  ];

  return (
    <>
      <CustomerJourneryRowTable customerJourneyId={customerJourneyId} customerJourneyRowKey={params.key} />
      <UserListSection
        user={user}
        usersRef={usersRef}
        title="配信履歴"
        prefixColumns={prefixColumns}
        collectionPath={collectionPath}
      />
    </>
  );
});

const CustomerJourneryRowTable = (props) => {
  const { customerJourneyId, customerJourneyRowKey } = props;
  const customerJourney = useDocumentSubscription(customerJourneysRef.doc(customerJourneyId), [customerJourneyId]);
  const row = customerJourney?.customerJourneyRows.find((_) => _.key === customerJourneyRowKey);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));
  const userTagsById = keyBy(userTags, 'id');
  if (!customerJourney || !row) return <></>;
  const { key, name, deliveryMethod, scheduleType, daysAfter, subject, body, isSuspended, userTagId } = row;
  return (
    <div className="container-fluid py-5 position-relative d-flex flex-column gap-3">
      <table className="table">
        <thead className="thead-light text-center text-nowrap">
          <tr>
            <th>名称</th>
            <th>配信方式</th>
            <th>スケジュール種別</th>
            <th>Eメール件名</th>
            <th>本文</th>
            <th>配信停止</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ whiteSpace: 'pre-line' }}> {name}</td>
            <td style={{ whiteSpace: 'pre-line' }}> {deliveryMethods[deliveryMethod].label}</td>
            <td style={{ whiteSpace: 'pre-line' }}>
              {scheduleType === 'tag' ? (
                <>
                  <Badge color="secondary" className="mx-1">
                    {userTagsById[userTagId]?.name}
                  </Badge>
                  {scheduleTypes[scheduleType].label}
                </>
              ) : (
                <>
                  {scheduleTypes[scheduleType].label}
                  {daysAfter}日後
                </>
              )}
            </td>
            <td style={{ whiteSpace: 'pre-line' }}>{subject}</td>
            <td style={{ whiteSpace: 'pre-line' }}>{body}</td>
            <td style={{ whiteSpace: 'pre-line' }}>{isSuspended}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
