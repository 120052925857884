import React, { useState, useEffect, } from 'react';

import firebase from '../../firebase';
import Page from './Page';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import TenantContext from '../contexts/tenant';

const db = firebase.firestore();

export default function TenantPageHOC(WrappedComponent) {
  return Page(function TenantPage(props) {
    const {
      match: {
        params: { tenantPath },
      },
      setLocale,
      lang,
    } = props;
    const [tenant] = useCollectionSubscription(db.collection('tenants').where('path', '==', tenantPath).limit(1), [
      tenantPath,
    ]);
    const locale = useDocumentSubscription(lang && db.collection('locales').doc([tenantPath, lang].join('__')), [tenantPath, lang]);
    useEffect(() => {
      locale && setLocale(locale);
    }, [locale]);

    return (
      tenant != null && (
        <div className="tenant-page">
          <TenantContext.Provider value={tenant}>
            <WrappedComponent {...{ ...props, tenant, }} />
          </TenantContext.Provider>
        </div>
      )
    );
  });
}
