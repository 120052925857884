const { keyBy, isEmpty, } = require('lodash');
const numeral = require('numeral');

module.exports = {
  fields: () => {
    return {
      number: {
        label: 'No.',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      departureDate: {
        label: '輸送予定日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      arrivalDate: {
        label: '到着予定日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
    };
  },
  itemFields: ({ purchaseOrderId, productId, purchaseOrdersById, productsById, purchaseOrders, products, }) => {
    const purchaseOrder = purchaseOrdersById[purchaseOrderId];
    const computedItemsByProductId = keyBy(purchaseOrder?.computedItems, 'productId');
    return {
      purchaseOrderId: {
        label: 'PO',
        type: 'select',
        options: purchaseOrders.map(_ => ({ label: _.poNumber, value: _.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      productId: {
        label: '商品',
        type: 'select',
        options: (state) => {
          return purchaseOrder?.items.map(_ => productsById[_.productId]).map(_ => ({ label: `${_?.code} (${numeral(computedItemsByProductId[_.id]?.leftQuantity).format()})`, value: _?.id }));
        },
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
          lessThanOrEqualTo: v => v == null || v <= computedItemsByProductId[productId]?.leftQuantity,
        },
        showsTextInput: true,
      },
    };
  },
};
