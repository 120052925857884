import React from 'react';
import { keyBy, countBy } from 'lodash';
import numeral from 'numeral';
import qs from 'qs';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AdminPage from '../hocs/AdminPage';
import { areaFromPostalCode } from '../../shared/models/setting';
import { statuses as troubleInquiryStatuses } from '../../shared/models/troubleInquiry';
import { statuses as inquiryStatuses } from '../../shared/models/inquiry';
import { statuses as methodInquiryStatuses } from '../../shared/models/methodInquiry';
import { statuses as surveyAnswerStatuses } from '../../shared/models/surveyAnswer';
import { getProductsWithInventory } from '../../shared/models/product';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const troubleInquiriesRef = db.collection('troubleInquiries');
const inquiriesRef = db.collection('inquiries');
const methodInquiriesRef = db.collection('methodInquiries');
const surveyAnswersRef = db.collection('surveyAnswers');

export default AdminPage(function AdminMypage(props) {
  const { tenant, user } = props;
  const areaSetting = useDocumentSubscription(db.collection('settings').doc([tenant.id, 'area'].join('__')));
  const troubleInquiries = useCollectionSubscriptionInTenant(
    troubleInquiriesRef.where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const inquiries = useCollectionSubscriptionInTenant(
    inquiriesRef.where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const methodInquiries = useCollectionSubscriptionInTenant(
    methodInquiriesRef.where('name', '!=', null).where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const surveyAnswers = useCollectionSubscriptionInTenant(
    surveyAnswersRef.where('status', 'in', ['initial', 'supporting', 'awaiting'])
  );
  const data = [
    { title: '問合せ', items: inquiries, statuses: inquiryStatuses, path: _ => `/admin/inquiries?${qs.stringify({ picNames: [user.displayName], dateRange: ['2020-01-01'], })}`, },
    { title: '不具合問合せ', items: troubleInquiries, statuses: troubleInquiryStatuses, path: _ => `/admin/troubleInquiries?${qs.stringify({ picNames: [user.displayName], dateRange: ['2020-01-01'], })}`, },
    { title: '乗り方問合せ', items: methodInquiries, statuses: methodInquiryStatuses, path: _ => `/admin/methodInquiries?${qs.stringify({ picNames: [user.displayName], dateRange: ['2020-01-01'], })}`, },
    { title: 'アンケート回答', items: surveyAnswers, statuses: surveyAnswerStatuses, path: _ => `/admin/surveyAnswers?${qs.stringify({ picNames: [user.displayName], dateRange: ['2020-01-01'], })}`, },
  ].map(({ title, items, statuses, path, }) => {
    const myItems = items
      .map((item) => {
        const area = areaFromPostalCode(item.contactorPostalCode || item.destinationPostalCode || item.postalCode, areaSetting);
        const picId = item.respondedBy?.uid || area?.user.uid;
        const label = item.name || item.createdBy?.displayName;
        return {
          ...item,
          area,
          picId,
          label,
        };
      })
      .filter(_ => _.picId === user.id);
    console.log(title, myItems);
    return {
      title,
      items,
      myItems,
      statuses,
      path,
    };
  });

  return (
    <div>
      <div className="admin-events container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>管理マイページ</h4>
        </div>
        <div className="d-flex flex-wrap justify-content-around align-items-start">
          {
            data.map(({ title, myItems, statuses, path, }) => {
              return (
                <div className="card mb-4" style={{ minWidth: 450 }}>
                  <div className="card-header">{title}</div>
                  <div className="card-body">
                    <div className="d-flex flex-column gap-2">
                      {
                        myItems.slice(0, 5).map((item) => {
                          const { id, label, status, } = item;
                          const { label: statusLabel, color } = statuses[status] || {};
                          return (
                            <div key={id} className="border rounded p-3">
                              <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                              <span className="ml-1">
                                {label}
                              </span>
                            </div>
                          );
                        })
                      }
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      <TenantLink to={path()}>
                        <span className="fas fa-angle-right mr-1" />
                        もっと見る
                      </TenantLink>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
});
