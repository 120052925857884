const isAdmin = (user = {}) => user.role === 'admin';
const isStaff = (user = {}) => ['admin', 'staff'].includes(user.role);
const isAdminOrEventPic = (user = {}, event = {}) => user.role === 'admin' || event.picId === user.uid;
const isAgentAdmin = (user, agent) => agent?.members?.[user.id]?.role === 'admin';

module.exports = {
  canUpdateUser: isAdmin,
  canUpdateUserTagRules: isStaff,
  canUpdatePlaces: isAdmin,
  canUpdateQuestions: isAdmin,
  canUpdateQuestionSets: isAdmin,
  canUpdateSurveys: isAdmin,
  canUpdateUserVoices: isAdmin,
  canUpdateProduct: isAdmin,
  canUpdateProductTag: isStaff,
  canUpdateProductType: isStaff,
  canUpdateEnvelopeProduct: isStaff,
  canUpdateContentProduct: isStaff,
  canUpdateCouponTemplate: isStaff,
  canUpdateCoupon: isStaff,
  canUpdateOrder: isAdmin,
  canUpdateInquiry: isStaff,
  canApproveInquiry: isAdmin,
  canUpdateTroubleInquiry: isStaff,
  canApproveTroubleInquiry: isAdmin,
  canUpdateMethodInquiry: isStaff,
  canUpdateQrUrl: isStaff,
  canUpdateAgent: (user, agent) => isStaff(user) || isAgentAdmin(user, agent),
  canUpdateAgentMember: isStaff,
  canUpdateAgentContractPlan: isAdmin,
  canUpdateQrBottomDisplay: isAdmin,
  canUpdateInquiryType: isStaff,
  canUpdateMagazine: isStaff,
  canCreateSmsDelivery: isStaff,
  canUpdateCustomerJourney: isStaff,
  canUpdateEnvelopeSchedule: isStaff,
  canCreateEnvelopeDelivery: isStaff,
  canCreateContact: isStaff,
  canCreateEvent: (user = {}) => ['admin', 'staff'].includes(user.role),
  canUpdateEvent: isAdminOrEventPic,
  canDeleteEvent: isAdminOrEventPic,
  canCreateLecture: isAdminOrEventPic,
  canUpdateLecture: isAdminOrEventPic,
  canDeleteLecture: isAdminOrEventPic,
  canCreateEventInvitation: isAdminOrEventPic,
  canUpdateEntry: isAdminOrEventPic,
  canUpdateEventProductType: isAdmin,
  canUpdateLectureType: isAdmin,
  canUpdateCartSettings: isAdmin,
  canUpdateEmailSettings: isAdmin,
  canUpdateTextSettings: isStaff,
  canUpdateContent: isAdmin,
  canUpdateShortUrls: isAdmin,
  canUpdateInitialTags: isStaff,
  canUpdateTenantSettings: isAdmin,
  canUpdateExportRequests: isStaff,
  canApproveExportRequests: isAdmin,
  canUpdatePaceMakerSetting: isStaff,
  canUpdateSurveyAnswer: isStaff,
  canUpdatePurchaseOrder: isStaff,
  canUpdateContainer: isStaff,
};
