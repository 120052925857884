import { Fragment } from 'react';
import { isEmpty, sortBy, pick, omit, get, keyBy } from 'lodash';
import { format as formatDate } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import { canUpdateInquiry, canApproveInquiry } from '../../shared/abilities';
import {
  statuses,
  fields as _fields,
  itemFields,
  shipmentFields,
  commentFields,
  activityTypes,
  shipmentMailBody,
  reimbursementResultFields,
  approvalRequestFields,
  approvalFields,
  shippingDestination,
  supportContentsFields,
  emailFields,
  replyEmailBody,
} from '../../shared/models/inquiry';
import { destinationFields } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import { supportMeans } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCreateActivity from '../hooks/useCreateActivity';
import InquiryItemsFormModal from '../modals/InquiryItemsFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import { AddInTenantButton } from '../v9/AddInTenantButton';
import { EditButton } from '../v9/EditButton';
import { InquiryShipmentButton } from '../InquiryShipmentButton';
import { InquiryUpdatePicButton } from '../InquiryUpdatePicButton';
import { InquiryReimbursementButton } from '../InquiryReimbursementButton';
import { InquiryApprovalRequestButton } from '../InquiryApprovalRequestButton';
import { InquiryApprovalButton } from '../InquiryApprovalButton';
import InquirySubAccountAlert from '../InquirySubAccountAlert';
import ModalButton from '../ModalButton';
import Activity from '../Activity';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import { useProductTypeCollection, tenantProductTypesQuery } from '../../models/productType';
import { useProductCollection, tenantProductsQuery } from '../../models/product';
import { useInquiryDocument, inquiryRef, updateInquiry } from '../../models/inquiry';
import { useInquiryTypeCollection, tenantInquiryTypesQuery } from '../../models/inquiryType';
import {
  useInquiryCommentCollection,
  tenantInquiryCommentsQuery,
  newInquiryCommentRef,
} from '../../models/inquiryComment';
import { useActivityCollection, tenantActivitiesByPayloadQuery } from '../../models/activity';
import { useSurveyCollection, tenantSurveysQuery } from '../../models/survey';
import { useOrderDocument, orderRef } from '../../models/order';
import firebase, { functions } from '../../firebase';
import InquiryReinquiryAlert from '../InquiryReinquiryAlert';

const { entries, keys } = Object;
const db = firebase.firestore();
const inquiryShipment = functions.httpsCallable('inquiryShipment');
const storageRef = firebase.storage().ref();

const sendInquiryEmail = functions.httpsCallable('sendInquiryEmail');

export default AdminPage(function AdminInquiry(props) {
  const {
    user,
    match: { params },
  } = props;
  const { inquiryId } = params;
  const tenant = useTenant();
  const createActivity = useCreateActivity();
  const { data: productTypes = [] } = useProductTypeCollection(tenantProductTypesQuery(tenant?.id));
  const { data: products = [] } = useProductCollection(tenantProductsQuery(tenant?.id));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts.map((product) => ({
    ...product,
    ...(product.isHidden && { label: `[非表示] ${product.name}` }),
  }));
  const productsById = keyBy(products, 'id');
  const { data: inquiry } = useInquiryDocument(inquiryRef(inquiryId));
  const { data: inquiryTypes = [] } = useInquiryTypeCollection(tenantInquiryTypesQuery(tenant?.id));
  const inquiryTypeChildren = useCollectionSubscriptionInTenant(db.collection('inquiryTypeChildren'));
  const fields = {
    ...omit(_fields({ inquiryTypes, inquiryTypeChildren, }), ['files']),
    ...destinationFields(),
  };
  const { data: comments = [] } = useInquiryCommentCollection(
    tenantInquiryCommentsQuery(inquiryRef(inquiryId), tenant?.id)
  );
  const { data: activities = [] } = useActivityCollection(
    tenantActivitiesByPayloadQuery(tenant?.id, 'inquiryId', inquiryId)
  );
  const { label: statusLabel, color } = (inquiry && statuses[inquiry.status]) || {};
  const shippable =
    inquiry &&
    !inquiry.shipmentRequested &&
    (!inquiry.approvalRequired || inquiry.approvedAt) &&
    inquiry.items?.every((_) => _.quantity <= get(productsById, [_.productId, 'normalInventory'], 0));
  const shipmentOrderId = inquiry?.orderId;
  const { data: shipmentOrder } = useOrderDocument(shipmentOrderId && orderRef(shipmentOrderId), [shipmentOrderId]);
  const thWidth = 220;
  const { data: surveys = [] } = useSurveyCollection(tenantSurveysQuery(tenant?.id));
  const onClickStart = async () => {
    if (!window.confirm('本当に対応開始しますか？')) return;

    await inquiry.ref.update({ status: 'supporting', respondedBy: user });
    await createActivity('startSupportOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onClickUnfinish = async () => {
    if (!window.confirm('本当に対応中に戻しますか？')) return;

    const status = inquiry.hasReimbursement && !inquiry.reimbursementRequest?.requestedAt ? 'awaiting' : 'supporting';
    await inquiry.ref.update({ status });
    await createActivity('unfinishSupportOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const validateOnDone = async () => {
    if (!window.confirm('本当に対応完了しますか？')) return false;
    return true;
  };
  const onDoneFinished = async (values) => {
    const note = `${values.supportSummary} (${values.supportMeans.map((_) => supportMeans[_]).join(',')})`;
    await inquiry.ref.update({ status: 'supported' });
    await createActivity('finishSupportOfInquiry', user, {
      inquiryId,
      note,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const validateeOnEdit = async ({ items }) => {
    const addedItems = items.filter(({ productId, quantity }) =>
      (inquiry.items || []).every((_) => _.productId !== productId || quantity > _.quantity)
    );
    const approvalRequired = items.some(({ productId }) => productsById[productId].approvalRequired);
    const addedApprovalRequired = addedItems.some(({ productId }) => productsById[productId].approvalRequired);
    addedApprovalRequired && window.alert('要承認の商品が追加されました。承認申請ボタンにより承認申請をしてください');

    // NOTE: 要承認の商品が追加されたら承認ステータスを初期値に戻す
    await updateInquiry(inquiryId, {
      approvalRequired,
      ...(addedApprovalRequired ? { approvalStatus: 'initial', approvedAt: null } : {}),
    });
    return true;
  };
  const onEdited = async ({ items }) => {
    await createActivity('editInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onShipmentFinished = async () => {
    await createActivity('createOrderOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onSubmitUpdatePic = async () => {
    await createActivity('updatePicOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onReimbursementStarted = async () => {
    await createActivity('startReimbursementOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onReimbursementFinished = async () => {
    await createActivity('finishReimbursementOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onReimbursementCancelled = async () => {
    await createActivity('cancelReimbursementOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onRequestApproval = async ({ approvalRequestComment }) => {
    await createActivity('requestedApprovalOfInquiry', user, {
      inquiryId,
      note: approvalRequestComment,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onApprove = async ({ approvalOrRejectionComment }) => {
    await createActivity('approvedInquiry', user, {
      inquiryId,
      note: approvalOrRejectionComment,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onReject = async ({ approvalOrRejectionComment }) => {
    await createActivity('rejectedInquiry', user, {
      inquiryId,
      note: approvalOrRejectionComment,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onEditedDestination = async () => {
    await createActivity('editDestinationOfInquiry', user, {
      inquiryId,
      uid: inquiry.createdBy?.uid || null,
    });
  };
  const onSubmitEmail = async (values, { onClickClose, }) => {
    const body = replyEmailBody(inquiry, values.body, inquiryTypes, inquiryTypeChildren, window.location.origin)
    const message = dedent`
      以下のようなメールを送ります。よろしいですか？

      -----------------------------

      ${body}
    `;
    if (!window.confirm(message)) return;
    
    try {
      const attachedFiles = await Promise.all(
        Array.from(values.files || []).map(async (file) => {
          const storagePath =`inquiries/${inquiryId}/attachedFiles/${file.name}`;
          const fileRef = storageRef.child(storagePath);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            storagePath,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      await sendInquiryEmail({ inquiryId: inquiry.id, values, });
      await inquiry.ref.update({
        messages: firebase.firestore.FieldValue.arrayUnion({ ...omit(values, 'files'), attachedFiles, sentAt: new Date(), sentBy: pick(user, ['email', 'uid', 'displayName']), }),
      });
      await createActivity('sendInquiryEmail', user, { inquiryId, uid: inquiry.createdBy.uid, body, attachedFiles });
      toast.success('送信しました');
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    inquiry != null && (
      <div className="admin-inquiry container-fluid">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>お問合せ詳細</h4>
          </div>
          <InquirySubAccountAlert inquiry={inquiry} />
          <InquiryReinquiryAlert inquiry={inquiry} />
          <div className="d-flex justify-content-end mb-3 gap-2">
            {inquiry.status !== 'supported' && (
              <InquiryUpdatePicButton
                inquiryRef={inquiryRef(inquiry.id)}
                inquiry={inquiry}
                onSubmit={onSubmitUpdatePic}
              />
            )}
            <ModalButton Modal={ModelFormModal} modalProps={{ title: 'メール送信', fields: emailFields(), submitLabel: '送信する', onSubmit: onSubmitEmail, }}>
              <span className="fas fa-paper-plane mr-1" /> メール送信
            </ModalButton>
            {['supporting', 'awaiting'].includes(inquiry.status) && (
              <Fragment>
                {inquiry.approvalRequired && (
                  <>
                    {inquiry.approvalStatus === 'requested' ? (
                      <InquiryApprovalButton
                        inquiryRef={inquiryRef(inquiry.id)}
                        inquiry={inquiry}
                        user={user}
                        fields={approvalFields}
                        onApprove={onApprove}
                        onReject={onReject}
                        disabled={!canApproveInquiry(user)}
                      />
                    ) : (
                      <InquiryApprovalRequestButton
                        inquiryRef={inquiryRef(inquiry.id)}
                        inquiry={inquiry}
                        fields={approvalRequestFields}
                        onRequest={onRequestApproval}
                        disabled={!canUpdateInquiry(user)}
                      />
                    )}
                  </>
                )}
                {inquiry.createdBy && (
                  <InquiryReimbursementButton
                    inquiryRef={inquiryRef(inquiry.id)}
                    inquiry={inquiry}
                    user={user}
                    disabled={!canUpdateInquiry(user)}
                    onStart={onReimbursementStarted}
                    onFinish={onReimbursementFinished}
                    onCancel={onReimbursementCancelled}
                  />
                )}
                <EditButton
                  label="発送先変更"
                  icon={null}
                  itemRef={inquiryRef(inquiry.id)}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '発送先を変更する',
                    fields: destinationFields,
                    submitLabel: '変更する',
                    values: shippingDestination(inquiry),
                  }}
                  disabled={!canUpdateInquiry(user)}
                  onFinish={onEditedDestination}
                />
                <EditButton
                  itemRef={inquiryRef(inquiry.id)}
                  FormModal={InquiryItemsFormModal}
                  disabled={!canUpdateInquiry(user)}
                  formProps={{ products: selectableProducts, itemFields }}
                  onFinish={onEdited}
                  validateOnSubmit={validateeOnEdit}
                />
                <InquiryShipmentButton
                  inquiryRef={inquiryRef(inquiry.id)}
                  inquiry={inquiry}
                  user={user}
                  disabled={!shippable}
                  products={products}
                  productTypes={productTypes}
                  shipmentFields={shipmentFields}
                  shipmentMailBody={shipmentMailBody}
                  onFinish={onShipmentFinished}
                  inquiryShipment={inquiryShipment}
                  shipmentOrder={shipmentOrder}
                />
                <EditButton
                  label="対応完了"
                  icon={null}
                  color="primary"
                  itemRef={inquiryRef(inquiry.id)}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'サポート内容を登録する',
                    fields: supportContentsFields({ surveys }),
                    submitLabel: '登録する',
                  }}
                  validateOnSubmit={validateOnDone}
                  onFinish={onDoneFinished}
                />
              </Fragment>
            )}
            {inquiry.status === 'initial' && (
              <Button color="primary" onClick={onClickStart}>
                対応開始
              </Button>
            )}
            {inquiry.status === 'supported' && (
              <Button color="primary" outline onClick={onClickUnfinish}>
                対応中に戻す
              </Button>
            )}
          </div>
          <div className="d-sm-flex justify-content-around mt-5 gap-4">
            <div className="flex-fill">
              <table className="table table-bordered">
                <tbody className="thead-light">
                  <tr>
                    <th style={{ width: thWidth }}>ID</th>
                    <td>
                      <div>
                        {inquiry.id}
                        {inquiry.isAdminCreated && <span className="badge badge-info ml-1">管理者作成</span>}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>状態</th>
                    <td>
                      <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                    </td>
                  </tr>
                  {!isEmpty(inquiry.items) && (
                    <tr>
                      <th style={{ width: thWidth }}>パーツ</th>
                      <td>
                        {inquiry.items?.map((item, i) => {
                          const { productId, quantity } = item;
                          const product = productsById[productId];
                          return (
                            product != null && (
                              <div key={i}>
                                {inquiry.status !== 'supported' &&
                                  !inquiry.shipmentRequested &&
                                  quantity > product.normalInventory && (
                                    <span className="mr-1 text-danger small">[在庫不足]</span>
                                  )}
                                <span>{product.code}</span>
                                <span className="ml-2">{product.name}</span>
                                <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                              </div>
                            )
                          );
                        })}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th style={{ width: thWidth }}>ファイル</th>
                    <td>
                      {inquiry.files.map(({ name, url }, i) => {
                        return (
                          <div key={i}>
                            <a href={url} target="_blank">
                              {name}
                            </a>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>お問合せ日時</th>
                    <td>{formatDate(inquiry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                  </tr>
                  {entries({ ...fields }).map(([fieldName, fieldSetting]) => {
                    const label = keys(destinationFields()).includes(fieldName)
                      ? `発送先 - ${fieldSetting.label}`
                      : fieldSetting.label;
                    const displayValue = fieldDisplayValue(inquiry[fieldName], { values: inquiry, ...fieldSetting });
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldName === 'name' && inquiry.createdBy ? (
                            <TenantLink to={`/admin/users/${inquiry.createdBy.uid}`}>{displayValue}</TenantLink>
                          ) : (
                            displayValue
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {entries(reimbursementResultFields()).map(([fieldName, fieldSetting]) => {
                    const { label } = fieldSetting;
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldDisplayValue(inquiry.reimbursementResult?.[fieldName], fieldSetting)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5 mt-sm-0" style={{ minWidth: '40vw' }}>
              <div>
                <div className="d-flex justify-content-end mb-3">
                  <AddInTenantButton
                    label="新規コメント"
                    itemRef={newInquiryCommentRef(inquiryRef(inquiryId))}
                    processValues={(_) => ({
                      ..._,
                      createdBy: pick(user, ['uid', 'email', 'displayName']),
                    })}
                    FormModal={ModelFormModal}
                    formProps={{ title: '新規コメント', fields: commentFields }}
                  />
                </div>
                <div className="overflow-auto" style={{ maxHeight: 360 }}>
                  {comments.length > 0 ? (
                    <div>
                      {comments.map(({ id, body, createdAt, createdBy }) => {
                        return (
                          <div key={id} className="card p-3 mb-3">
                            <div className="small text-muted mb-1">
                              {createdBy?.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                            </div>
                            <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>コメントはまだありません</div>
                  )}
                </div>
              </div>
              <hr className="my-5" />
              <div>
                <h5>アクティビティ</h5>
                <div className="overflow-auto" style={{ maxHeight: 360 }}>
                  {activities.length > 0 ? (
                    <div className="d-flex flex-column gap-1">
                      {sortBy(activities, (_) => _.createdAt.toDate(), 'desc').map((_) => (
                        <Activity activity={_} activityTypes={activityTypes} />
                      ))}
                    </div>
                  ) : (
                    <div>アクティビティはまだありません</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
