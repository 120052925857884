const { isHiragana } = require('wanakana');
const { countBy, upperFirst, mapKeys, pick, isNaN, orderBy, sumBy, get, isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');
const { format: formatDate, addDays, setMonth, eachDayOfInterval, startOfMonth, endOfMonth, } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');

const { oldReferralFeeRate, prefectures, deliveryTimes, defaultShipmentFee, shipmentFeeThreshold, routes, } = require('../config');
const { computeDiscountAmount, } = require('./coupon');

const { entries } = Object;

const checkSimilarOrdersDays = 7;

const cammacsStatuses = {
  initial: { label: '連携待ち', labelForAgent: '出荷待ち', },
  imported: { label: '出荷待ち', },
  shipped: { label: '出荷済み', },
  cancelled: { label: 'キャンセル済み', },
};

const conclusiveReferralFee = (productsById, order) => {
  const { amount = 0, discountAmount = 0, referralFee, orderItems, } = order;
  const nonBodyAmount = sumBy(orderItems.map(_ => ({ ..._, product: productsById[_.productId], })).filter(_ => !_.product?.isBody), _ => _.product?.price * _.quantity);
  return referralFee != null ? referralFee : Math.round((amount - discountAmount - nonBodyAmount) * oldReferralFeeRate);
};

const computeReferralFee = (orderItems, productsById, coupons) => {
  return sumBy(orderItems, (orderItem) => {
    const { productId, quantity } = orderItem;
    const product = productsById[productId];
    if (orderItem.referralFeeType === 'amount' && orderItem.referralFeeAmount)
      return orderItem.referralFeeAmount;
    
    const referralFeeRate =
      orderItem.referralFeeRate != null
        ? orderItem.referralFeeRate
        : product.referralFeeRate != null
        ? product.referralFeeRate
        : 0;
    const price = orderItem.price != null ? orderItem.price : product.price;
    const amount = price * quantity;
    const discountAmount = !isEmpty(coupons) ? sumBy(coupons, _ => computeDiscountAmount([{ ...orderItem, product }], _)) : 0;
    return Math.round((amount - discountAmount) * referralFeeRate * 0.01);
  });
};

const isSplitedOrder = (orderItems) => {
  return orderItems.some(_ => _.normalOrderCount > 0) && orderItems.some(_ => _.preOrderCount > 0);
};

const oneOrderItems = (orderItems) => {
  return orderItems.map(_ => ({
    ...pick(_, ['productId', 'quantity', 'planDate', 'isGift']),
    preOrderCount: _.normalOrderCount || _.preOrderCount,
    normalOrderCount: 0,
  }));
};

const ordererFields = () => {
  return {
    name: {
      label: 'お名前',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    nameKana: {
      type: 'string',
      label: 'お名前ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    phone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
      placeholder: '08012345678 （ハイフンなし）'
    },
    email: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
    },
    postalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    prefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    city: {
      type: 'string',
      label: '市区町村',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    address: {
      type: 'string',
      label: '番地・建物名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    route: {
      type: 'select',
      label: 'どちらでお知りになりましたか？',
      options: routes.map(_ => ({ value: _, label: _ })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const destinationFields = () => {
  return {
    destinationName: {
      label: 'お名前',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    destinationNameKana: {
      type: 'string',
      label: 'お名前ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    destinationPhone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
    },
    destinationPostalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    destinationPrefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    destinationCity: {
      type: 'string',
      label: '市区町村',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    destinationAddress: {
      type: 'string',
      label: '番地・建物名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    destinationEmail: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
    },
  };
};

const contactorFields = () => {
  return {
    contactorName: {
      label: 'お名前',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    contactorNameKana: {
      type: 'string',
      label: 'お名前ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    contactorPhone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
    },
    contactorPostalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    contactorPrefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    contactorCity: {
      type: 'string',
      label: '市区町村',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    contactorAddress: {
      type: 'string',
      label: '番地・建物名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    contactorEmail: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
    },
  };
};

const referrerFields = () => {
  return {
    referrerKey: {
      label: 'リファラキー',
      type: 'string',
    },
  };
};

const deliveryFields = ({ isWish = false, isWholesale = false, cartSettings = {}, hasPreOrder = false, } = {}) => {
  const canEditDeliveryDate = get(cartSettings, 'deliveryDateEnabled') !== false && !hasPreOrder && !isWish;
  const december = setMonth(new Date(), 11);
  const january5 = addDays(endOfMonth(december), 5)
  return {
    deliveryDate: {
      type: 'date',
      label: '配送希望日',
      placeholder: canEditDeliveryDate ? '指定なし' : '指定できません',
      inputProps: {
        minDate: addDays(new Date(), isWholesale ? 10 : 7),
        maxDate: addDays(new Date(), 30),
        // NOTE: 来年以降も除外しそうなので一旦コメントアウト
        excludeDates: eachDayOfInterval({ start: startOfMonth(december), end: january5, }),
      },
      readOnly: _ => !canEditDeliveryDate,
      topHint: get(cartSettings, 'deliveryDateDescription'),
    },
    deliveryTime: {
      type: 'select',
      label: '配送希望時間帯',
      options: entries(deliveryTimes).map(([k, _]) => ({ label: _.label, value: k, })),
      initialValue: 'anytime',
      hint: get(cartSettings, 'deliveryTimeDescription'),
    },
  };
};

const wishFields = ({ isWish } = {}) => {
  return {
    wishMessage: {
      type: 'text',
      label: 'メッセージ',
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => !isWish,
      rows: 5,
    },
    wishFile: {
      label: '画像や動画',
      type: 'file',
      inputProps: {
        accept: 'image/*,video/*',
      },
      hidden: _ => !isWish,
    }
  };
};

const couponFields = () => {
  return {
    couponId: {
      type: 'string',
      label: '優待コード',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fields = (...args) => {
  return {
    ...ordererFields(...args),
    ...deliveryFields(...args),
    ...wishFields(...args),
    ...destinationFields(...args),
    ...contactorFields(...args),
    ...contactorFields(...args),
    ...couponFields(...args),
  };
};

const shipmentFeeByAmount = amount => amount === 0 || amount >= shipmentFeeThreshold ? 0 : defaultShipmentFee;
const shipmentFee = _ => _.shipmentFee != null ? _.shipmentFee
  : (_.viaTroubleInquiry || _.viaInquiry) ? 0
  : shipmentFeeByAmount(_.amount);
// NOTE: 合計 + 送料 + 決済手数料 + 代引手数料
const totalAmount = _ => (paymentMethod(_) === 'billing' ? _.wholesaleAmount : _.amount) + shipmentFee(_) + 0 + 0;
const totalPaymentAmount = ({ amount = 0, discountAmount = 0, shipmentFee = 0 }) => amount - discountAmount + shipmentFee;

const paymentMethods = {
  unnecessary: { label: '決済不要', },
  billing: { label: '請求書払い', labelForUser: '店頭払い', },
  credit: { label: 'クレジットカード', },
};

const paymentMethod = (order) => {
  return (order.viaTroubleInquiry || order.viaInquiry) ? (
    'unnecessary'
  ) : !isEmpty(order.wholesaleAgentId) ? (
    'billing'
  ) : (
    'credit'
  );
};

const cammacsFields = {
  受注番号: _ => _.id,
  注文日: _ => formatDate(utcToZonedTime(_.createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd'),
  注文時間: _ => formatDate(utcToZonedTime(_.createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss'),
  商品番号: (o, p) => p.code,
  商品名: (o, p) => p.name,
  個数: (o, p, q) => q,
  '単価（税込）': (o, p, q, a, as, wholesalePrice) => {
    return (o.viaTroubleInquiry || o.viaInquiry || o.isEnvelopeOrder) ? (
      0
    ) : paymentMethod(o) === 'billing' ? (
      wholesalePrice != null ? wholesalePrice : Math.round(p.price * (p.wholesalePriceRate / 100 || 0))
    ) : p.price;
  },
  注文者名字: _ => (_.name || _.destinationName),
  注文者名前: _ => '',
  メールアドレス: _ => _.email,
  注文者郵便番号１: _ => (_.postalCode || _.destinationPostalCode).replace('-', '').slice(0, 3),
  注文者郵便番号２: _ => (_.postalCode || _.destinationPostalCode).replace('-', '').slice(-4),
  '注文者住所：都道府県': _ => prefectures[_.prefecture],
  '注文者住所：都市区': _ => (_.city || _.destinationCity),
  '注文者住所：町以降': _ => (_.address || _.destinationAddress),
  注文者電話番号１: _ => (_.phone || _.destinationPhone).slice(0, 3),
  注文者電話番号２: _ => (_.phone || _.destinationPhone).slice(3, 7),
  注文者電話番号３: _ => (_.phone || _.destinationPhone).slice(7),
  送付先名字: _ => _.destinationName || '',
  送付先名前: _ => '',
  送付先郵便番号１: _ => _.destinationPostalCode.replace('-', '').slice(0, 3),
  送付先郵便番号２: _ => _.destinationPostalCode.replace('-', '').slice(-4),
  '送付先住所：都道府県': _ => prefectures[_.destinationPrefecture],
  '送付先住所：都市区': _ => _.destinationCity,
  '送付先住所：町以降': _ => _.destinationAddress,
  送付先電話番号１: _ => _.destinationPhone.slice(0, 3),
  送付先電話番号２: _ => _.destinationPhone.slice(3, 7),
  送付先電話番号３: _ => _.destinationPhone.slice(7),
  お届け指定日: _ => _.deliveryDate != null ? formatDate(utcToZonedTime(_.deliveryDate.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd') : '',
  お届け日時: _ => get(deliveryTimes, `${_.deliveryTime}.cammacs`),
  決済方法: _ => paymentMethods[paymentMethod(_)].label,
  合計: _ => paymentMethod(_) === 'billing' ? _.wholesaleAmount : _.amount,
  送料: shipmentFee,
  代引料: _ => 0,
  // NOTE: 合計金額 - ポイント - クーポン
  請求金額: _ => totalAmount(_) - 0 - (_.discountAmount || 0),
  ポイント利用額: _ => 0,
  クーポン利用額: _ => _.discountAmount || 0,
  合計金額: totalAmount,
  決済手数料: _ => 0,
  得意先CD: (o, p, q, a, as) => as?.customerCode || null,
  発注番号: _ => _.agentOrderNumber || '', // 仮
  配送便CD: _ => _.deliveryCode || '', // 仮
  梱包指示備考: (o, p, q, a, as) => [
    a?.hidesAmountInSlip ? '[金額なし]' : '',
    o.packageNote || '',
    p.packageNote || '',
  ].join(' '),
};

const activityTypes = {
  editContactorOfOrder: {
    text: () => '連絡先変更',
  },
  checkCouponOfOrder: {
    text: () => '優待確認',
  },
  uncheckCouponOfOrder: {
    text: () => '優待確認解除',
  },
};

const payerFields = () => {
  return {
    ...mapKeys(
      pick(ordererFields(), ['name', 'nameKana', 'phone', 'email']),
      (v, k) => 'payer' + upperFirst(k),
    ),
  };
};

const generateShippingRequestRows = (orders) => {
  return orders.flatMap((order) => {
    return order.orderItems.map((orderItem) => {
      return {
        '処理区分': 2,
        '荷主コード': 'VIFA001',
        '出荷依頼番号': order.id,
        '出荷タイプ': 0,
        '出荷予定日': formatDate(new Date(), 'yyyyMMdd'),
        '伝票のみ発行フラグ': 0,
        '引当時ロット混在可能フラグ': 0,
        '出荷フラグ': 0,
        '納品先コード': '',
        '納品先名称': order.destinationName,
        '納品先担当者名': '',
        '納品先郵便番号': order.destinationPostalCode,
        '納品先住所１': [prefectures[order.destinationPrefecture], order.destinationCity].join(''),
        '納品先住所２': order.destinationAddress,
        '納品先住所３': '',
        '納品先電話番号': order.destinationPhone,
        '納品先FAX番号': '',
        '納品先E-Mail': order.destinationEmail || order.email,
        '納品先国コード': '',
        '納品先入出港コード': '',
        '納品先地区コード': '',
        '依頼主変更フラグ': 0,
        '依頼主コード': '',
        '依頼主名称': '',
        '依頼主郵便番号': '',
        '依頼主住所１': '',
        '依頼主住所２': '',
        '依頼主住所３': '',
        '依頼主電話番号': '',
        '運送会社コード': 312,
        '元着区分': 0,
        '代引きフラグ': 0,
        '決済方法': '',
        '便種': '',
        '納品指定日': '',
        '配達希望時間コード': '',
        '営業店止置フラグ': 0,
        '営業店コード': '',
        '品目金額合計': orderItem.price * orderItem.quantity,
        '送料（税抜）': 0,
        '決済手数料（税抜）': 0,
        '消費税率': 10,
        '割引・利用ポイント１': 0,
        '割引・利用ポイント２': 0,
        '割引・利用ポイント３': 0,
        '取得ポイント': 0,
        '残ポイント': 0,
        '消費税計算単位': 0,
        '端数処理': 0,
        '消費税額': 0,
        '請求金額': orderItem.price * orderItem.quantity,
        '消費税率10％対象 品目金額合計': orderItem.price * orderItem.quantity,
        '消費税率10％対象 割引・利用ポイント１': 0,
        '消費税率10％対象 割引・利用ポイント２': 0,
        '消費税率10％対象 割引・利用ポイント３': 0,
        '消費税率10％対象 取得ポイント': 0,
        '消費税率10％対象 残ポイント': 0,
        '消費税率10％対象 消費税額': 0,
        '消費税率8％対象 品目金額合計': 0,
        '消費税率8％対象 割引・利用ポイント１': 0,
        '消費税率8％対象 割引・利用ポイント２': 0,
        '消費税率8％対象 割引・利用ポイント３': 0,
        '消費税率8％対象 取得ポイント': 0,
        '消費税率8％対象 残ポイント': 0,
        '消費税率8％対象 消費税額': 0,
        '送り状記事': '',
        '納品書備考': '',
        '梱包者向け作業指示': '',
        'のし紙加工フラグ': 0,
        'のし備考': '',
        'ラッピング加工フラグ': 0,
        'ラッピング備考': '',
        'メッセージカードフラグ': 0,
        'メッセージカード備考': '',
        '受注番号': order.id,
        '店舗区分': '',
        '購入区分（定期発送の場合）': '',
        '会員番号': '',
        '定期購入回数': '',
        '初回購入日': '',
        '会員ランク割引': '',
        '注文日／出荷日／着日': '',
        '請求年月日': '',
        '振込用紙フラグ': 0,
        '振込振替用紙バーコードデータ': '',
        '振込振替用紙郵便局サービスコード１': '',
        '振込振替用紙郵便局サービスコード２': '',
        '海外郵便番号': '',
        '内容品総額': '',
        '重量（ｋｇ）': '',
        '損害要償額または保険金額': '',
        '備考１': '',
        '備考２': '',
        '備考３': '',
        '備考４': '',
        '備考５': '',
        '品目コード': orderItem.productId,
        '品目名称': '',
        '荷姿単位': 'PCS',
        '予定数量': '',
        '品目単価': orderItem.price,
        '_消費税率': '',
        '１個当たりの消費税額': 0,
        '品目金額': orderItem.price * orderItem.quantity,
        '_消費税額': 0,
        'ロット日付': '',
        'ロット文字列': '',
        '良品・不良品区分': 0,
        'ＨＳコード': '',
        '_重量（ｋｇ）': '',
        '金額': '',
        '_備考１': '',
        '_備考２': '',
        '_備考３': '',
        '_備考４': '',
        '_備考５': '',
      };
    });
  });
};

module.exports = {
  ordererFields,
  deliveryFields,
  wishFields,
  destinationFields,
  contactorFields,
  referrerFields,
  couponFields,
  fields,
  orderItemFields: ({ products = [], }) => {
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map((product) => ({ value: product.id, product: product, })),
        validations: {
          required: v => !isEmpty(v),
        },
        inputProps: {
          isSearchable: false,
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThan0: v => v != null && v > 0,
          lessThanOrEqualToInventory: (v, s) => {
            if(v == null || isNaN(v)) return true;

            const product = products.find(_ => _.id === s.productId);
            if(!product) return true;

            const { normalOrderableQuantity = 0, receivingPlanItems = [], } = product;
            const [maxReceivingPlanItem] = orderBy(receivingPlanItems, 'leftQuantity', 'desc');
            return v <= normalOrderableQuantity + get(maxReceivingPlanItem, 'leftQuantity', 0);
          },
        },
      },
    };
  },
  cammacsFields,
  shipmentFeeByAmount,
  checkSimilarOrdersDays,
  isSplitedOrder,
  oneOrderItems,
  computeReferralFee,
  conclusiveReferralFee,
  paymentMethods,
  paymentMethod,
  cammacsStatuses,
  totalPaymentAmount,
  activityTypes,
  paymentMethod,
  payerFields,
  generateShippingRequestRows,
};
