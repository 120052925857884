import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { omit, isEmpty, sumBy, get, keyBy } from 'lodash';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';

import {
  isSplitedOrder,
  oneOrderItems,
  ordererFields,
  destinationFields,
  contactorFields,
  deliveryFields,
  wishFields,
  shipmentFeeByAmount,
} from '../../shared/models/order';
import { computeDiscountAmount, isShipmentFree } from '../../shared/models/coupon';
import { fieldDisplayValue } from '../../shared/util';
import OrderFlowSection from '../OrderFlowSection';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import AppCardElement from '../AppCardElement';
import env from '../../env';

const { entries } = Object;

export default function NewOrderConfirmFormApp(props) {
  return (
    <StripeProvider apiKey={env('STRIPE_API_KEY')}>
      <FormContainer {...props} />
    </StripeProvider>
  );
}

function FormContainer(props) {
  return (
    <Elements>
      <InjectedNewOrderForm {...props} />
    </Elements>
  );
}

function NewOrderConfirmForm(props) {
  const {
    products = [],
    agentProductPublicSettings,
    values,
    stripe,
    onClickBack,
    cartSettings,
    user,
    isWholesale,
    isWish,
    isPartsOrder,
    isAgentUser,
  } = props;
  const { coupon, otherCoupons, } = values;
  const allCoupons = [coupon, ...otherCoupons].filter(_ => _);
  const hasCoupon = allCoupons.length > 0;
  const productsById = keyBy(products, 'id');
  const agentProductPublicSettingsById = keyBy(agentProductPublicSettings, 'id');
  const { splitType, orderItems: _orderItems, children = [] } = values;
  const giftOrderItems = allCoupons
    .flatMap((coupon) => {
      return (coupon.giftProductIds || []).map((giftProductId) => {
        const product = productsById[giftProductId];
        const { id, normalOrderableQuantity } = product;
        const quantity = sumBy(
          _orderItems.filter((_) => coupon.productIds.includes(_.productId)),
          (_) => _.normalOrderCount || _.preOrderCount
        );
        return {
          productId: id,
          quantity,
          isEnough: normalOrderableQuantity >= quantity,
          [_orderItems.some((_) => _.preOrderCount > 0) ? 'preOrderCount' : 'normalOrderCount']: quantity,
          planDate: null,
          isGift: true,
        };
      });
    });
  const enoughGiftOrderItems = giftOrderItems.filter((_) => _.isEnough);
  const isEnoughGifts = giftOrderItems.every((_) => _.isEnough);
  const orderItems =
    splitType === 'one' && isSplitedOrder(_orderItems)
      ? oneOrderItems([..._orderItems, ...enoughGiftOrderItems])
      : [..._orderItems, ...enoughGiftOrderItems];
  const normalOrderItems = orderItems
    .map((_) => ({
      ...omit(_, ['normalOrderCount', 'preOrderCount']),
      product: {
        ...productsById[_.productId],
        agentPrice: agentProductPublicSettingsById[_.productId]?.price,
        agentWholesalePriceRate: agentProductPublicSettingsById[_.productId]?.wholesalePriceRate,
      },
      quantity: _.normalOrderCount,
    }))
    .filter((_) => _.quantity > 0);
  const preOrderItems = orderItems
    .map((_) => ({
      ...omit(_, ['normalOrderCount', 'preOrderCount']),
      product: { ...productsById[_.productId], agentPrice: agentProductPublicSettingsById[_.productId]?.price },
      quantity: _.preOrderCount,
    }))
    .filter((_) => _.quantity > 0);
  const hasPreOrder = preOrderItems.length > 0;
  const normalOrderAmount = sumBy(normalOrderItems, (_) => (_.product.agentPrice ?? _.product.price) * _.quantity);
  const normalOrderWholesaleAmount = sumBy(
    normalOrderItems,
    (_) =>
      (((_.product.agentPrice ?? _.product.price) *
        (_.product.agentWholesalePriceRate ?? _.product.wholesalePriceRate)) /
        100) *
      _.quantity
  );
  const preOrderAmount = sumBy(preOrderItems, (_) => (_.product.agentPrice ?? _.product.price) * _.quantity);
  const normalOrderDiscountAmount = hasCoupon ? sumBy(allCoupons, _ => computeDiscountAmount(normalOrderItems, _)) : 0;
  const preOrderDiscountAmount = hasCoupon
    ? normalOrderDiscountAmount > 0
      ? 0
      : sumBy(allCoupons, _ => computeDiscountAmount(preOrderItems, _))
    : 0;
  const normalOrderShipmentFee =
    hasCoupon && allCoupons.some(_ => isShipmentFree(normalOrderItems, _))
      ? 0
      : shipmentFeeByAmount(normalOrderAmount - normalOrderDiscountAmount);
  const preOrderShipmentFee =
    hasCoupon && allCoupons.some(_ => isShipmentFree(preOrderItems, _)) ? 0 : shipmentFeeByAmount(preOrderAmount - preOrderDiscountAmount);
  const normalOrderDiscountedAmount = normalOrderAmount - normalOrderDiscountAmount;
  const normalOrderDiscountedWholesaleAmount = normalOrderWholesaleAmount - normalOrderDiscountAmount;
  const normalOrderTotalAmount = normalOrderDiscountedAmount + normalOrderShipmentFee;
  const normalOrderWholesaleTotalAmount = normalOrderDiscountedWholesaleAmount + normalOrderShipmentFee;
  const preOrderDiscountedAmount = preOrderAmount - preOrderDiscountAmount;
  const preOrderTotalAmount = preOrderDiscountedAmount + preOrderShipmentFee;
  const totalAmount = normalOrderTotalAmount + preOrderTotalAmount;
  const shouldCharge = !isWish && !isWholesale && (normalOrderDiscountedAmount > 0 || preOrderDiscountedAmount > 0);
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    toggleSubmitting(true);
    try {
      const tokens = shouldCharge
        ? await Promise.all(
            [normalOrderTotalAmount, preOrderTotalAmount].map(async (_, i) => {
              await new Promise(_ => setTimeout(_, 2000 * i));
              return (await stripe.createToken({ type: 'card' })).token;
            })
          )
        : [];
      if (shouldCharge && tokens.some((_) => _ == null)) return toggleSubmitting(false);

      await props.onSubmit({
        ...values,
        orderItems,
        stripeTokens: tokens,
        normalOrderItems: normalOrderItems.map((_) => omit(_, ['product'])),
        normalOrderAmount,
        normalOrderDiscountAmount,
        normalOrderShipmentFee,
        normalOrderTotalAmount,
        preOrderItems: preOrderItems.map((_) => omit(_, ['product'])),
        preOrderAmount,
        preOrderDiscountAmount,
        preOrderShipmentFee,
        preOrderTotalAmount,
      });
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <OrderFlowSection activeIndex={2} />
      <section className="container mt-5">
        <div>
          <div>
            <div className="card border-danger text-danger p-3 text-center font-weight-bold">
              {isWish ? 'おねだり注文' : 'ご注文'}はまだ完了していません
            </div>
            <h4 className="mt-4 h5 text-center font-weight-bold">注文内容をご確認ください</h4>
            <div className="mt-4">
              {normalOrderItems.length > 0 && (
                <div className="mb-3">
                  {hasPreOrder && <h5>通常注文</h5>}
                  <table className="table table-bordered">
                    <thead className="thead-light text-center text-nowrap">
                      <tr>
                        <th>商品名</th>
                        <th>注文数</th>
                        <th>金額(税込)</th>
                        {isWholesale && isAgentUser && <th>卸価格</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {normalOrderItems.map((orderItem) => {
                        const { productId, quantity, isGift = false, product } = orderItem;
                        return (
                          product != null && (
                            <tr>
                              <td>
                                {product.name}
                                {isGift && <span className="ml-1 badge badge-info">プレゼント</span>}
                              </td>
                              <td className="text-right">{numeral(quantity).format('0,0')}</td>
                              <td className="text-right">
                                {numeral((product.agentPrice ?? product.price) * quantity).format('0,0')}
                              </td>
                              {isWholesale && isAgentUser && (
                                <td className="text-right">
                                  {numeral(
                                    (((product.agentPrice ?? product.price) *
                                      (product.agentWholesalePriceRate ?? product.wholesalePriceRate)) /
                                      100) *
                                      quantity
                                  ).format('0,0')}
                                </td>
                              )}
                            </tr>
                          )
                        );
                      })}
                      <tr>
                        <td className="text-right">商品合計</td>
                        <td className="text-right">{numeral(sumBy(normalOrderItems, 'quantity')).format('0,0')}</td>
                        <td className="text-right">{numeral(normalOrderAmount).format('0,0')}</td>
                        {isWholesale && isAgentUser && (
                          <td className="text-right">{numeral(normalOrderWholesaleAmount).format('0,0')}</td>
                        )}
                      </tr>
                      {hasCoupon && (
                        <Fragment>
                          <tr>
                            <td colSpan={2} className="text-right">
                              割引金額
                            </td>
                            <td className="text-right">{numeral(normalOrderDiscountAmount).format('0,0')}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-right">
                              割引後
                            </td>
                            <td className="text-right">
                              {numeral(normalOrderAmount - normalOrderDiscountAmount).format('0,0')}
                            </td>
                          </tr>
                        </Fragment>
                      )}
                      <tr>
                        <td colSpan={2} className="text-right">
                          送料
                        </td>
                        <td className="text-right">{numeral(normalOrderShipmentFee).format('0,0')}</td>
                        {isWholesale && isAgentUser && (
                          <td className="text-right">{numeral(normalOrderShipmentFee).format('0,0')}</td>
                        )}
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-right">
                          合計
                        </td>
                        <td className="text-right">{numeral(normalOrderTotalAmount).format('0,0')}</td>
                        {isWholesale && isAgentUser && (
                          <td className="text-right">{numeral(normalOrderWholesaleTotalAmount).format('0,0')}</td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {preOrderItems.length > 0 && (
                <div className="mb-3">
                  <h5>予約注文</h5>
                  <table className="table table-bordered">
                    <thead className="thead-light text-center text-nowrap">
                      <tr>
                        <th>商品名</th>
                        <th>注文数</th>
                        <th>金額(税込)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {preOrderItems.map((orderItem) => {
                        const { productId, quantity, isGift = false, product } = orderItem;
                        return (
                          product != null && (
                            <tr>
                              <td>
                                {product.name}
                                {isGift && <span className="ml-1 badge badge-info">プレゼント</span>}
                              </td>
                              <td className="text-right">{numeral(quantity).format('0,0')}</td>
                              <td className="text-right">
                                {numeral((product.agentPrice ?? product.price) * quantity).format('0,0')}
                              </td>
                            </tr>
                          )
                        );
                      })}
                      <tr>
                        <td className="text-right">商品合計</td>
                        <td className="text-right">{numeral(sumBy(preOrderItems, 'quantity')).format('0,0')}</td>
                        <td className="text-right">{numeral(preOrderAmount).format('0,0')}</td>
                      </tr>
                      {hasCoupon && (
                        <Fragment>
                          <tr>
                            <td colSpan={2} className="text-right">
                              割引金額
                            </td>
                            <td className="text-right">{numeral(preOrderDiscountAmount).format('0,0')}</td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="text-right">
                              割引後
                            </td>
                            <td className="text-right">
                              {numeral(preOrderAmount - preOrderDiscountAmount).format('0,0')}
                            </td>
                          </tr>
                        </Fragment>
                      )}
                      <tr>
                        <td colSpan={2} className="text-right">
                          送料
                        </td>
                        <td className="text-right">{numeral(preOrderShipmentFee).format('0,0')}</td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-right">
                          合計
                        </td>
                        <td className="text-right">{numeral(preOrderTotalAmount).format('0,0')}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {
              (() => {
                return (
                  !isEnoughGifts && (
                    <div className="mt-4 alert alert-danger">
                      <div>
                        {giftOrderItems
                          .filter((_) => !_.isEnough)
                          .map((_) => (
                            <div key={_.productId}>「{get(productsById, [_.productId, 'name'])}」</div>
                          ))}
                      </div>
                      <div>
                        <SettingText name="giftOutOfStockText" initialText="が在庫切れのためプレゼントできません" />
                      </div>
                    </div>
                  )
                );
              })()}
          </div>
          <div className="mt-5">
            <h4>注文者情報</h4>
            <table className="table table-bordered">
              <tbody>
                {entries(ordererFields()).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  const value = fieldDisplayValue(values[fieldName], fieldSettings);
                  return (
                    <tr>
                      <th>{label}</th>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isEmpty(get(user, 'children', [])) && (
            <div className="mt-5">
              <h4>お子様情報</h4>
              {children.length > 0
                ? children.map((child, i) => {
                    const { name, birthday, vehicleExperiences = [] } = child;
                    return (
                      <div className="mb-3 bg-grey rounded p-3 flex-fill" key={i}>
                        <div className="large font-weight-bold mb-3">{name}</div>
                        <div>{formatDate(birthday, 'yyyy年MM月dd日生まれ')}</div>
                        <div>
                          {vehicleExperiences.map((_) => (
                            <div className="badge bg-white mr-1">{_}</div>
                          ))}
                        </div>
                      </div>
                    );
                  })
                : 'なし'}
            </div>
          )}
          <div className="mt-5">
            <h4>お届け先情報</h4>
            <table className="table table-bordered">
              <tbody>
                {entries(destinationFields()).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  const value = fieldDisplayValue(values[fieldName], fieldSettings);
                  return (
                    <tr>
                      <th>{label}</th>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {!isPartsOrder && (
            <div className="mt-5">
              <h4>利用者情報</h4>
              <table className="table table-bordered">
                <tbody>
                  {entries(contactorFields()).map(([fieldName, fieldSettings]) => {
                    const { label } = fieldSettings;
                    const value = fieldDisplayValue(values[fieldName], fieldSettings);
                    return (
                      <tr>
                        <th>{label}</th>
                        <td>{value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div className="mt-5">
            <h4>お届け日時</h4>
            <table className="table table-bordered">
              <tbody>
                {entries(deliveryFields({ cartSettings, hasPreOrder })).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  const value = fieldDisplayValue(values[fieldName], fieldSettings);
                  return (
                    <tr>
                      <th>{label}</th>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {
            isWish && (
              <div className="mt-5">
                <h4>おねだりメッセージ</h4>
                <table className="table table-bordered">
                  <tbody>
                    {entries(wishFields({ isWish, })).map(([fieldName, fieldSettings]) => {
                      const { label } = fieldSettings;
                      const value = fieldDisplayValue(values[fieldName], fieldSettings);
                      return (
                        <tr>
                          <th>{label}</th>
                          <td>{value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          }
          {shouldCharge && (
            <div className="mt-4">
              <div className="mt-3 text-center">お支払い情報を入力してください</div>
              <FormGroup className="mt-3">
                <Label>
                  クレジットカード
                  <span className="text-danger small">【必須】</span>
                </Label>
                <AppCardElement />
                <small className="form-text text-muted">
                  CVCはセキュリティコードです。署名欄の３桁など、カード会社所定のコードを入力して下さい。
                </small>
              </FormGroup>
            </div>
          )}
        </div>
        <div className="d-flex mt-5">
          <AppButton
            size="lg"
            className="cancel flex-fill"
            color="secondary"
            onClick={onClickBack}
            disabled={isSubmitting}
          >
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton
            size="lg"
            color="primary"
            className="save flex-fill ml-2"
            type="submit"
            color="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            <span
              className={classnames('fas mr-1', {
                'fa-arrow-right': !isSubmitting,
                'fa-spin fa-spinner': isSubmitting,
              })}
            />
            {isWish ? 'おねだり注文' : '注文'}を確定する
          </AppButton>
        </div>
      </section>
    </Form>
  );
}

const InjectedNewOrderForm = injectStripe(NewOrderConfirmForm);
