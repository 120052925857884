import React from 'react';
import { omitBy, isUndefined, pick, omit, isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle, } from 'react-use';

import useFirebaseUser from './hooks/useFirebaseUser';

export default function AddButton ({ label = '追加', successMessage = '追加しました', initialValues = {}, validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, icon = <span className="fas fa-plus mr-1" />, onFinish = _ => _, ...extraProps }) {
  const { firebaseUser } = useFirebaseUser();
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    const ref = isFunction(itemRef) ? (await itemRef(values)) : itemRef;
    const { id } = ref;
    if(!(await validateOnSubmit({ id, ...values }))) return;

    try {
      const processedValues = await processValues({ id, ref, ...values });
      await ref.set({ ...omit(processedValues, ['id', 'ref']), addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined), createdAt: new Date(), });
      await onFinish(processedValues);
      toast.success(successMessage);
      toggleModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color="primary" onClick={_ => toggleModal(true)} {...extraProps}>
      {icon}
      {label}
      {showsModal && <FormModal isOpen values={initialValues} onClickClose={_ => toggleModal(false)} onSubmit={onSubmit} {...formProps} />}
    </Button>
  );
};
