import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { pickBy, isEmpty, keyBy, sortBy, get, pick, omit, mapValues, } from 'lodash';
import { useMap, } from 'react-use';
import { getYear, getMonth, } from 'date-fns';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import { fields, } from '../../shared/models/methodInquiry';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { generateMethodInquiryId, } from '../../shared/util';
import { computeVisibleQuestionIds, computeVisibleAnswers, } from '../../shared/models/survey';
import useFormState from '../hooks/useFormState';
import MethodInquiryBasicForm from '../forms/MethodInquiryBasicForm';
import MethodInquiryConfirmForm from '../forms/MethodInquiryConfirmForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import MethodInquiryFlowSection from '../MethodInquiryFlowSection';
import { getCollectionData } from '../../shared/firebase';

const storageRef = firebase.storage().ref();
const { entries, keys, } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const productTypesRef = db.collection('productTypes');
const productsRef = db.collection('products');
const methodInquiriesRef = db.collection('methodInquiries');
const surveysRef = db.collection('surveys');
const questionsRef = db.collection('questions');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');
const getOrderItems = functions.httpsCallable('getOrderItems');
const createPendingSubUser = functions.httpsCallable('createPendingSubUser');

export default TenantUserPage(function MethodInquiry(props) {
  const { tenant, user, myAccount, history, toggleLoginForm } = props;
  const { sourceMethodInquiryId, sourceSurveyAnswerId, orderId, productId, productTypeId, createdAt } = useQueryParams();
  const sourceMethodInquiry = useDocumentSubscription(sourceMethodInquiryId && db.collection('methodInquiries').doc(sourceMethodInquiryId), [sourceMethodInquiryId]);
  const order = useDocumentSubscription(user != null && orderId && ordersRef.doc(orderId), [user, orderId]);
  const orders = useCollectionSubscriptionInTenant(user != null && !orderId && ordersRef.where("createdBy.uid", "==", user.uid).orderBy('createdAt', 'desc'), [user, orderId]);
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const statedFields = useFormState({ productId, productTypeId, }, fields({ products, productTypes }));
  const currentProduct = productsById[statedFields.productId.value];
  const survey = useDocumentSubscription((_ => _ != null && surveysRef.doc(_))(currentProduct?.[sourceMethodInquiryId ? 'methodReinquirySurveyId' : 'methodInquirySurveyId']), [currentProduct?.methodInquirySurveyId]);
  const questions = useDocumentsFetch((survey?.questionRows?.map(_ => _.questionId) || []).map(_ => questionsRef.doc(_)), [survey]);
  const questionsById = keyBy(questions, 'id');
  const initialAnswers = questions.reduce(
    (x, { id, type, isOptional = false }) => ({
      ...x,
      [id]: {
        isValid: isOptional,
        value: { text: '', radio: null, checkbox: {}, imageFile: null, }[type],
      },
    }),
    {}
  );
  const [answers, { setAll: setAnswers, }] = useMap(initialAnswers);
  const visibleQuestionIds = computeVisibleQuestionIds(survey, mapValues(answers, 'value'));
  const visibleQuestions = visibleQuestionIds.map(_ => questionsById[_] || {});
  const [currentScreen, setScreen] = useState('basic');

  const onSubmit = async () => {
    const id = await generateMethodInquiryId();
    const ref = methodInquiriesRef.doc(id);
    const processedAnswers = await entries(answers).reduce(async (x, [questionId, { value }]) => {
      const prevs = await x;

      const question = questionsById[questionId];
      return {
        ...prevs,
        [questionId]:
          question?.type === 'imageFile' && !isEmpty(value)
            ? await Promise.all(
                value.map(async (file) => {
                  const fileRef = storageRef.child(`methodInquiries/${ref.id}/${questionId}/${new Date().toISOString()}/${file.name}`);
                  await fileRef.put(file, { contentType: file.type });
                  return {
                    ...pick(file, ['name', 'type']),
                    url: await fileRef.getDownloadURL(),
                  };
                })
              )
            : value,
      };
    }, Promise.resolve({}));

    const values = {
      ...mapValues(statedFields, 'value'),
      answers: processedAnswers,
    };
    // orderIdが無い時、productIdから探す
    const [_order] = !orderId && values.productId
      ? await getCollectionData(ordersRef.where('tenantId', '==', tenant.id).where('createdBy.uid', '==', user.uid).where('productIds', 'array-contains', values.productId))
      : [];
    try {
      const { data } = await getOrderItems({ orderId: orderId || _order?.id });
      const order = data ? { ...data, id: data.orderId, createdAt: new Date(data.createdAt) } : null;

      // NOTE: 問い合わせ登録
      await ref.set({
        tenantId: tenant.id,
        ...omit(values, 'files'),
        sourceOrder: order != null ? pick(order, ['id', 'orderItems', 'createdAt']) : null,
        sourceMethodInquiryId: sourceMethodInquiryId || null,
        sourceSurveyAnswerId: sourceSurveyAnswerId || null,
        createdAt: new Date(),
        createdBy: user,
        myAccount: myAccount || null,
        status: 'initial',
        isDetail: true,
      });

      // NOTE: 遷移、トースト
      history.push('/mypage/methodInquiries');
      toast.success('お問合せ完了しました。');
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        name: user.displayName,
        ...pick(user, ['nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address', 'route']),
      });
    }
  }, [user != null]);
  useEffect(() => {
    if (order != null) {
      statedFields.setValues({
        purchaseYear: getYear(order.createdAt.toDate()),
        purchaseMonth: getMonth(order.createdAt.toDate()) + 1,
      });
    }
    if (createdAt) {
      statedFields.setValues({
        purchaseYear: getYear(new Date(createdAt)),
        purchaseMonth: getMonth(new Date(createdAt)) + 1,
      });
    }
  }, [order != null, products.length, productTypes.length, createdAt]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);
  useEffect(() => {
    setAnswers(initialAnswers);
  }, [questions]);
  useEffect(() => {
    if (sourceMethodInquiry != null) {
      statedFields.setValues({
        ...pick(sourceMethodInquiry, keys(statedFields)),
      });
    }
  }, [sourceMethodInquiry != null]);
  useEffect(() => {
    const [lastOrder] = orders.filter(({ productIds }) => productIds && productIds.some(productId => productsById[productId]?.isBody));
    // orderIdが無い時、直近のorderから設定する
    if (!orderId && lastOrder) {
      const [productId] = lastOrder.productIds.filter(productId => productsById[productId]?.isBody);
      const product = productsById[productId];
      statedFields.setValues({
        productId,
        productTypeId: product.productTypeIds?.[0]
      });
    }
  }, [orderId, orders?.length, products?.length]);
  const screens = ['basic', 'confirm'];

  return (
    <div className="method-inquiry position-relative">
      <MethodInquiryFlowSection activeIndex={screens.indexOf(currentScreen)} />
      <section className="container mt-5 py-3">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            {
              ({
                basic: () => <MethodInquiryBasicForm onSubmit={_ => setScreen('confirm')} {...{ statedFields, products, questions: visibleQuestions, answers, onChange: (id, _) => setAnswers(computeVisibleAnswers(survey, { ...answers, [id]: _, })), }} />,
                confirm: () => <MethodInquiryConfirmForm onSubmit={onSubmit} onCancel={_ => setScreen('basic')} {...{ statedFields, products, questions: visibleQuestions, answers, }} />,
              })[currentScreen]()
            }
          </div>
        </div>
      </section>
    </div>
  );
});
