import React from 'react';
import { Button, Form, } from 'reactstrap';
import { keyBy, } from 'lodash';
import numeral from 'numeral';
import classnames from 'classnames';
import { useToggle } from 'react-use';

import { basicFields, ordererFields, } from '../../shared/models/methodInquiry';
import { fieldDisplayValue, } from '../../shared/util';
import AppButton from '../AppButton';

const { keys, entries, } = Object;

export default function MethodInquiryConfirmForm (props) {
  const { products, statedFields, onCancel, questions, answers, } = props;
  const productsById = keyBy(products, 'id');
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isSubmitting) return;

    toggleSubmitting(true);
    await props.onSubmit();
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-2">
        <div>
          <div className="card border-danger text-danger p-3 text-center font-weight-bold">
            お問合せはまだ完了していません
          </div>
          <h4 className="mt-4 h5 text-center font-weight-bold">
            内容をご確認ください
          </h4>
          <div className="mt-5">
            <h4>基本情報</h4>
            <table className="table table-bordered">
              <tbody>
                {
                  keys(basicFields()).map((fieldName) => {
                    const fieldSetting = statedFields[fieldName];
                    const { label, } = fieldSetting;
                    const value = fieldDisplayValue(fieldSetting.value, fieldSetting);
                    return (
                      <tr>
                        <th>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>{value}</td>
                      </tr>
                    );
                  })
                }
                {questions.map((question) => {
                  const { id, description, type, } = question;
                  const { value } = answers[id];
                  return (
                    <tr key={id}>
                      <th>{description}</th>
                      <td>
                        {
                          ({
                            text: value,
                            checkbox: keys(value || {}).join(', '),
                            radio: value,
                            imageFile: value?.map?.((_) => _.name).join(', '),
                          })[type] || null
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <h4>お客様情報</h4>
            <table className="table table-bordered">
              <tbody>
                {
                  keys(ordererFields()).map((fieldName) => {
                    const fieldSetting = statedFields[fieldName];
                    const { label, } = fieldSetting;
                    const value = fieldDisplayValue(statedFields[fieldName].value, fieldSetting);
                    return (
                      <tr>
                        <th>{label}</th>
                        <td>{value}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex mt-5">
          <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onCancel} disabled={isSubmitting}>
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton size="lg" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit} disabled={isSubmitting}>
            <span className={classnames('fas mr-1', { 'fa-arrow-right': !isSubmitting, 'fa-spin fa-spinner': isSubmitting, })} />
            確定する
          </AppButton>
        </div>
      </section>
    </Form>
  );
};
