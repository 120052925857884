import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';

if(['production', 'staging', 'multistaging'].includes(process.env.REACT_APP_ENV)) {
  Bugsnag.start({
    apiKey: 'd434d020f7cb532c6c4844a73cc3d2a5',
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.REACT_APP_ENV || 'development',
  });
}
