import React from 'react';
import { Badge } from 'reactstrap';
import { collection, startAt, endAt, query, where, orderBy as dbOrderBy, limit, getCountFromServer, } from 'firebase/firestore';
import { omitBy, isUndefined, pick, uniq, uniqBy, difference, keyBy, isEmpty, get } from 'lodash';
import { useAsync, } from 'react-use';
import { useCollection } from 'react-firebase-hooks/firestore';
import numeral from 'numeral';

import firebase from '../../firebase';
import { roles, prefectures } from '../../shared/config';
import { batch, docToData, getCollectionData } from '../../shared/firebase';
import AdminPage from '../hocs/AdminPage';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import TenantLink from '../TenantLink';

const { entries } = Object;
const db = firebase.firestore();
const roleOptions = entries(roles).map(([k, v]) => ({ label: v, value: k, }));

export default AdminPage(function AdminUsers(props) {
  const {
    user,
    match: {
      params: { tenantPath },
    },
  } = props;
  const tenantUsersRef = db.collection('tenants').doc(tenantPath).collection('tenantUsers');
  const queryParams = useQueryParams();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0', userTags: userTagsFilter, } = queryParams;
  const showsAllUsers = _showsAllUsers === '1';
  const isSearching = !!text;
  let tenantUsersQuery = collection(db, `tenants/${tenantPath}/tenantUsers`);
  if(text) {
    console.log(1, startAt, field, text);
    tenantUsersQuery = query(tenantUsersQuery, dbOrderBy(field), startAt(text), endAt(`${text}\uf8ff`));
  } else {
    let isQueried = false;
    if(!isEmpty(userTagsFilter)) {
      tenantUsersQuery = query(tenantUsersQuery, where('userTagIds', 'array-contains-any', userTagsFilter.slice(0, 10)));
      isQueried = true;
    }
    if(!isEmpty(queryParams.role)) {
      tenantUsersQuery = query(tenantUsersQuery, where('role', '==', queryParams.role[0]));
      isQueried = true;
    }
    if(!isQueried) {
      tenantUsersQuery = query(tenantUsersQuery, dbOrderBy('createdAt', 'desc'));
    }
  }
  const { value: count, error, } = useAsync(async () => {
    return (await getCountFromServer(tenantUsersQuery)).data()?.count;
  }, [queryParams]);
  const [tenantUsersValue] = useCollection((_ => showsAllUsers ? _ : query(_, limit(100)))(tenantUsersQuery));
  const tenantUsers = tenantUsersValue?.docs.map(docToData) || [];
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const userTagOptions = userTags.map((_) => ({ label: _.name, value: _.id }));
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];

  const processTagCsvRows = async (rows) => {
    const uniqRows = uniqBy(
      rows.filter((_) => _.userId),
      'userId'
    );
    const tagNames = userTags.map((_) => _.name);
    const rowTagNames = uniq(uniqRows.flatMap((_) => _.userTags.split(/\s+/g))).filter((_) => _);
    await batch(db, difference(rowTagNames, tagNames), (batch, _) =>
      batch.set(db.collection('userTags').doc(), { name: _, createdAt: new Date() })
    );
    const tagsByName = keyBy(await getCollectionData(db.collection('userTags')), 'name');
    return uniqRows.map((row) => {
      return {
        ...row,
        userTagIds: row.userTags
          .split(/\s+/g)
          .map((_) => tagsByName[_]?.id)
          .filter((_) => _),
      };
    });
  };
  const processTagCsvRow = async (batch, row) => {
    const { userId, userTagIds } = row;
    if (isEmpty(userId)) return;

    batch.set(
      tenantUsersRef.doc(userId),
      {
        userTagIds,
        updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      },
      { merge: true }
    );
  };

  return (
    <div>
      <div className="admin-users container-fluid py-5 position-relative d-flex flex-column gap-3">
        <div className="d-flex justify-content-center">
          <h4>アカウント管理</h4>
        </div>
        <div className="d-flex align-items-start gap-2">
          <div className="card">
            <div className="card-header">
              検索
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-2 align-items-end">
                <QuerySelector
                  paramName="field"
                  options={fieldOptions}
                  label="検索フィールド"
                  defaultValue={[field]}
                  isClearable={false}
                />
                <QueryText paramName="text" label="検索テキスト" />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              絞り込み
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-2 align-items-end">
                <QuerySelector
                  paramName="userTags"
                  width={250}
                  max={10}
                  isMulti
                  options={userTagOptions}
                  label="ユーザータグで絞込み"
                  disabled={isSearching}
                />
                <QuerySelector
                  paramName="role"
                  width={250}
                  options={roleOptions}
                  label="権限で絞込み"
                  disabled={isSearching}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-start gap-2">
          <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
        </div>
        <div className="overflow-auto">
          <div className="d-flex justify-content-between align-items-end mb-1">
            <div>
              {numeral(tenantUsers.length).format()} / {numeral(count).format()} 件
            </div>
            <div className="d-flex gap-1 align-items-end">
              <div>
                <div className="small text-muted">userIdとuserTags(半角スペース)で指定</div>
                <ImportButton label="タグ付けCSVインポート" processRows={processTagCsvRows} processRow={processTagCsvRow} />
              </div>
              <ExportButton
                fileName="users.csv"
                rows={tenantUsers.map((u) => ({
                  ...['id', 'email', 'displayName', 'role', 'ngDm', 'note', 'subUserIds', 'mainUserId'].reduce(
                    (x, k) => ({ ...x, [k]: u[k] }),
                    {}
                  ),
                  status: u.disabledAt ? '無効' : '',
                }))}
                hasPersonalInfo
                user={user}
              />
            </div>
          </div>
          <table className="table">
            <thead className="thead-light text-center text-nowrap">
              <tr>
                <th style={{ minWidth: 150 }}>アカウント名</th>
                <th style={{ minWidth: 150 }}>メールアドレス</th>
                <th style={{ minWidth: 100 }}>都道府県</th>
                <th style={{ minWidth: 150 }}>市区町村</th>
                <th style={{ minWidth: 150 }}>電話番号</th>
                <th style={{ minWidth: 100 }}>権限</th>
                <th style={{ minWidth: 100 }}>DM発送不可</th>
                <th style={{ minWidth: 150 }}>ユーザータグ</th>
                <th style={{ minWidth: 200 }}>メモ</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(tenantUsers || []).map((user) => {
                const {
                  id,
                  email,
                  displayName,
                  prefecture,
                  phone,
                  role = 'user',
                  ngDm = false,
                  note,
                  city,
                  userTagIds = [],
                  mainUserId,
                  subUserIds,
                  disabledAt,
                } = user;
                return (
                  <tr key={id} style={{ background: disabledAt ? 'lightgray' : '' }}>
                    <td>
                      <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                      <div>
                        {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
                        {!isEmpty(subUserIds) && (
                          <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>
                        )}
                      </div>
                      {disabledAt && <Badge color="danger">無効</Badge>}
                    </td>
                    <td>{email}</td>
                    <td>{prefecture && prefectures[prefecture]}</td>
                    <td>{city}</td>
                    <td>{phone}</td>
                    <td>{roles[role]}</td>
                    <td>{ngDm && '発送不可'}</td>
                    <td>
                      <div className="d-flex flex-wrap gap-1">
                        {(userTagIds || []).map((_) => (
                          <div key={_} className="badge badge-secondary">
                            {userTagsById[_]?.name}
                          </div>
                        ))}
                      </div>
                    </td>
                    <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
