import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { omit, mapValues, } from 'lodash';
import { useAsync, useList, } from 'react-use';

import firebase from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import { getProductsWithInventory } from '../../shared/models/product';
import useFormState from '../hooks/useFormState';
import useTenant from '../hooks/useTenant';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries, } = Object;
const db = firebase.firestore();
const tmpOrdersRef = db.collectionGroup('tmpOrders');
const receivingPlansRef = db.collection('receivingPlans');

export default function InquiryItemsFormModal(props) {
  const { isOpen, values, itemFields, products, onClickClose, } = props;
  const tenant = useTenant();
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.items || []);
  const isUnsubmittable = items.some(_ => !_.isValid);
  const { value: productItems } = useAsync(async () => {
    const initialTmpOrders = await getCollectionData(tmpOrdersRef.where('tenantId', '==', tenant.id).where('status', '==', 'initial'));
    let productsWithInventory = getProductsWithInventory(products, [], [], initialTmpOrders);
    return productsWithInventory.map(_ => ({ ..._, label: `[${_.code}] ${_.name} (在庫${_.normalOrderableQuantity})`, }));
  }, [products]);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            商品選択
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div>
            <ListForm
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    {productItems != null && <ItemForm
                        fields={itemFields({ products: productItems, })}
                        index={itemIndex}
                        values={item}
                        onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                      />
                    }
                  </div>
                );
              }}
              onChange={setItems}
              initialItem={{ quantity: 1 }}
              minItems={1}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { fields, values, onChange, } = props;
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
    statedFields.startAll();
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div className="mr-2" style={{ minWidth: fieldName === 'productId' && 500 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
