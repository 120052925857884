import qs from 'qs';
import { isEmpty, keyBy, mapValues, isObject, pick } from 'lodash';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import firebase from './firebase';
import { prefectures } from './shared/config';

const { entries, keys, } = Object;
const prefecturesByPref = mapValues(keyBy(entries(prefectures), '1'), '0');
const db = firebase.firestore();
const placesRef = db.collection('places');

export function fullPathWithParams(params, { pathname, search }, currentParamNames) {
  const currentParams = qs.parse(decodeURI(search.slice(1)));
  const newParams = {
    ...(currentParamNames ? pick(currentParams, currentParamNames) : currentParams),
    ...params,
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
}

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', (_) => resolve(_.target.result));
  });
}

export async function checkPlaceUniqueness({ id, name, prefecture }) {
  const { docs } = await placesRef.where('name', '==', name).where('prefecture', '==', prefecture).get();
  return docs.filter((_) => _.id !== id).length === 0;
}

export function answerText(value, type) {
  return {
    text: value,
    radio: isObject(value)
      ? Object.entries(value || {})
          .filter((_) => _[1])
          .map((_) => _[0])
          .join(', ')
      : value,
    checkbox: Object.entries(value || {})
      .filter((_) => _[1])
      .map((_) => _[0])
      .join(', '),
  }[type];
}

export function withConfirm(message, f) {
  return (...args) => {
    if (!window.confirm(message)) return;

    f(...args);
  };
}

export async function getAddressByPostalCode(postalCode) {
  const { results } = await (
    await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`)
  ).json();
  if (isEmpty(results)) return;

  const [
    { address1, address2, address3 },
  ] = results;
  return {
    prefecture: prefecturesByPref[address1],
    city: address2,
    address1: address3,
  };
}

export function fillAddress(prevStatedFields, statedFields) {
  if(!prevStatedFields) return;
  if(prevStatedFields.postalCode.value === statedFields.postalCode.value) return;
  if(!statedFields.postalCode.isValid) return;

  (async () => {
    const data = await getAddressByPostalCode(statedFields.postalCode.value);
    data && statedFields.setValues({
      prefecture: data.prefecture,
      city: data.city,
    });
  })();
}

export function beforeDelete(refs) {
  return async (item) => {
    if((await Promise.all(refs.map(_ => _.limit(1).get()))).some(_ => _.docs.length > 0)) {
      toast.error('使用されているため削除できません');
      return false;
    }
  };
}

export const downloadCanvasImage = (canvas, filename) => {
  const link = document.createElement('a');
  link.href = canvas.toDataURL("image/jpg");
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link)
  }, 100);
}

export const formatDuration = (_seconds) => {
  if (_seconds === 0) return;
  const hours = numeral(Math.trunc(_seconds / 3600)).format('00');
  const minutes = numeral(Math.trunc((_seconds % 3600) / 60)).format('00')
  const seconds = numeral(Math.trunc(_seconds % 60)).format('00');
  return `${hours}:${minutes}:${seconds}`;
};

export const activateRichTextHtml = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const imageNodes = doc.querySelectorAll('img[alt]');
  Array.from(imageNodes).map((imageNode) => {
    if(imageNode.getAttribute('alt')?.startsWith('[video] ')) {
      const videoNode = document.createElement('video');
      videoNode.setAttribute('src', imageNode.getAttribute('alt').replace('[video] ', ''));
      const posterUrl = imageNode.getAttribute('src');
      if(!posterUrl.includes('movie_play')) videoNode.setAttribute('poster', posterUrl);
      videoNode.setAttribute('controls', '');
      imageNode.parentNode.appendChild(videoNode);
      imageNode.parentNode.removeChild(imageNode);
    } else {
      const aNode = document.createElement('a');
      aNode.setAttribute('href', imageNode.getAttribute('alt'));
      aNode.setAttribute('target', '_blank');
      imageNode.parentNode.appendChild(aNode);
      aNode.appendChild(imageNode);
      imageNode.removeAttribute('alt');
    }
  });
  const anchorNodes = doc.querySelectorAll('a[href]');
  Array.from(anchorNodes).map((anchorNode) => {
    anchorNode.setAttribute('target', '_blank');
  });
  return doc.body.innerHTML;
};

export const answerDisplayValue = (answer) => {
  return (
    isObject(answer) ? (
      [answer].flat().some(_ => _?.url) ? (
        [answer].flat().map((answer, i) => (
          <div key={i}>
            <a href={answer?.url} target="_blank">
              {answer?.name}
            </a>
          </div>
        ))
      ) : keys(answer).join(', ')
    ) : answer || ''
  );
};

export function autoLink(text, { linkAttr = {} } = {}) {
  return text.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, `<a href="$1" ${entries(linkAttr).map(([k, v]) => `${k}="${v}"`).join(' ')}>$1</a> `);
};

export function nl2br(text) {
  return text.replace(/(\n|\r\n)/g, '<br />');
};
