import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import { keyBy, mapValues, isEmpty, orderBy } from 'lodash';
import { useToggle, useMap } from 'react-use';

import { computeVisibleQuestionIds, computeVisibleAnswers } from '../../shared/models/survey';
import { activityTypes, adminFields } from '../../shared/models/surveyAnswer';
import QuestionForm from '../forms/QuestionForm';
import RichTextContent from '../RichTextContent';
import Activity from '../Activity';
import EditButton from '../EditButton';
import ModelFormModal from './ModelFormModal';
import { auditData } from '../../shared/models/user';
const { entries } = Object;

export default function SurveyAnswerFormModal(props) {
  const { isOpen, onClickClose, survey, answers: _answers, questions, activities, surveyAnswer, user } = props;
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const questionsById = keyBy(questions, 'id');
  const relatedQuestions = survey?.questionRows?.map((_) => questionsById[_.questionId] || {}) || [];
  const initialAnswers = relatedQuestions.reduce((x, { id, type, isOptional = false }) => {
    const value = _answers?.[id];
    return {
      ...x,
      [id]: {
        isValid: isOptional || !isEmpty(value),
        value: value ?? { text: '', radio: undefined, checkbox: {}, imageFile: [] }[type],
      },
    };
  }, {});
  const [answers, { setAll: setAnswers }] = useMap(initialAnswers);
  const visibleQuestionIds = computeVisibleQuestionIds(survey, mapValues(answers, 'value'));
  const visibleQuestions = visibleQuestionIds.map((_) => questionsById[_] || {});
  const validationErrorMessages = [
    ...entries(answers)
      .filter((_) => !_[1].isValid)
      .map(([questionId]) => {
        const question = questionsById[questionId];
        return `「${question?.description}」が回答されていません`;
      }),
  ].filter((_) => _);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    await props.onSubmit({
      answers: mapValues(answers, 'value'),
    });
    onClickClose();
  };
  const isStaff = ['admin', 'staff'].includes(user?.role);
  const showActivities = isStaff || activities.length > 0;

  return (
    <Modal isOpen={isOpen} size={showActivities ? "xl" : "lg"}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">アンケート回答編集</h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div className="d-sm-flex justify-content-around mt-5 gap-4">
            <div className="flex-fill">
              <h4 className="h5 text-center font-weight-bold mb-5">{survey.title}</h4>
              <div className="bg-light-grey rounded p-3">
                <RichTextContent html={survey.description} />
              </div>
              <div className="mt-4 d-flex flex-column gap-4">
                {visibleQuestions.map((question) => {
                  const { id } = question;
                  return (
                    <div key={id}>
                      <QuestionForm
                        answer={answers[id]}
                        {...question}
                        onChange={(_) => setAnswers(computeVisibleAnswers(survey, { ...answers, [id]: _ }))}
                        shouldStarts={hasSubmitted}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {showActivities && (
              <div className="mt-5 mt-sm-0" style={{ minWidth: '20vw', maxWidth: '30vw', }}>
                <div>
                  <h5>アクティビティ</h5>
                  <div className="overflow-auto" style={{ maxHeight: 360 }}>
                    {activities.length > 0 ? (
                      <div className="d-flex flex-column gap-1">
                        {orderBy(activities, (_) => _.createdAt.toDate(), 'desc').map((_) => (
                          <Activity activity={_} activityTypes={activityTypes} />
                        ))}
                      </div>
                    ) : (
                      <div>アクティビティはまだありません</div>
                    )}
                  </div>
                </div>
                <hr className="my-5" />
                {isStaff && (
                  <div>
                    <h5>管理者メモ</h5>
                    <EditButton
                      label={false}
                      color="link"
                      size="sm"
                      itemRef={surveyAnswer.ref}
                      FormModal={ModelFormModal}
                      formProps={{ title: '情報編集', fields: adminFields }}
                      beforeSubmit={(values) => ({
                        ...values,
                        adminNoteUpdatedAt: new Date(),
                        adminNoteUpdatedBy: auditData(user),
                      })}
                    />
                    {surveyAnswer.adminNote}
                    <br />
                    {surveyAnswer.adminNoteUpdatedAt && surveyAnswer.adminNoteUpdatedBy && (
                      <small className="text-muted">
                        {surveyAnswer.adminNoteUpdatedAt.toDate().toLocaleString()}{' '}
                        {surveyAnswer.adminNoteUpdatedBy.displayName}
                      </small>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isSubmitting}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
