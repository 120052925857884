const { isEmpty } = require('lodash');

module.exports = {
  fields: () => {
    return {
      body: {
        label: '内容(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
    };
  },
};
