import React, { useEffect } from 'react';
import qs from 'qs';
import { orderBy, groupBy, uniq, maxBy, omit, isEmpty, sum, sumBy, get, keyBy, omitBy, pick, isUndefined, max } from 'lodash';
import { format as formatDate, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { Button, Badge, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ResponsiveContainer, BarChart, Bar, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, } from 'recharts';
import ellipsis from 'text-ellipsis';
import ReactPaginate from 'react-paginate';

import { fullPathWithParams } from '../../util';
import { prefectures, routes, colors, } from '../../shared/config';
import { canUpdateOrder } from '../../shared/abilities';
import firebase from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import { generateShippingRequestRows, fields, payerFields, paymentMethods, paymentMethod, totalPaymentAmount } from '../../shared/models/order';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fields as userFields, adminFields as adminUserFields } from '../../shared/models/user';
import { computeWithoutTax, fieldDisplayValue } from '../../shared/util';
import AdminPage from '../hocs/AdminPage';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useCreateActivity from '../hooks/useCreateActivity';
import TenantLink from '../TenantLink';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import QueryText from '../QueryText';
import QueryInput from '../QueryInput';
import OrderExportButton from '../OrderExportButton';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import useTenant from '../hooks/useTenant';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import SavedSearchParameterForm from '../forms/SavedSearchParameterForm';

const db = firebase.firestore();
const settingsRef = db.collection('settings');
const ordersRef = db.collection('orders');
const usersRef = db.collection('users');
const productsRef = db.collection('products');
const referrersRef = db.collectionGroup('referrers');
const agentsRef = db.collection('agents');
const agentShopsRef = db.collectionGroup('agentShops');
const productTypesRef = db.collection('productTypes');
const couponsRef = db.collection('coupons');
const qrUrlsRef = db.collection('qrUrls');
const { entries } = Object;
const prefectureOptions = entries(prefectures).map(([k, v]) => ({ label: v, value: k }));
const tabs = {
  list: { label: '一覧', },
  trend: { label: 'トレンド', },
  comparison: { label: '期間比較', },
};
const dimensions = {
  route: {
    label: '経路',
    computeItems: _ => uniq(_.map(_ => _.order.route)).map(_ => ({ label: _, value: _ })),
    value: _ => _.order.route,
  },
  area: {
    label: 'エリアグループ',
    computeItems: _ => uniq(_.map(_ => _.area?.group)).map(_ => ({ label: _, value: _ })),
    value: _ => _.area?.group,
  },
  pic: {
    label: '担当',
    computeItems: _ => uniq(_.map(_ => _.area?.user?.displayName)).map(_ => ({ label: _, value: _ })),
    value: _ => _.area?.user?.displayName,
  },
  agent: {
    label: '代理店',
    computeItems: _ => uniq(_.map(_ => _.agent?.name)).filter(_ => _).map(_ => ({ label: _, value: _ })),
    value: _ => _.agent?.name,
  },
  agentShop: {
    label: '代理店店舗',
    computeItems: _ => uniq(_.map(_ => _.agentShop?.name)).filter(_ => _).map(_ => ({ label: _, value: _ })),
    value: _ => _.agentShop?.name,
  },
  referrer: {
    label: 'リファラ',
    computeItems: _ => uniq(_.map(_ => _.referrer?.name)).filter(_ => _).map(_ => ({ label: _, value: _ })),
    value: _ => _.referrer?.name,
  },
};
const metrics = (productsById) => ({
  bodysCount: {
    label: '注文本体数',
    value: _ => sumBy(_, _ => sumBy(_.order.orderItems.map((orderItem) => ({ ...orderItem, product: productsById[orderItem.productId] })).filter(({ product }) => product?.isBody), 'quantity')),
    format: _ => '0,0',
  },
  count: {
    label: '注文件数',
    value: _ => _.length,
    format: _ => '0,0',
  },
  itemsCount: {
    label: '注文商品数',
    value: _ => sumBy(_, _ => sumBy(_.order.orderItems, 'quantity')),
    format: _ => '0,0',
  },
  amount: {
    label: '合計金額',
    value: _ => sumBy(_, 'order.amount'),
    format: _ => '0,0.0a',
  },
  totalAmount: {
    label: '支払合計',
    value: _ => sumBy(_, _ => totalPaymentAmount(_.order)),
    format: _ => '0,0.0a',
  },
});
const metricOptions = (productsById) => entries(metrics(productsById)).map(([k, v]) => ({ label: v.label, value: k, }));
const itemsPerPage = 100;

export default AdminPage(function AdminOrders(props) {
  const { user, location, history } = props;
  const now = new Date();
  const queryParams = qs.parse(decodeURI(location.search.slice(1)));
  const {
    tab = 'list',
    startOn: startOnString = formatDate(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(endOfMonth(new Date()), 'yyyy-MM-dd'),
    staffs: staffsForFilter,
    prefectures: prefecturesForFilter,
    agents: agentsForFilter,
    productTypes: productTypesForFilter,
    preOrderTypes: preOrderTypesForFilter,
    agentShops: agentShopsForFilter,
    referrers: referrersForFilter,
    routes: routesForFilter,
    areaGroups: areaGroupsForFilter,
    withoutCancel: _withoutCancel = '0',
    withPartsOrder: _withPartsOrder = '0',
    onlyCoupon: _onlyCoupon = '0',
    page: _page = '1',
    keyword: keywordForFilter,
    field: [field] = ['id'],
    text,
    comparedDateRange,
  } = queryParams;
  const withoutCancel = _withoutCancel === '1';
  const withPartsOrder = _withPartsOrder === '1';
  const onlyCoupon = _onlyCoupon === '1';
  const page = Number(_page);
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const comparedStartOn = queryParams.comparedDateRange?.[0] ? new Date(queryParams.comparedDateRange[0]) : null;
  const comparedEndOn = queryParams.comparedDateRange?.[1] ? new Date(queryParams.comparedDateRange[1]) : null;
  const { firebaseUser } = useFirebaseUser();
  const tenant = useTenant();
  const createActivity = useCreateActivity();
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroupOptions = uniq(Object.values(areaSetting?.data || {}).map(_ => _.group)).map(_ => ({ label: _, value: _ }));
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const staffs = useCollectionSubscriptionInTenant(usersRef.where('role', 'in', ['admin', 'staff']));
  const staffOptions = staffs.map((_) => ({ label: _.displayName, value: _.id }));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const fieldOptions = [
    { label: '注文ID', value: 'id' },
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウントID', value: 'createdBy.uid' },
    { label: 'アカウント名', value: 'createdBy.displayName' },
    { label: 'アカウント名(かな)', value: 'createdBy.nameKana' },
  ];
  const fieldPath = field === 'id' ? firebase.firestore.FieldPath.documentId() : field;
  const ordersQuery = text
    ? ordersRef.orderBy(fieldPath).startAt(text).endAt(`${text}\uf8ff`)
    : ordersRef
        .where('createdAt', '>=', startOfDay(startOn))
        .where('createdAt', '<=', endOfDay(new Date(endOn)))
        .orderBy('createdAt', 'desc');
  const { items: orders, isLoading } = useCollectionSubscriptionInTenant(ordersQuery, [startOnString, endOnString, text, field], { detail: true });
  const { items: comparedOrders, } = useCollectionSubscriptionInTenant(tab === 'comparison' && comparedStartOn && comparedEndOn && db.collection('orders').where('createdAt', '>=', startOfDay(comparedStartOn)).where('createdAt', '<=', endOfDay(comparedEndOn)).orderBy('createdAt', 'desc'), [tab, comparedStartOn?.toString(), comparedEndOn?.toString()], { detail: true });
  const referrers = useCollectionsFetchInTenant(
    uniq([...orders, ...comparedOrders].flatMap(_ => [_.referrerKey, ...(_.referralLogs || []).map(_ => _.referrerKey)].filter(_ => _))).map((_) => referrersRef.where('key', '==', _)),
    [orders.length, comparedOrders.length]
  );
  const referrersById = keyBy(referrers, 'id');
  const referrerOptions = referrers.map(_ => ({ label: _.name, value: _.id }));
  const qrUrls = useCollectionFetchInTenant(qrUrlsRef);
  const qrUrlsById = keyBy(qrUrls, 'id');
  const agentShops = useCollectionSubscriptionInTenant(agentShopsRef);
  const agentShopsById = keyBy(agentShops, 'id');
  const agentShopOptions = agentShops.map(_ => ({ label: _.name, value: _.id }));
  const agents = useCollectionSubscriptionInTenant(agentsRef);
  const agentOptions = agents.map((_) => ({ label: _.name, value: _.id }));
  const agentsById = keyBy(agents, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const coupons = useCollectionSubscriptionInTenant(couponsRef);
  const couponsById = keyBy(coupons, 'id');
  const preOrderTypeOptions = [
    { label: '予約注文', value: 'true' },
    { label: '通常注文', value: 'false' },
  ];
  const magazineGroups = useCollectionSubscriptionInTenant(db.collection('magazineGroups'));
  const routeOptions = routes.map(_ => ({ label: _, value: _ }));
  const [rows, comparedRows] = [orders, comparedOrders].map(orders => orders.filter(_ => !_.isEnvelopeOrder).map((order) => {
    const { orderItems, referrerKey, qrUrlId, destinationPostalCode, contactorPostalCode, wholesaleAgentId, wholesaleAgentShopId, coupon, otherCoupons = [], } = order;
    const allCoupons = [coupon, ...otherCoupons].filter(_ => _);
    const area = areaFromPostalCode(contactorPostalCode || destinationPostalCode, areaSetting);
    const referrer = referrersById[referrerKey];
    const qrUrl = qrUrlsById[qrUrlId];
    const agentShop = agentShopsById[wholesaleAgentShopId || get(referrer, 'ref.parent.parent.id')];
    const agent = agentsById[wholesaleAgentId || get(agentShop, 'ref.parent.parent.id')];
    const referralLogs = (order.referralLogs || []).map((referralLog) => {
      const { referrerKey, referrerKeySavedAt } = referralLog;
      const referrer = referrersById[referrerKey];
      const agentShop = agentShopsById[referrer?.ref.parent.parent.id];
      const agent = agentsById[agentShop?.ref.parent.parent.id];
      return {
        savedAt: new Date(referrerKeySavedAt),
        agent,
        agentShop,
        referrer,
      };
    });
    const orderItemsWithProduct = orderItems.map((orderItem) => {
      const { productId } = orderItem;
      const product = productsById[productId];
      return { ...orderItem, product, order };
    });
    const bodyQuantity = sumBy(orderItemsWithProduct.filter(({ product }) => product?.isBody), 'quantity');
    const bodyAmount = sumBy(orderItemsWithProduct.filter(({ product }) => product?.isBody).map(_ => (_.price ?? _.product.price) * _.quantity));
    return { order, area, referrer, agentShop, agent, qrUrl, orderItems, orderItemsWithProduct, bodyQuantity, bodyAmount, referralLogs, allCoupons, };
  }));

  // NOTE: filter
  const filterRows = (rows) => {
    let filteredRows = rows;
    let withoutProductTypeFilteredRows = rows;
    if (!text) {
      if (!isEmpty(staffsForFilter)) {
        filteredRows = filteredRows.filter((_) => staffsForFilter.includes(get(_, 'area.user.id')));
      }
      if (!isEmpty(prefecturesForFilter)) {
        filteredRows = filteredRows.filter((_) => prefecturesForFilter.includes(get(_, 'area.prefecture')));
      }
      if (!isEmpty(agentsForFilter)) {
        filteredRows = filteredRows.filter((_) => agentsForFilter.includes(get(_, 'agent.id')));
      }
      if (!isEmpty(preOrderTypesForFilter)) {
        filteredRows = filteredRows.filter((_) =>
          preOrderTypesForFilter.includes((_.order.isPreOrder || false).toString())
        );
      }
      if (!isEmpty(agentShopsForFilter)) {
        filteredRows = filteredRows.filter((_) => agentShopsForFilter.includes(get(_, 'agentShop.id')));
      }
      if (!isEmpty(referrersForFilter)) {
        filteredRows = filteredRows.filter((_) => referrersForFilter.includes(get(_, 'referrer.id')));
      }
      if (!isEmpty(routesForFilter)) {
        filteredRows = filteredRows.filter((_) => routesForFilter.includes(get(_, 'order.route')));
      }
      if (!isEmpty(areaGroupsForFilter)) {
        filteredRows = filteredRows.filter((_) => areaGroupsForFilter.includes(get(_, 'area.group')));
      }
      if (withoutCancel) {
        filteredRows = filteredRows.filter((_) => _.order.cancelledAt == null);
      }
      if (!withPartsOrder) {
        filteredRows = filteredRows.filter((_) => _.orderItemsWithProduct.some((_) => _.product?.isBody));
      }
      if (onlyCoupon) {
        filteredRows = filteredRows.filter((_) => _.order.coupon != null);
      }
      if (keywordForFilter && typeof keywordForFilter === 'string') {
        filteredRows = filteredRows.filter((_) => {
          const {
            id,
            cammacsStatus,
            createdBy,
            referrerUrl,
            route,
          } = _.order;
          const fieldValues = entries(omit({ ...fields(), ...payerFields(), }, ['route'])).map(([fieldName, fieldSettings]) => {
            return fieldDisplayValue(_.order[fieldName], fieldSettings);
          });
          return id?.includes(keywordForFilter) ||
            cammacsStatus?.includes(keywordForFilter) ||
            `${prefectures[_.area?.prefecture]} ${_.area?.city}`.includes(keywordForFilter) ||
            _.area?.user.displayName?.includes(keywordForFilter) ||
            paymentMethods[paymentMethod(_.order)].label.includes(keywordForFilter) ||
            _.agent?.name.includes(keywordForFilter) ||
            _.agentShop?.name.includes(keywordForFilter) ||
            _.referrer?.name.includes(keywordForFilter) ||
            _.qrUrl?.name.includes(keywordForFilter) ||
            referrerUrl?.includes(keywordForFilter) ||
            route?.includes(keywordForFilter) ||
            _.allCoupons.some(({ name }) => name.includes(keywordForFilter)) ||
            createdBy?.displayName.includes(keywordForFilter) ||
            _.orderItemsWithProduct.some(({ product }) => product?.code.includes(keywordForFilter) || product?.name.includes(keywordForFilter)) ||
            fieldValues.some(v => v.includes(keywordForFilter));
        });
      }
      withoutProductTypeFilteredRows = filteredRows;
      if (!isEmpty(productTypesForFilter)) {
        filteredRows = filteredRows.filter((_) =>
          _.orderItemsWithProduct.some((_) =>
            get(_, 'product.productTypeIds', []).some((_) => productTypesForFilter.includes(_)) && _.product.isBody
          )
        );
      }
    }
    return { withoutProductTypeFilteredRows, filteredRows };
  };
  const [{ withoutProductTypeFilteredRows, filteredRows }, { filteredRows: filteredComparedRows }] = [rows, comparedRows].map(_ => filterRows(_));
  const productTypeOptions = productTypes.map((productType) => {
    const count = sumBy(withoutProductTypeFilteredRows.flatMap(_ =>
      _.orderItemsWithProduct.filter(_ => (_?.product.productTypeIds || []).includes(productType.id) && _?.product.isBody)
    ), 'quantity');
    return {
      label: (
        <div>
          {productType.name} <span className="text-muted small">({count})</span>
        </div>
      ),
      value: productType.id
    };
  });

  const rowsForExport = async (_rows) => {
    const users = (await usersRef.where('tenantId', '==', tenant?.id).get()).docs.map((_) => ({ id: _.id, ..._.data() }));
    const usersById = keyBy(users, 'id');
    const rowsWithUser = await Promise.all(
      _rows.map(async (row) => {
        const { order } = row;
        const { createdBy } = order;
        const user = !isEmpty(get(createdBy, 'uid')) ? usersById[get(createdBy, 'uid')] : null;
        const userTags = (user?.userTagIds || []).map(_ => userTagsById[_]);
        return {
          ...row,
          user,
          userTags,
        };
      })
    );
    const maxLengthOrderItemsRow = maxBy(rowsWithUser, (_) => _.orderItemsWithProduct.length);
    const orderItemsRowCount = max([maxLengthOrderItemsRow?.orderItemsWithProduct.length, 10])
    const maxLengthChildrenRow = maxBy(rowsWithUser, (_) => get(_, 'user.children', []).length);
    return rowsWithUser.map(({ user, userTags, area, order, referrer, agentShop, agent, qrUrl, orderItemsWithProduct, referralLogs, allCoupons, }) => {
      const {
        id,
        cammacsStatus,
        createdAt,
        createdBy,
        cancelledAt,
        wishPaidAt,
        shippedDate,
        amount,
        discountAmount = 0,
        shipmentFee = 0,
        receivedOn,
        receivedAmount,
        stripeFee,
        refundedOn,
        refundAmount,
        isPreOrder = false,
        isWish = false,
        referrerUrl,
      } = order;
      return {
        id,
        cammacsStatus,
        areaPrefecture: area && prefectures[area.prefecture],
        areaCity: get(area, 'city'),
        areaStaffName: get(area, 'user.displayName'),
        paymentMethod: paymentMethods[paymentMethod(order)].label,
        agentId: agent && agent.id,
        agentName: agent && agent.name,
        agentShopId: agentShop && agentShop.id,
        agentShopName: agentShop && agentShop.name,
        referrerId: referrer && referrer.id,
        referrerName: referrer && referrer.name,
        referralLogs: referralLogs.map(_ => [
          formatDate(_.savedAt, 'yyyy/MM/dd HH:mm:ss'),
          _.agent?.name || '(代理店不明)',
          _.agentShop?.name || '(店舗不明)',
          _.referrer?.name || '(リファラー不明)',
        ].join(' ')).join('\n'),
        qrUrlId: qrUrl && qrUrl.id,
        qrUrlName: qrUrl && qrUrl.name,
        couponIds: allCoupons.map(_ => _.id).join('\n'),
        couponNames: allCoupons.map(_ => _.name).join('\n'),
        referrerUrl,
        isPreOrder,
        isWish,
        amount,
        discountAmount,
        shipmentFee,
        totalAmount: amount - discountAmount + shipmentFee,
        receivedOn: receivedOn && formatDate(receivedOn.toDate(), 'yyyy/MM/dd'),
        receivedAmount,
        stripeFee,
        refundedOn: refundedOn && formatDate(refundedOn.toDate(), 'yyyy/MM/dd'),
        refundAmount,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        daysElapsed: shippedDate && differenceInDays(new Date(), new Date(shippedDate)),
        cancelledAt: cancelledAt && formatDate(cancelledAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        shippedDate,
        userId: createdBy?.uid,
        userTags: userTags.map(_ => _.name).join(' '),
        ...entries({ ...fields(), ...payerFields(), }).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [fieldName]: fieldDisplayValue(order[fieldName], fieldSettings),
          };
        }, {}),
        wishPaidAt: wishPaidAt && formatDate(wishPaidAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        ...[...Array(orderItemsRowCount).keys()].reduce((x, _, i) => {
          const orderItem = orderItemsWithProduct[i];
          return {
            ...x,
            [`orderItem_${i}_productId`]: orderItem?.product && orderItem.product.id,
            [`orderItem_${i}_productCode`]: orderItem?.product && orderItem.product.code,
            [`orderItem_${i}_productName`]: orderItem?.product && orderItem.product.name,
            [`orderItem_${i}_quantity`]: orderItem && orderItem.quantity,
          };
        }, {}),
        ...entries({
          ...omit(userFields({ magazineGroups }), ['password', 'currentPassword', 'passwordConfirmation', 'accept']),
          ...adminUserFields({ userTags }),
        }).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [`profile_${fieldName}`]: user && fieldDisplayValue(user[fieldName], fieldSettings),
          };
        }, {}),
        ...get(maxLengthChildrenRow, 'user.children', []).reduce((x, _, i) => {
          const child = get(user, ['children', i]);
          return {
            ...x,
            [`child_${i}_name`]: child && child.name,
            [`child_${i}_birthday`]: get(child, 'birthday.toDate') && formatDate(child.birthday.toDate(), 'yyyy/MM/dd'),
            [`child_${i}_gender`]: child && child.gender,
            [`child_${i}_vehicleExperiences`]: child && (child.vehicleExperiences || []).join(','),
          };
        }, {}),
      };
    });
  };

  const shippingRequestRows = async () => {
    const orders = (
      await getCollectionData(db.collection('orders').where('tenantId', '==', tenant.id).where('cammacsStatus', 'in', ['initial', 'imported']))
    ).filter(_ => _.cancelledAt == null);
    return generateShippingRequestRows(orders);
  };
  const processStripeCsvRow = async (batch, row) => {
    if (isEmpty(row.reporting_category)) return;

    const type = row.reporting_category || row.Type.toLowerCase();
    const fee = row.fee || row.Fees;
    const net = row.net || row.Net;
    const orderId = (row.description || row.Description)?.match(/\d{5}-\d{5}-\d{5}-\d{5}/)?.[0];
    if (!orderId) return;

    const { exists } = await ordersRef.doc(orderId).get();
    if (!exists) return;

    batch.update(
      ordersRef.doc(orderId),
      {
        ...(
          {
            charge: {
              receivedOn: row.automatic_payout_effective_at
                ? new Date(row.automatic_payout_effective_at.slice(0, 10).replace(/-/g, '/'))
                : null,
              stripeFee: parseInt(fee, 10),
              receivedAmount: parseInt(net, 10),
            },
            refund: {
              refundedOn: row.automatic_payout_effective_at
                ? new Date(row.automatic_payout_effective_at.slice(0, 10).replace(/-/g, '/'))
                : null,
              refundAmount: parseInt(net, 10),
            },
          }[type]
        ),
        updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      }
    );
  };
  const processShippingCsvRow = async (batch, row) => {
    const [, year, month, day] = row['出荷実績日']?.match(/^(\d{4})(\d{2})(\d{2})$/) || [];
    if (!year) return;

    const orderId = row['備考２'] || row['受注番号'];
    if (!orderId) return;

    const { exists } = await ordersRef.doc(orderId).get();
    if (!exists) return;

    batch.update(
      ordersRef.doc(orderId),
      {
        shippedDate: [year, month, day].join('/'),
        updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      }
    );
  };
  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };
  const pageCount = Math.ceil(filteredRows.length / itemsPerPage);
  const pageOffset = ((page - 1) * itemsPerPage) % filteredRows.length
  const sortedRows = text ? orderBy(filteredRows, _ => _.order.createdAt.toDate(), 'desc') : filteredRows;
  const handlePageClick = (event) => {
    const path = fullPathWithParams({ page: event.selected + 1 }, location);
    history.replace(encodeURI(path));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div>
      <div className='admin-orders container-fluid py-5 position-relative'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>注文一覧</h4>
        </div>
        <div className='d-flex align-items-end flex-wrap gap-2'>
          <Button onClick={onClickDateButton.bind(null, [addDays(now, -1), addDays(now, -1)])}>昨日</Button>
          <Button onClick={onClickDateButton.bind(null, [now, now])}>今日</Button>
          <Button onClick={onClickDateButton.bind(null, [startOfMonth(now), endOfMonth(now)])}>今月</Button>
          <Button
            onClick={onClickDateButton.bind(null, [startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}
          >
            先月
          </Button>
          <QueryDateSelector
            paramName='startOn'
            label='開始日'
            history={history}
            location={location}
            defaultValue={startOfMonth(new Date())}
            style={{ width: 150 }}
          />
          <QueryDateSelector
            paramName='endOn'
            label='終了日'
            history={history}
            location={location}
            defaultValue={endOfMonth(new Date())}
            invalid={startOn > endOn}
            style={{ width: 150 }}
          />
          <QuerySelector
            paramName='field'
            options={fieldOptions}
            label='検索フィールド'
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName='text' label='検索テキスト' />
          <SavedSearchParameterForm {...props} type="order" />
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QuerySelector
            paramName='staffs'
            className='ml-0'
            width={200}
            isMulti
            options={staffOptions}
            label='担当で絞込み'
          />
          <QuerySelector
            paramName='prefectures'
            width={200}
            isMulti
            options={prefectureOptions}
            label='エリア都道府県で絞込み'
          />
          <QuerySelector
            paramName='areaGroups'
            width={200}
            isMulti
            options={areaGroupOptions}
            label='エリアグループで絞込み'
          />
          <QuerySelector paramName='agents' width={250} isMulti options={agentOptions} label='代理店で絞込み' />
          <QuerySelector
            paramName='productTypes'
            width={250}
            isMulti
            options={productTypeOptions}
            label='商品種別で絞込み'
          />
          <QuerySelector
            paramName='preOrderTypes'
            width={200}
            isMulti
            options={preOrderTypeOptions}
            label='予約注文かどうかで絞込み'
          />
          <QuerySelector
            paramName='agentShops'
            width={200}
            isMulti
            options={agentShopOptions}
            label='店舗で絞込み'
          />
          <QuerySelector
            paramName='referrers'
            width={200}
            isMulti
            options={referrerOptions}
            label='リファラで絞込み'
          />
          <QuerySelector
            paramName='routes'
            width={200}
            isMulti
            options={routeOptions}
            label='どこで知ったか？で絞込み'
          />
          <QueryBoolean paramName='withoutCancel' label='キャンセルを除く' defaultValue={'0'} />
          <QueryBoolean paramName='withPartsOrder' label='パーツ注文も表示する' defaultValue={'0'} />
          <QueryBoolean paramName='onlyCoupon' label='優待有り' defaultValue={'0'} />
          <QueryInput paramName="keyword" label="キーワード絞り込み" />
        </div>
        <Nav tabs className="mt-3">
          {
            entries(tabs).map(([name, { label }]) => {
              return (
                <NavItem key={name}>
                  <NavLink
                    tag={Link}
                    className={classnames({ active: tab === name })}
                    to={`${location.pathname}?${qs.stringify({ ...queryParams, tab: name, })}`}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              );
            })
          }
        </Nav>
        {
          ({
            list: _ => (
              <div className="mt-3">
                <div className='d-flex justify-content-start align-items-end'>
                  <div>
                    <div className="d-flex gap-3 align-items-center mb-1">
                      <Badge className="p-2">　全件　</Badge>
                      <span>注文件数: {filteredRows.length}</span>
                      <span>注文商品数: {sumBy(filteredRows.map((_) => _.orderItems || []).flat(), 'quantity')}</span>
                      <span className="d-flex gap-1">
                        <span>合計金額(税抜):</span>
                        <span>&yen; {numeral(computeWithoutTax(sumBy(filteredRows, 'order.amount'))).format()}</span>
                      </span>
                      <span className="d-flex gap-1">
                        <span>支払合計(税抜):</span>
                        <span>&yen; {numeral(computeWithoutTax(sumBy(filteredRows, (_) => totalPaymentAmount(_.order)))).format()}</span>
                      </span>
                    </div>
                    <div className="d-flex gap-3 align-items-center mb-1">
                      <Badge className="p-2">本体のみ</Badge>
                      <span>注文件数: {filteredRows.length}</span>
                      <span>注文商品数: {sumBy(filteredRows.map((_) => _.bodyQuantity))}</span>
                      <span className="d-flex gap-1">
                        <span>合計金額(税抜):</span>
                        <span>&yen; {numeral(computeWithoutTax(sumBy(filteredRows.map((_) => _.bodyAmount)))).format()}</span>
                      </span>
                      <span className="d-flex gap-1">
                        <span>支払合計(税抜):</span>
                        <span>&yen; {numeral(computeWithoutTax(sumBy(filteredRows, (_) => totalPaymentAmount({ ..._.order, amount: _.bodyAmount})))).format()}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end align-items-end gap-1'>
                  <ImportButton label='入金CSVインポート' processRow={processStripeCsvRow} />
                  <ImportButton label="出荷" processRow={processShippingCsvRow} />
                  <ExportButton label="出荷依頼データ" fileName="出荷依頼.csv" rows={shippingRequestRows} user={user} />
                  <ExportButton fileName='注文.csv' rows={() => rowsForExport(filteredRows)} hasPersonalInfo user={user} />
                  <OrderExportButton fileName='注文.csv' {...{ rowsForExport, areaSetting, qrUrlsById, agentShopsById, agentsById, productsById, fieldOptions, staffOptions, prefectureOptions, agentOptions, productTypeOptions, preOrderTypeOptions }} hasPersonalInfo user={user} />
                </div>
                <div className='overflow-auto mt-2'>
                  {filteredRows.length > 0 ? (
                    <div>
                      <table className='table'>
                        <thead className='thead-light text-center text-nowrap'>
                          <tr>
                            <th>注文ID</th>
                            <th>CAMMACSステータス</th>
                            <th>予約注文</th>
                            <th>エリア</th>
                            <th>担当</th>
                            <th>決済方法</th>
                            <th>代理店</th>
                            <th>店舗</th>
                            <th>リファラ</th>
                            <th>紹介QRコード遷移先</th>
                            <th>直前URL</th>
                            <th>どちらでお知りになりましたか？</th>
                            <th style={{ minWidth: 150 }}>優待</th>
                            <th>アカウント</th>
                            <th style={{ minWidth: 300 }}>商品</th>
                            <th>合計金額(税込)</th>
                            <th>割引額(税込)</th>
                            <th>送料(税込)</th>
                            <th>支払合計(税込)</th>
                            <th>入金日</th>
                            <th>入金額</th>
                            <th>決済手数料</th>
                            <th>Stripeへの返金日</th>
                            <th>Stripeへの返金額</th>
                            <th>注文日時</th>
                            <th>キャンセル日時</th>
                            <th>出荷日</th>
                            {entries(omit({ ...fields(), ...payerFields(), }, ['route'])).map(([fieldName, { label }]) => {
                              const prefix = fieldName.startsWith('destination') ? '配送先' : fieldName.startsWith('contactor') ? '利用者情報' : fieldName.startsWith('payer') ? '決済者' : '';
                              return <th key={fieldName}>{prefix}{label}</th>;
                            })}
                            <th>おねだり決済日時</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedRows.slice(pageOffset, pageOffset + itemsPerPage).map(
                            ({ order, area, referrer, agentShop, agent, qrUrl, allCoupons, orderItemsWithProduct }) => {
                              const {
                                id,
                                cammacsStatus,
                                createdBy,
                                createdAt,
                                amount,
                                discountAmount = 0,
                                shipmentFee = 0,
                                receivedOn,
                                receivedAmount,
                                stripeFee,
                                refundedOn,
                                refundAmount,
                                cancelledAt,
                                wishPaidAt,
                                shippedDate,
                                isPreOrder = false,
                                referrerUrl,
                                route,
                                couponCheckedAt,
                                isWish = false,
                                charge,
                              } = order;

                              const onCheckCoupon = async () => {
                                await order.ref.update({
                                  couponCheckedAt: couponCheckedAt ? null : new Date(),
                                  updatedAt: new Date(),
                                  updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined)
                                })
                                await createActivity(couponCheckedAt ? 'uncheckCouponOfOrder' : 'checkCouponOfOrder', user, { orderId: order?.id, uid: order.createdBy.uid });
                              }

                              return (
                                <tr key={id}>
                                  <td>
                                    <TenantLink to={`/admin/orders/${id}`}>{id}</TenantLink>
                                  </td>
                                  <td>
                                    <div>{cammacsStatus}</div>
                                    {isWish && <span className="badge badge-info">おねだり</span>}
                                  </td>
                                  <td>{isPreOrder && '予約注文'}</td>
                                  <td>
                                    {area && (
                                      <span>
                                        <span>{prefectures[area.prefecture]}</span>
                                        <span>{area.city}</span>
                                      </span>
                                    )}
                                  </td>
                                  <td>{area && area.user.displayName}</td>
                                  <td>
                                    <div>{paymentMethods[paymentMethod(order)].label}</div>
                                    {isWish && charge == null && (<span className="badge badge-secondary">未決済</span>)}
                                  </td>
                                  <td>{agent && agent.name}</td>
                                  <td>{agentShop && agentShop.name}</td>
                                  <td>{referrer && referrer.name}</td>
                                  <td>{qrUrl && qrUrl.name}</td>
                                  <td>
                                    {!isEmpty(referrerUrl) && (
                                      <a href={referrerUrl} target='_blank'>
                                        <span className='text-truncate d-inline-block' style={{ maxWidth: 200 }}>
                                          {referrerUrl}
                                        </span>
                                        <span className='ml-1 fas fa-external-link-alt' />
                                      </a>
                                    )}
                                  </td>
                                  <td>{route}</td>
                                  <td>
                                    {
                                      allCoupons.map((coupon) => {
                                        return (
                                          <div key={coupon.id} className="card p-1 mb-1">
                                            {coupon.name}
                                            {!couponCheckedAt ? <Button color="warning" size="sm" onClick={onCheckCoupon}>未確認</Button> : <Button color='success' size="sm" outline onClick={onCheckCoupon}>確認済</Button>}
                                          </div>
                                        );
                                      })
                                    }
                                  </td>
                                  <td>
                                    {createdBy && <TenantLink to={`/admin/users/${createdBy.uid}`}>{createdBy.displayName}</TenantLink>}
                                  </td>
                                  <td>
                                    {orderItemsWithProduct.map((orderItem) => {
                                      const { product, quantity } = orderItem;
                                      return (
                                        product != null && (
                                          <div>
                                            <span>{product.code}</span>
                                            <span className='ml-2'>{product.name}</span>
                                            <span className='ml-2'>{numeral(quantity).format('0,0')}個</span>
                                          </div>
                                        )
                                      );
                                    })}
                                  </td>
                                  <td className='text-right'>{numeral(amount).format('0,0')}</td>
                                  <td className='text-right'>{numeral(discountAmount).format('0,0')}</td>
                                  <td className='text-right'>{numeral(shipmentFee).format('0,0')}</td>
                                  <td className='text-right'>{numeral(totalPaymentAmount(order)).format('0,0')}</td>
                                  <td>{receivedOn && formatDate(receivedOn.toDate(), 'yyyy/MM/dd')}</td>
                                  <td className='text-right'>
                                    {receivedAmount != null && numeral(receivedAmount).format('0,0')}
                                  </td>
                                  <td className='text-right'>{stripeFee != null && numeral(stripeFee).format('0,0')}</td>
                                  <td>{refundedOn && formatDate(refundedOn.toDate(), 'yyyy/MM/dd')}</td>
                                  <td className='text-right'>{refundAmount != null && numeral(refundAmount).format('0,0')}</td>
                                  <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                                  <td>{cancelledAt && formatDate(cancelledAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                                  <td>{shippedDate}</td>
                                  {entries(omit({ ...fields(), ...payerFields(), }, ['route'])).map(([fieldName, fieldSettings]) => {
                                    return <th>{fieldDisplayValue(order[fieldName], fieldSettings)}</th>;
                                  })}
                                  <td>{wishPaidAt && formatDate(wishPaidAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-center">
                        <ReactPaginate
                          breakLabel="..."
                          previousLabel="<"
                          nextLabel=">"
                          activeClassName="active"
                          disabledClassName="disabled"
                          containerClassName="list-group list-group-horizontal"
                          pageClassName="list-group-item"
                          previousClassName="list-group-item"
                          nextClassName="list-group-item"
                          breakClassName="list-group-item"
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          renderOnZeroPageCount={null}
                        />
                      </div>
                    </div>
                  ) : isLoading ? (
                    <div>
                      <span className="fas fa-spin fa-spinner" />
                    </div>
                  ) : (
                    <div>
                      No Data
                    </div>
                  )}
                </div>
              </div>
            ),
            trend: _ => (
              <TrendCharts {...{ filteredRows, startOn, endOn, productsById }} />
            ),
            comparison: _ => (
              <ComparisonCharts {...{ filteredRows, filteredComparedRows, startOn, endOn, comparedStartOn, comparedEndOn, productsById }} />
            ),
          })[tab]()
        }
      </div>
      <div className='position-fixed' style={{ bottom: 12, right: 12 }}>
        <Button color="grey" className="text-white bg-grey rounded-circle" style={{ width: 38 }} onClick={() => window.scrollTo(0, document.documentElement.scrollHeight - document.documentElement.clientHeight)}>
          <span className="fas fa-caret-down"></span>
        </Button>
      </div>
    </div>
  );
});

function TrendCharts(props) {
  const { filteredRows, startOn, endOn, productsById } = props;
  const xAxisUnits = {
    daily: {
      label: '日次',
      xs: _ => eachDayOfInterval({ start: _.startOn, end: _.endOn }).map(_ => [_]),
      format: _ => formatDate(_[0], 'yyyy/MM/dd'),
    },
    weekly: {
      label: '週次',
      xs: _ => eachWeekOfInterval({ start: _.startOn, end: _.endOn }).map(_ => eachDayOfInterval({ start: _, end: addDays(_, 6) })),
      format: _ => `${formatDate(_[0], 'yyyy/MM/dd')}〜`,
    },
    monthly: {
      label: '月次',
      xs: _ => eachMonthOfInterval({ start: _.startOn, end: _.endOn }).map(_ => eachDayOfInterval({ start: _, end: endOfMonth(_) })),
      format: _ => formatDate(_[0], 'yyyy/MM'),
    },
  };
  const xAxisUnitOptions = entries(xAxisUnits).map(([k, v]) => ({ label: v.label, value: k, }));
  const queryParams = useQueryParams();
  const {
    xAxisUnit = 'daily',
    metric = 'count',
  } = queryParams;
  const xGroupedRows = groupBy(filteredRows, _ => formatDate(_.order.createdAt.toDate(), 'yyyyMMdd'));
  const xs = xAxisUnits[xAxisUnit].xs({ startOn, endOn });
  const rowGroups = xs.map((dates) => {
    const rows = dates.flatMap(_ => xGroupedRows[formatDate(_, 'yyyyMMdd')] || []);
    return {
      dates,
      rows,
    };
  });
  const cardWidth = 600;
  const cardHeight = cardWidth * 0.8;
  const chartWidth = cardWidth * 0.95;
  const chartHeight = chartWidth * 0.7;
  const charts = {
    all: {
      title: '全体',
      data: rowGroups.map(_ => ({
        x: xAxisUnits[xAxisUnit].format(_.dates),
        [metrics(productsById)[metric].label]: metrics(productsById)[metric].value(_.rows),
      })),
      ys: [
        { label: metrics(productsById)[metric].label, },
      ],
    },
    ...entries(dimensions).reduce((x, [dimension, { label, computeItems, value, }]) => {
      const items = computeItems(filteredRows);
      const data = rowGroups.map((rowGroup) => {
        const { dates, rows } = rowGroup;
        const rowsGroupedByDimension = groupBy(rows, value);
        return {
          x: xAxisUnits[xAxisUnit].format(dates),
          ...items.reduce((x, item) => {
            const rows = rowsGroupedByDimension[item.value] || [];
            return {
              ...x,
              [item.label]: metrics(productsById)[metric].value(rows),
            };
          }, {}),
        };
      });
      return {
        ...x,
        [dimension]: {
          title: label,
          data: data,
          ys: items,
        },
      };
    }, {}),
  };

  return (
    <div className="mt-3">
      <div className='d-flex align-items-end gap-2 justify-content-end'>
        <QuerySelector
          paramName='xAxisUnit'
          options={xAxisUnitOptions}
          label=''
          defaultValue={'daily'}
          isClearable={false}
        />
        <QuerySelector
          paramName='metric'
          options={metricOptions(productsById)}
          label=''
          defaultValue={'bodysCount'}
          isClearable={false}
        />
      </div>
      <div className="mt-3 d-flex flex-wrap justify-content-around gap-3">
        {
          entries(charts).map(([name, { title, data, ys, }]) => {
            return (
              <div key={name} className="card" style={{ width: cardWidth, height: cardHeight }}>
                <div className="card-header d-flex justify-content-between">
                  <div className="flex-fill">
                    {title}
                  </div>
                </div>
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div>
                    <LineChart width={chartWidth} height={chartHeight} data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="x" />
                      <YAxis tick={{ fontSize: 14.5 }} tickFormatter={_ => numeral(_).format(metrics(productsById)[metric].format())} tick={{ fontSize: 14.5 }} />
                      <Tooltip />
                      <Legend />
                      {
                        ys.map(({ label }, i) => {
                          return (
                            <Line key={label} dataKey={label} type="monotone" dataKey={label} fill={colors[i]} stroke={colors[i]} strokeWidth={2} formatter={(v, _, { payload }) => `${numeral(v).format(metrics(productsById)[metric].format())} (${numeral(v / sum(Object.values(omit(payload, 'x')))).format('0,0.0%')})`} />
                          );
                        })
                      }
                    </LineChart>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

function ComparisonCharts(props) {
  const { filteredRows, filteredComparedRows = [], startOn, endOn, comparedStartOn, comparedEndOn, productsById } = props;
  const queryParams = useQueryParams();
  const {
    metric = 'count',
  } = queryParams;
  const groups = [
    { rangeName: [comparedStartOn, comparedEndOn].map(_ => _ ? formatDate(_, 'yyyy/MM/dd') : '').join(' - '), rows: filteredComparedRows, },
    { rangeName: [startOn, endOn].map(_ => formatDate(_, 'yyyy/MM/dd')).join(' - '), rows: filteredRows, },
  ];
  const cardWidth = 1200;
  const cardHeight = cardWidth * 0.4;
  const chartWidth = cardWidth * 0.95;
  const chartHeight = chartWidth * 0.35;
  const charts = {
    all: {
      title: '全体',
      data: [
        groups.reduce((x, y) => ({
          ...x,
          [y.rangeName]: metrics(productsById)[metric].value(y.rows),
        }), { x: '全体', }),
      ],
      ys: groups.map(_ => ({
        label: _.rangeName,
      })),
    },
    ...entries(dimensions).reduce((x, [dimension, { label, computeItems, value, }]) => {
      const items = computeItems([...filteredComparedRows, ...filteredRows]);
      const rowsGroupedByDimension = [filteredComparedRows, filteredRows].map(_ => groupBy(_, value));
      const data = orderBy(items.map((item) => {
        return groups.reduce((x, y, i) => ({
          ...x,
          [y.rangeName]: metrics(productsById)[metric].value(rowsGroupedByDimension[i][item.value] || []),
        }), { x: item.label || '(なし)', });
      }), [_ => _[groups[0].rangeName], _ => _[groups[1].rangeName]], ['desc', 'desc']).slice(0, 10);
      return {
        ...x,
        [dimension]: {
          title: label,
          data: data,
          ys: groups.map(_ => ({
            label: _.rangeName,
          })),
        },
      };
    }, {}),
  };
  const buttons = [
    { label: '1年前', monthsAgo: 12, },
    { label: '半年前', monthsAgo: 6, },
    { label: '前月', monthsAgo: 1, },
  ].map(_ => ({ label: _.label, dates: () => [addMonths(startOn, -_.monthsAgo), addMonths(endOn, -_.monthsAgo)] }));

  return (
    <div className="mt-3">
      <div className='d-flex align-items-end gap-2 justify-content-end'>
        <QueryDateRangeSelector label="比較期間" defaultValue={[comparedStartOn, comparedEndOn]} paramName="comparedDateRange" buttons={buttons} pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} />
        <QuerySelector
          paramName='metric'
          options={metricOptions(productsById)}
          label=''
          defaultValue={'bodysCount'}
          isClearable={false}
        />
      </div>
      <div className="mt-3 d-flex flex-wrap justify-content-around gap-3">
        {
          entries(charts).map(([name, { title, data, ys, }]) => {
            return (
              <div key={name} className="card" style={{ width: cardWidth, height: cardHeight }}>
                <div className="card-header d-flex justify-content-between">
                  <div className="flex-fill">
                    {title}
                  </div>
                </div>
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div>
                    <BarChart width={chartWidth} height={chartHeight} data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="x" tick={{ fontSize: 11 }} interval={0} tickFormatter={_ => ellipsis(_, 10)} />
                      <YAxis tick={{ fontSize: 11 }} tickFormatter={_ => numeral(_).format(metrics(productsById)[metric].format())} tick={{ fontSize: 14.5 }} />
                      <Tooltip />
                      <Legend />
                      {
                        ys.map(({ label }, i) => {
                          return (
                            <Bar key={label} dataKey={label} fill={colors[i]} formatter={(v, _, { payload }) => `${numeral(v).format(metrics(productsById)[metric].format())} (${numeral(v / sum(Object.values(omit(payload, 'x')))).format('0,0.0%')})`} />
                          );
                        })
                      }
                    </BarChart>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
