import React from 'react';
import { Form } from 'reactstrap';
import { isString, pickBy, isEmpty, mapValues, keyBy } from 'lodash';
import { useToggle, useMap } from 'react-use';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import { computeVisibleQuestionIds, computeVisibleAnswers, } from '../../shared/models/survey';
import AppButton from '../AppButton';
import QuestionForm from '../forms/QuestionForm';
import RichTextContent from '../RichTextContent';

const { entries } = Object;

export default function NewSurveyAnswerForm(props) {
  const { translate, values, survey, questions = [] } = props;
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const questionsById = keyBy(questions, 'id');
  const relatedQuestions = survey?.questionRows?.map(_ => questionsById[_.questionId] || {}) || [];
  const initialAnswers = relatedQuestions.reduce((x, { id, type, isOptional = false }) => {
    const value = values.answers?.[id];
    return {
      ...x,
      [id]: {
        isValid: isOptional || !isEmpty(value),
        value: value ?? { text: '', radio: undefined, checkbox: {}, imageFile: [] }[type],
      },
    };
  }, {});
  const [answers, { setAll: setAnswers, }] = useMap(initialAnswers);
  const visibleQuestionIds = computeVisibleQuestionIds(survey, mapValues(answers, 'value'));
  const visibleQuestions = visibleQuestionIds.map(_ => questionsById[_] || {});
  const validationErrorMessages = [
    ...entries(answers)
      .filter((_) => !_[1].isValid)
      .map(([questionId]) => {
        const question = questionsById[questionId];
        return `「${question?.description}」が回答されていません`;
      }),
  ].filter((_) => _);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    await props.onSubmit({
      answers: mapValues(answers, 'value'),
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <h4 className="h5 text-center font-weight-bold mb-5">{survey.title}</h4>
            <div className="bg-light-grey rounded p-3">
              <RichTextContent html={survey.description} />
            </div>
            <div className="mt-4 d-flex flex-column gap-4">
              {visibleQuestions.map((question) => {
                const { id } = question;
                return (
                  <div key={id}>
                    <QuestionForm
                      answer={answers[id]}
                      {...question}
                      onChange={_ => setAnswers(computeVisibleAnswers(survey, { ...answers, [id]: _, }))}
                      shouldStarts={hasSubmitted}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex mt-5">
              <AppButton
                size="lg"
                color="primary"
                className="save flex-fill ml-2"
                type="submit"
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                <span
                  className={classnames('fas mr-1', {
                    'fa-arrow-right': !isSubmitting,
                    'fa-spin fa-spinner': isSubmitting,
                  })}
                />
                {translate('確認画面')}
              </AppButton>
            </div>
          </div>
        </div>
      </section>
    </Form>
  );
}
