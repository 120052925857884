import React, { useEffect } from 'react';
import { mapValues } from 'lodash';
import sanitizeHtml from 'sanitize-html';

import { canUpdateTextSettings, } from '../../shared/abilities';
import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import SettingText from '../SettingText';
import { textSettingsFields } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';

const db = firebase.firestore();
const { entries } = Object;

export default AdminPage(function AdminTextSettings (props) {
  const { tenant, user, match: { params: { tenantPath } } } = props;
  const textSettingsRef = db.collection('settings').doc([tenantPath, 'text'].join('__'));
  const textSettings = useDocumentSubscription(textSettingsRef);
  useEffect(() => {
    if(textSettings === null) {
      textSettingsRef.set({ tenantId: tenant.id, ...mapValues(textSettingsFields(), _ => '') });
    }
  }, [textSettings])

  return textSettings != null && (
    <div>
      <div className="admin-text-settings container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="d-flex justify-content-end mb-3">
            <EditButton color="primary" itemRef={textSettingsRef} FormModal={ModelFormModal} formProps={{ title: 'テキスト設定', fields: textSettingsFields, style: { minWidth: 700 }, }} disabled={!canUpdateTextSettings(user)} />
          </div>
          <table className="table table-bordered align-middle">
            <tbody className="thead-light">
              {
                entries(textSettingsFields()).map(([fieldName, fieldSettings]) => {
                  const { label, type } = fieldSettings;
                  const value = fieldDisplayValue(textSettings[fieldName], fieldSettings);
                  return (
                    <tr>
                      <th>
                        {label}
                      </th>
                      <td>
                        <SettingText name={fieldName} />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
