import { Fragment } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import dedent from 'dedent';
import { updateDoc } from '../firebase';

import ModelFormModal from './modals/ModelFormModal';

export const InquiryShipmentButton = ({
  inquiryRef,
  inquiry,
  shipmentFields,
  shipmentMailBody,
  user,
  products,
  productTypes,
  onFinish,
  inquiryShipment,
  shipmentOrder,
  ...extraProps
}) => {
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    const message = dedent`
      以下のようなメールを送り、発送のための注文処理を行います。よろしいですか？

      -----------------------------

      ${shipmentMailBody({ ...inquiry, ...values }, products, productTypes)}
    `;
    if (!window.confirm(message)) return;

    try {
      await updateDoc(inquiry.ref, { ...values, shipmentRequested: true });
      await inquiryShipment({ inquiryId: inquiry.id })
      toast.success('発送のための注文処理しました');
      toggleModal(false);
      await onFinish();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Fragment>
      <Button color="success" onClick={toggleModal.bind(null, true)} {...extraProps}>
        {shipmentOrder ? (shipmentOrder?.cammacsStatus === 'shipped' ? '発送完了' : '発送依頼中') : '発送' }
      </Button>
      {showsModal && (
        <ModelFormModal
          isOpen
          title="発送"
          submitLabel="確認する"
          fields={shipmentFields}
          onClickClose={toggleModal.bind(null, false)}
          onSubmit={onSubmit}
        />
      )}
    </Fragment>
  );
};
