import React, { useEffect } from 'react';
import { Button, Collapse, Badge, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { isEmpty, sortBy, keyBy } from 'lodash';
import { useToggle } from 'react-use';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { fields, scheduleTypes } from '../../shared/models/customerJourney';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import TenantLink from '../TenantLink';
import { deliveryMethods } from '../../shared/config';

const db = firebase.firestore();
const customerJourneysRef = db.collection('customerJourneys');
const customerJourneyGroupsRef = db.collection('customerJourneyGroups');
const { entries } = Object;

export default AdminPage(function AdminCustomerJourney(props) {
  const {
    match: {
      params: { customerJourneyId },
    },
  } = props;
  const customerJourney = useDocumentSubscription(customerJourneysRef.doc(customerJourneyId), [customerJourneyId]);
  const customerJourneyGroups = useCollectionSubscriptionInTenant(customerJourneyGroupsRef);
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [customerJourney]);

  return (
    customerJourney != null && (
      <div className="admin-customer-journey container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>カスタマージャーニー配信詳細</h4>
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                {entries({
                  ...fields({ products, productTypes, events, userTags, customerJourneyGroups }),
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  const displayValue = fieldDisplayValue(customerJourney[fieldName], fieldSetting);
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {Array.isArray(displayValue) ? displayValue.join(', ') : displayValue}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {customerJourney.customerJourneyRows.map((row, index) => (
              <div key={row.key} className="mb-2">
                <CustomerJourneyRow row={row} index={index} customerJourneyId={customerJourneyId} userTags={userTags} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
});

const CustomerJourneyRow = ({ row, index, customerJourneyId, userTags }) => {
  const [open, toggle] = useToggle();
  const { key, name, deliveryMethod, scheduleType, daysAfter, subject, body, isSuspended, userTagId } = row;
  const deliveryHistoriesRef = db
    .collection(`customerJourneys/${customerJourneyId}/deliveryHistories`)
    .where('customerJourneyRowKey', '==', key);
  const deliveryHistories = useCollectionSubscriptionInTenant(deliveryHistoriesRef.limit(1));
  const userTagsById = keyBy(userTags, 'id');
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5" onClick={toggle}>
          <div className="d-flex justify-content-between">
            <div>
              <Badge color="info" className="mx-2">
                {index + 1}
              </Badge>
              {name}
              <Badge className="ml-2" pill>
                {deliveryMethods[deliveryMethod].label}
              </Badge>
            </div>
            {isEmpty(deliveryHistories) || (
              <Button
                tag={TenantLink}
                to={`/admin/customerJourneys/${customerJourneyId}/deliveryHistories?key=${key}`}
                target="_blank"
              >
                配信履歴
              </Button>
            )}
          </div>
        </CardTitle>
        <Collapse isOpen={open}>
          <CardSubtitle className="mb-2 text-muted">{key}</CardSubtitle>
          <table className="table table-bordered">
            <tbody className="thead-light">
              <tr>
                <th style={{ width: 200 }}>スケジュール種別</th>
                <td style={{ whiteSpace: 'pre-line' }}>
                  {scheduleType === 'tag' ? (
                    <>
                      <Badge color="secondary" className="mx-1">
                        {userTagsById[userTagId]?.name}
                      </Badge>
                      {scheduleTypes[scheduleType].label}
                    </>
                  ) : (
                    <>
                      {scheduleTypes[scheduleType].label}
                      {daysAfter}日後
                    </>
                  )}
                </td>
              </tr>
              {deliveryMethod === 'email' && (
                <tr>
                  <th style={{ width: 200 }}>Eメール件名</th>
                  <td style={{ whiteSpace: 'pre-line' }}>{subject}</td>
                </tr>
              )}
              <tr>
                <th style={{ width: 200 }}>本文</th>
                <td style={{ whiteSpace: 'pre-line' }}>{body}</td>
              </tr>
              <tr>
                <th style={{ width: 200 }}>配信停止</th>
                <td style={{ whiteSpace: 'pre-line' }}>{isSuspended}</td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </CardBody>
    </Card>
  );
};
