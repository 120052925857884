import React from 'react';
import { Form, Label } from 'reactstrap';
import { keyBy } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import AppButton from '../AppButton';

const { keys } = Object;

export default function NewInquiryConfirmForm(props) {
  const { values, survey, questions = [], onClickBack } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const questionsById = keyBy(questions, 'id');
  const { answers } = values;
  const visibleQuestions = (survey.questionRows?.map((_) => questionsById[_.questionId] || {}) || [])
    .filter(_ => _.id in answers);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    toggleSubmitting(true);
    try {
      await props.onSubmit({
        ...values,
      });
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div>
          <div>
            <h4 className="mt-4 h5 text-center font-weight-bold">内容をご確認ください</h4>
          </div>
          <div className="mt-5">
            <div className="d-flex flex-column align-items-start gap-5">
              {visibleQuestions.map((question) => {
                const { id, description, type } = question;
                const answer = answers[id];
                return (
                  <div key={id}>
                    <Label className="mb-1 font-weight-bold">{description}</Label>
                    <div>
                      {{
                        text: answer,
                        checkbox: keys(answer || {}).join(', '),
                        radio: answer,
                        imageFile: answer?.map?.((_) => _.name).join(', '),
                      }[type] || null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex mt-5">
          <AppButton
            size="lg"
            className="cancel flex-fill"
            color="secondary"
            onClick={onClickBack}
            disabled={isSubmitting}
          >
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton
            size="lg"
            className="save flex-fill ml-2"
            type="submit"
            color="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            <span className="fas fa-arrow-right mr-1" />
            送信する
          </AppButton>
        </div>
      </section>
    </Form>
  );
}
