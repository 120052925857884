import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Badge } from 'reactstrap';
import { omit, get, mapValues } from 'lodash';
import { useList } from 'react-use';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import { arrayMoveImmutable } from 'array-move';

import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';
import ModalButton from '../ModalButton';
import ModelFormModal from './ModelFormModal';

const { entries } = Object;
const db = firebase.firestore();
const sendSms = functions.httpsCallable('sendSms');
const sendEmail = functions.httpsCallable('sendEmail');
const sendMobile = functions.httpsCallable('sendMobile');

export default function CustomerJourneyFormModal(props) {
  const { fields, rowFields, isOpen, values, onClickClose, tenant } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields, isOpen);
  const [rows, { set: setRows, updateAt: updateRowAt }] = useList(get(values, 'customerJourneyRows') || []);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid) || rows.some((_) => !_.isValid);
  const onClickMove = (index, direction) => {
    const newRows = arrayMoveImmutable(rows, index, index + { up: -1, down: 1 }[direction]);
    setRows(newRows);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({
      ...mapValues(statedFields, 'value'),
      customerJourneyRows: rows.map((_) => omit(_, 'isValid')),
    });
  };

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader>カスタマージャーニー{isNew ? '追加' : '編集'}</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              values={mapValues(statedFields, 'value')}
              documentName="customerJourney"
              {...fieldSetting}
            />
          ))}
          <div className="my-3">
            <ListForm
              items={rows}
              renderItem={(item, itemIndex) => {
                return (
                  <div>
                    <div className="card p-3">
                      <RowForm
                        index={itemIndex}
                        values={item}
                        tenant={tenant}
                        onChange={(_) => updateRowAt(itemIndex, { ...item, ..._ })}
                        rowFields={rowFields}
                        rows={rows}
                        onClickMove={(direction) => onClickMove(itemIndex, direction)}
                        onSubmit={onSubmit}
                        isUnsubmittable={isUnsubmittable}
                      />
                    </div>
                  </div>
                );
              }}
              onChange={(_) => setRows(_)}
              minItems={1}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

function RowForm(props) {
  const { index, values, onChange, tenant, rowFields, rows, onClickMove, onSubmit, isUnsubmittable } = props;
  const statedFields = useFormState(values, rowFields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
    statedFields.startAll();
    // NOTE: hiddenのRichTextEditorがあると、このeffectのonChangeでnullと""が交互に設定されてしまう。nullと""の違いではeffectが動かないように
  }, [...Object.values(statedFields).map((_) => (_.value == null ? '' : _.value))]);

  const onClickSendSms = async ({ displayName, phone, email }) => {
    const {
      body,
      deliveryMethod,
      subject,
      mobileNotificationType,
      mobileNotificationContentType,
      url,
      html,
      mobileTypeDisplayName,
      fixUntil,
    } = values;
    const content = { text: body, url, html }[mobileNotificationContentType] || '';
    try {
      if (deliveryMethod === 'email' || deliveryMethod === 'user') {
        await sendEmail({
          from_name: tenant.name,
          to: [
            {
              email,
              name: displayName,
              type: 'to',
            },
          ],
          subject: `${subject}`,
          text: dedent`
            ${body.replace(/\{\{name\}\}/g, displayName)}
          `,
        });
      }
      if (deliveryMethod === 'sms' || deliveryMethod === 'user') {
        await sendSms({ targets: [{ displayName, phone }], text: body });
      }
      if (deliveryMethod === 'mobile' || deliveryMethod === 'user') {
        await sendMobile({
          targets: [{ email, displayName }],
          subject,
          contentType: mobileNotificationContentType,
          content,
          type: mobileNotificationType,
          typeDisplayName: mobileTypeDisplayName,
          fixUntil: fixUntil?.toDate().toISOString(),
        });
      }
      toast.success('送信しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div className="d-flex flex-column">
      <div>
        <div className="d-flex justify-content-between align-items-center gap-1">
          <Badge color="info" className="px-2">
            {index + 1}
          </Badge>
          <div className="d-flex gap-1">
            <Button size="sm" onClick={() => onClickMove('up')} disabled={index === 0}>
              <span className="fas fa-caret-up" />
            </Button>
            <Button size="sm" onClick={() => onClickMove('down')} disabled={index === rows.length - 1}>
              <span className="fas fa-caret-down" />
            </Button>
            <Button size="sm" className="save ml-2" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
              保存
            </Button>
          </div>
        </div>
        {entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            documentName="customerJourneyRow"
            {...fieldSetting}
          />
        ))}
        <ModalButton
          Modal={ModelFormModal}
          modalProps={{
            title: 'テスト送信',
            submitLabel: '送信',
            fields: {
              displayName: { type: 'string', label: '名前' },
              ...(['email', 'mobile', 'user'].includes(values.deliveryMethod)
                ? { email: { type: 'string', label: 'メールアドレス' } }
                : {}),
              ...(['sms', 'user'].includes(values.deliveryMethod)
                ? { phone: { type: 'string', label: '電話番号' } }
                : {}),
            },
            onSubmit: onClickSendSms,
          }}
        >
          <span className="fas fa-paper-plane mr-1" />
          テスト送信
        </ModalButton>
      </div>
    </div>
  );
}
