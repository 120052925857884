const { isEmpty } = require('lodash');

const fields = ({ areaGroups = [] }) => {
  return {
    destinations: {
      label: '送信先メールアドレス',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'カンマ区切りで複数指定できます',
    },
    targetDirectSalesVolume: {
      label: '目標直販台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetDirectSalesAmount: {
      label: '目標直販金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetAgentSalesVolume: {
      label: '目標代理店台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetAgentSalesAmount: {
      label: '目標代理店金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    areaGroups: {
      label: 'エリアグループ',
      type: 'multiSelect',
      options: areaGroups.map((_) => ({ label: _, value: _ })),
      initialValue: [],
      hint: 'ORで判定します'
    },
    startDate: {
      label: '開始日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
    },
    endDate: {
      label: '終了日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
    },
  };
};

const targetFields = () => {
  return {
    category: {
      label: '商品カテゴリ',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'HB,BB等で指定する',
    },
    targetDirectSalesVolume: {
      label: '目標直販台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetDirectSalesAmount: {
      label: '目標直販金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetAgentSalesVolume: {
      label: '目標代理店台数',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    targetAgentSalesAmount: {
      label: '目標代理店金額',
      type: 'integer',
      initialValue: 0,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
  };
};

module.exports = {
  fields,
  targetFields
};
